import { classeAction } from '@fava/Store/Slices/classeSlice';
import { RootState } from '@fava/Store/store';
import {
  createClasseRequest,
  deleteClasse,
  EditClasseRequest,
  fetchClass,
  fetchClassPopulate,
  fetchLevel,
} from '@fava/Store/Thunks/fetchClass';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const useClasse = () => {
  const search = useLocation().search as string;

  const Classe: any = useSelector((state: RootState) => state.classe);

  const account: any = useSelector((state: RootState) => state.account);

  const dispatch = useDispatch();

  const setClasseList = (list: any[]) => {
    dispatch(classeAction.setListClasse(list));
  };

  const setLoading = (bool: boolean) => {
    dispatch(classeAction.setLoading(bool));
  };

  const setOpenDrawer = () => {
    dispatch(classeAction.setOpenDrawer(!Classe.openDrawer));
  };

  const setLoadingLevel = (bool: boolean) => {
    dispatch(classeAction.setLoadingLevel(bool));
  };

  const setListLevel = (bool: any[]) => {
    dispatch(classeAction.setListLevel(bool));
  };

  const setLoadEdit = (bool: boolean) => {
    dispatch(classeAction.setLoadEdit(bool));
  };

  const setCurrentClass = (bool: any) => {
    dispatch(classeAction.setCurrentClass(bool));
  };

  const fetchLevelRequest = async () => {
    setLoadingLevel(true);
    try {
      const response = await fetchLevel();
      setListLevel(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoadingLevel(true);
    }
  };

  const fetchListClasse = async () => {
    setLoading(true);

    setClasseList([]);

    try {
      const response = await fetchClass(account?.data?.id, account.type);
      setClasseList(response?.data);
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const fetchListClassePopulated = async (id?: number) => {
    setLoading(true);
    setClasseList([]);
    try {
      const response = await fetchClassPopulate(id || account?.data?.id, account?.type);
      setClasseList(response?.data);
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const createClasse = async () => {
    setLoadEdit(true);

    try {
      const response = await createClasseRequest({
        ...Classe.currentClass,
        establishment: {
          disconnect: [],
          connect: [
            {
              id: account.data.establishment.id,
              position: { end: true },
            },
          ],
        },
        level: {
          disconnect: [],
          connect: [
            {
              id: +Classe.currentClass.level,
              position: { end: true },
            },
          ],
        },
      });

      response.data.attributes.link = `${window.location.origin}/register/${response.data.id}`;

      setClasseList([
        {
          ...response.data,
        },
        ...Classe.listClasse,
      ]);
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoadEdit(false);
      setOpenDrawer();
    }
  };

  const deleteClasseRequest = async () => {
    setLoadEdit(true);

    try {
      const response = await deleteClasse(Classe.currentClass.id);
      setClasseList(Classe.listClasse.filter((x: any) => x.id !== Classe.currentClass.id));
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoadEdit(false);
      setOpenDrawer();
    }
  };

  const EditClassFunction = async () => {
    setLoadEdit(true);

    try {
      const response = await EditClasseRequest({
        ...Classe.currentClass,
        level: {
          disconnect: [],
          connect: [
            {
              id: +Classe.currentClass.level,
              position: { end: true },
            },
          ],
        },
      });

      const newArray = Classe.listClasse.map((x: any) => {
        if (x.id === response.data.id) {
          return response.data;
        }

        return x;
      });

      setClasseList(newArray);
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoadEdit(false);
      setOpenDrawer();
    }
  };

  return {
    ...Classe,
    EditClassFunction,
    setOpenDrawer,
    setLoading,
    setLoadEdit,
    createClasse,
    setClasseList,
    setCurrentClass,
    fetchListClasse,
    fetchLevelRequest,
    deleteClasseRequest,
    fetchListClassePopulated,
    EditClasseRequest,
  };
};

export default useClasse;
