import './style.scss';
import BtnBack from '@fava/Button/Back';
import BtnSimple from '@fava/Button/Simple';
import { Table, TitlePage } from '@fava/Components';
import Drawer from '@fava/Components/Drawer';
import useClasse from '@fava/Hooks/useClasse';
import useStructure from '@fava/Hooks/useStrucuture';
import { useEffect, useState } from 'react';
import { head } from './constant';
import Edit from './Edit';

const AdminClasses = () => {
  const [comfirm, setComfirm] = useState(false);

  const { getParams } = useStructure();

  const {
    fetchListClassePopulated,
    loading,
    listClasse,
    setCurrentClass,
    currentClass,
    setOpenDrawer,
    openDrawer,
  } = useClasse();

  useEffect(() => {
    if (!openDrawer) {
      setComfirm(false);
    }
  }, [openDrawer]);

  useEffect(() => {
    fetchListClassePopulated(getParams('id_etablisement'));
  }, []);

  const onSelect = (data: any) => {
    setCurrentClass({
      ...data.attributes,
      level: data.attributes?.level?.data?.id,
      id: data.id,
    });
    setOpenDrawer();
  };

  return (
    <>
      <BtnBack to="/admin-structure/etablisement/view" />

      <div className="root-space-between">
        <div>
          <TitlePage nbr={listClasse.length}>Classe</TitlePage>
        </div>
        <div>
          <BtnSimple backgroundColor="#615EFF">Consulter les statistiques</BtnSimple>
        </div>
      </div>

      <div className="container-student">
        <Table head={head as any} body={listClasse} onSelect={onSelect} loading={loading} />
      </div>

      <Drawer
        open={openDrawer}
        handclose={setOpenDrawer}
        btnBack={!comfirm}
        middleWidth={!!currentClass?.id && !comfirm}>
        <Edit />
      </Drawer>
    </>
  );
};

export default AdminClasses;
