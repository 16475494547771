import './index.scss';
import './custom.scss';
import HyperDX from '@hyperdx/browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import SocketProvider from './NotifProvider';
import reportWebVitals from './reportWebVitals';
import { store } from './Store/store';

const { hostname } = new URL(process.env.REACT_APP_ENDPOINT);

console.log({
  apiKey: process.env.REACT_APP_HYPERDX_API_KEY,
  service: `${process.env.REACT_APP_LOCALSTORAGE_PREFIX}-frontend`,
  tracePropagationTargets: [new RegExp(hostname, 'i')], // Set to link traces from frontend to backend requests
  consoleCapture: true, // Capture console logs (default false)
  advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
});
HyperDX.init({
  apiKey: process.env.REACT_APP_HYPERDX_API_KEY,
  service: `${process.env.REACT_APP_LOCALSTORAGE_PREFIX}-frontend`,
  tracePropagationTargets: [new RegExp(hostname, 'i')], // Set to link traces from frontend to backend requests
  consoleCapture: true, // Capture console logs (default false)
  advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
});
HyperDX.setGlobalAttributes({
  appId: process.env.REACT_APP_LOCALSTORAGE_PREFIX,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <SocketProvider>
      <App />
    </SocketProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
