import './style.scss';
import { ReactComponent as Logo } from '@fava/Asset/Icon/Layout/logo.svg';
import useResetSession from '@fava/Hooks/useResetSession';
import PublicLayout from '@fava/Layout/Public';
import { routeAdminPrefix, routeStudentPrefix } from '@fava/Utils';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { faEye, faEyeSlash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { generateUsername, registerUser } from '../../../Services/user';

interface RouteParams {
  classId: string;
}

const Register: FC = () => {
  const [name, setName] = useState<any>({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useResetSession();
  const { push } = useHistory();
  const { classId } = useParams<RouteParams>();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    values: {
      firstname: '',
      lastname: '',
    } as any,
    shouldUseNativeValidation: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleBlur = async () => {
    const firstname = watch('firstname');
    const lastname = watch('lastname');
    if (!!firstname.trim().length && !!lastname.trim().length) {
      // Effectuer l'appel API pour générer le pseudo unique
      try {
        const response: any = await generateUsername({ firstname, lastname });
        setValue('username', response.data?.data?.username.toLowerCase());
      } catch (err) {
        console.error('error while generating username ', error);
        setError('Erreur lors de la récupération du pseudo');
      }
    }
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const response = await registerUser({
        ...data,
        firstname: data.firstname.trim(),
        lastname: data.lastname.trim(),
        password: data.password.trim(),
        class: +classId,
      });
      LocalStorageUtils.setItem('user_infos', JSON.stringify(response?.user));
      LocalStorageUtils.setItem('user_token', response?.jwt);
      if (response?.user?.role?.id === 1) {
        push(`/${routeStudentPrefix}/programs`);
      } else if (response?.user?.role?.id) {
        push(`/${routeAdminPrefix}/my-account`);
      }
    } catch (err) {
      if (err?.response?.data?.error?.name === 'ValidationError') {
        if (err?.response?.data?.error?.details?.code === 'missing_license') {
          setError("Compte crée mais votre établissement n'a pas encore de licence");
          return;
        }
        setError("Votre adresse mail / nom d'utilisateur ou votre mot de passe est  incorrecte ");
      } else {
        setError('Une erreur est survenue');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: any) => {
    setValue(
      e.target.name,
      `${e.target.value || ''}`.replace(/[^\w\s]/gi, '').replace(/[0-9]/gi, '')
    );
    setName({
      ...name,
      [e.target.name]: `${e.target.value || ''}`.replace(/[^\w\s]/gi, '').replace(/[0-9]/gi, ''),
    });
  };

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  return (
    <PublicLayout>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ zIndex: 10 }}>
        <div className="text-center register--logo">
          <Logo />
          <div style={{ marginTop: 42, marginBottom: 32 }}>
            <p className=" form-header-text-white">Bienvenue sur la plateforme Fava e-learning.</p>
            <p className="form-header-text-white">
              Pour finaliser votre inscription, veuillez créer votre compte :
            </p>
          </div>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} className="form-register">
          <div>
            <Form.Group controlId="formFirstname" className="text-white form-group-mg-bottom">
              <div className="label-container">
                <Form.Label className="text-white">Prénom</Form.Label>
                {errors.firstname && (
                  <FontAwesomeIcon color="#FF7E7E" icon={faTriangleExclamation} />
                )}
              </div>
              <Form.Control
                type="text"
                className="rounded-input input-text"
                {...register('firstname', {
                  onChange: handleChange,
                  required: 'Le champ est obligatoire',
                })}
                value={name.firstname || ''}
                onBlur={handleBlur}
              />
              {errors.firstname && (
                <Form.Text className="text-danger">{errors.firstname.message as any}</Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="formLastname" className="form-group-mg-bottom">
              <div className="label-container">
                <Form.Label className="text-white">Nom</Form.Label>
                {errors.lastname && (
                  <FontAwesomeIcon color="#FF7E7E" icon={faTriangleExclamation} />
                )}
              </div>
              <Form.Control
                type="text"
                className="rounded-input input-text"
                {...register('lastname', {
                  onChange: handleChange,
                  required: 'Le champ est obligatoire',
                })}
                value={name.lastname || ''}
                onBlur={handleBlur}
              />
              {errors.lastname && (
                <Form.Text className="text-danger">{errors.lastname.message as any}</Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="formUsername" className="form-group-mg-bottom">
              <Form.Label className="text-white">Pseudo - À RETENIR </Form.Label>
              <Form.Control
                type="text"
                disabled={true}
                className="rounded-input input-text"
                {...register('username', { required: 'Le champ est obligatoire' })}
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="form-group-mg-bottom">
              <div className="label-container">
                <Form.Label className="text-white">Mot de passe</Form.Label>
                {errors.password && (
                  <FontAwesomeIcon color="#FF7E7E" icon={faTriangleExclamation} />
                )}
              </div>
              <div className="password-input">
                <Form.Control
                  className="rounded-input input-text"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: 'Le champ est obligatoire',
                    minLength: {
                      value: 6,
                      message: 'Le champ mot de passe doit avoir au moins 6 caractères',
                    },
                  })}
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="password-icon"
                  color="#004F38"
                  onClick={togglePasswordVisibility}
                />
              </div>
              {errors.password && (
                <Form.Text className="text-danger">{errors.password.message as any}</Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="formConfirmPassword" className="form-group-mg-bottom">
              <div className="label-container">
                <Form.Label className="text-white">Confirmation mot de passe</Form.Label>
                {errors.confirmPassword && (
                  <FontAwesomeIcon color="#FF7E7E" icon={faTriangleExclamation} />
                )}
              </div>
              <div className="password-input">
                <Form.Control
                  className="rounded-input input-text"
                  type={showConfirmPassword ? 'text' : 'password'}
                  {...register('confirmPassword', {
                    required: 'Le champ est obligatoire',
                    minLength: {
                      value: 6,
                      message:
                        'Le champ confirmation mot de passe doit avoir au moins 6 caractères',
                    },
                  })}
                />
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                  className="password-icon"
                  color="#004F38"
                  onClick={toggleConfirmPasswordVisibility}
                />
              </div>
              {errors.confirmPassword && (
                <Form.Text className="text-danger">
                  {errors.confirmPassword.message as any}
                </Form.Text>
              )}
            </Form.Group>
          </div>
          {password !== confirmPassword && (
            <p className="text-danger">Les mots de passe ne sont pas identiques</p>
          )}
          <Form.Group controlId="formCheckbox" className="form-group-mg-top register--check d-flex">
            <Form.Check
              type="checkbox"
              {...register('cgu', { required: 'Vous devez accepter les CGU' })}
            />
            <span className="cgu-label">
              J&apos;accepte les
              <Link
                className="cgu-label cgu-label__link"
                to="/mentions-legales"
                target="_blank"
                rel="noopener noreferrer">
                Mentions légales
              </Link>{' '}
              et la
              <Link
                className="cgu-label cgu-label__link"
                to="/rgpd"
                target="_blank"
                rel="noopener noreferrer">
                RGPD
              </Link>
            </span>
          </Form.Group>
          <div>
            {errors.cgu && (
              <Form.Text className="text-danger">{errors.cgu.message as any}</Form.Text>
            )}
          </div>
          {!!error?.trim()?.length && <p className="text-danger">{error}</p>}
          <Button
            className="w-100 submit-btn"
            variant="primary"
            type="submit"
            disabled={!!Object.keys(errors).length}>
            {isLoading ? 'Inscription en cours...' : 'Finaliser mon inscription'}
          </Button>
        </Form>
      </div>
    </PublicLayout>
  );
};

export default Register;
