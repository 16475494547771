/* eslint-disable import/no-unresolved */
// eslint-disable-next-line simple-import-sort/exports
import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './Reduce/counter';
import QuestionAnswerReducer from './Reduce/QuestionAnswer';
import accountReducer from './Slices/accountSlice';
import avatarSlice from './Slices/avatarSlice';
import chapterReducer from './Slices/chapterSlice';
import classeReducer from './Slices/classeSlice';
import chapterContentsSlice from './Slices/contentsSlice';
import currentProgramReducer from './Slices/currentProgramSlice';
import currentUserChapterContentsReducer from './Slices/currentUserChapterContents';
import currentUserSlice from './Slices/currentUserSlice';
import etablishementReducer from './Slices/EtablishementSlice';
import modulesReducer from './Slices/modulesSlice';
import programsReducer from './Slices/programsSlice';
import socketReducer from './Slices/socket.slice';
import structureReducer from './Slices/structureSlice';
import studentSlice from './Slices/studentSlice';
import teacherReducer from './Slices/teacherSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    questionAnswer: QuestionAnswerReducer,
    programs: programsReducer,
    avatar: avatarSlice,
    modules: modulesReducer,
    chapter: chapterReducer,
    chapterContents: chapterContentsSlice,
    account: accountReducer,
    teacher: teacherReducer,
    classe: classeReducer,
    student: studentSlice,
    structure: structureReducer,
    etablishement: etablishementReducer,
    currentUser: currentUserSlice,
    currentProgram: currentProgramReducer,
    currentUserChapterContents: currentUserChapterContentsReducer,
    socket: socketReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
