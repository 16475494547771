/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const classeSlice: any = createSlice({
  name: 'classe',
  initialState: {
    openDrawer: false,
    currentClass: null,
    listLevel: [],
    listClasse: [],
    loading: false,
    loadingLevel: false,
    loadEdit: false,
  },
  reducers: {
    setListClasse: (state, action: PayloadAction<any>) => {
      state.listClasse = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoadEdit: (state, action: PayloadAction<boolean>) => {
      state.loadEdit = action.payload;
    },
    setLoadingLevel: (state, action: PayloadAction<boolean>) => {
      state.loadingLevel = action.payload;
    },
    setOpenDrawer: (state, action: PayloadAction<boolean>) => {
      state.openDrawer = action.payload;
    },
    setListLevel: (state, action: PayloadAction<any[]>) => {
      state.listLevel = action.payload;
    },
    setCurrentClass: (state, action: PayloadAction<any>) => {
      state.currentClass = action.payload;
    },
  },
});

export const classeAction = classeSlice.actions;

export default classeSlice.reducer;
