import './style.scss';
import usePrograms from '@fava/Hooks/usePrograms';
import { Status } from '@fava/Utils/constant';
import { FC } from 'react';
import Card from 'react-bootstrap/Card';
import { useHistory } from 'react-router-dom';
import ButtonFava from '../ButtonFava/ButtonFava';
import FavaProgressBar from '../ProgressBar/ProgressBarFava';

interface IProps {
  imageSrc: string;
  altText: string;
  cardTitle: string;
  cardDescription: string;
  buttonText: string;
  buttonLink: string;
  id: number;
  status?: string;
  count: number;
  moduleDoneCount: number;
}

const getCardColorCodesByStatus = (status: string) => {
  const defaultCode = {
    background: '#FFF',
    button: 'outline-primary',
    color: '#000',
    titleColor: '#004F38',
  };
  let code;
  switch (status) {
    case Status.done:
      code = { background: '#FFF', button: 'primary', color: '#000', titleColor: '#000' };
      break;

    case Status.in_progress:
      code = { background: '#004F38', button: 'primary', color: '#FFF', titleColor: '#FFF' };
      break;

    default:
      code = defaultCode;
  }
  return code;
};

const StackedCard: FC<IProps> = ({
  id,
  imageSrc,
  altText,
  cardTitle,
  cardDescription,
  buttonText,
  buttonLink,
  count,
  moduleDoneCount,
  status = Status.to_start,
}) => {
  const { setProgram } = usePrograms();

  const history = useHistory();

  const goToLink = () => {
    setProgram({
      id: id as number,
      description: cardDescription as string,
      title: cardTitle as string,
      img: imageSrc as string,
    });

    history.push(buttonLink);
  };

  const { background, button, color, titleColor } = getCardColorCodesByStatus(status);
  const now = moduleDoneCount;
  const label = `${count} modules`;

  return (
    <div className="card-wrapper card-vertical d-flex flex-column flex-grow-1">
      <img className="card-img" src={imageSrc} alt={altText} />
      <div
        className="card-vertical__body d-flex flex-column justify-content-between flex-grow-1"
        style={{ backgroundColor: background, color }}>
        <div>
          <FavaProgressBar isSuccesBar={false} text={label} color="white" value={now} />
          <Card.Title className=" card-vertical__title" style={{ color: titleColor }}>
            {cardTitle}
          </Card.Title>
          <div className=" card-vertical__text">
            <div className="p-style line-ellipsis line-ellipsis--3">{cardDescription}</div>
          </div>
        </div>

        <ButtonFava
          type={`button-fava--${
            buttonText === 'Démarrer' || buttonText === 'Reprendre' ? 'primary' : 'secondary'
          }`}
          text={buttonText}
          url={goToLink}
        />
      </div>
    </div>
  );
};

export default StackedCard;
