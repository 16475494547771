/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import io from 'socket.io-client';
import { setSocket } from './Store/Slices/socket.slice';

// Define the props type
interface SocketProviderProps {
  children: ReactNode;
}

const SocketProvider: FC<SocketProviderProps> = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_ENDPOINT}`, {
      upgrade: false,
    });

    newSocket.on('connect', () => {
      console.log('Connected to server via WebSocket');
    });

    newSocket.on('disconnect', reason => {
      console.log('Disconnected from server:', reason);
    });

    newSocket.on('connect_error', error => {
      console.error('Connection error:', error);
    });

    dispatch(setSocket(newSocket));
    return () => {
      newSocket.disconnect();
    };
  }, [dispatch]);

  return <>{children}</>;
};

export default SocketProvider;
