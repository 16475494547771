import '../style.scss';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import { FC, ReactNode } from 'react';

interface IContent {
  done?: boolean;
  end: boolean;
  start: boolean;
  children: ReactNode;
  repeat?: boolean;
}

const Content: FC<IContent> = ({ done, end, start, children, repeat }) => {
  const iconTimeLine = () => {
    if (repeat) {
      return (
        <span className="uk-badge uk-repeat">
          <ClearIcon />
        </span>
      );
    }

    if (done) {
      return (
        <span className="uk-badge uk-done">
          <DoneIcon />
        </span>
      );
    }

    return <span className="uk-badge" />;
  };

  return (
    <div className="uk-timeline-item">
      {start && <div className="uk-line-time-line-start" />}

      {end && <div className="uk-line-time-line-end" />}

      <div className="uk-line-time-line" />

      <div className="uk-timeline-icon">{iconTimeLine()}</div>

      <div className="uk-timeline-content">{children}</div>
    </div>
  );
};

export default Content;
