import './style.scss';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { FC } from 'react';

interface IChipTime {
  time: number;
}

const ChipTime: FC<IChipTime> = ({ time }) => {
  return (
    <div className="chip-time-contains">
      <div className="p-normal"> 🕘 {time} min</div>
    </div>
  );
};

export default ChipTime;
