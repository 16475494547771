import './style.scss';
import BtnSimple from '@fava/Button/Simple';
import { FC, MouseEvent } from 'react';

interface IModules {
  id: number;
  img: any;
  title: string;
  description: string;
  handleClick: (id: number, title: string) => void;
}

const AdminProgram: FC<IModules> = ({ id, img, title, description, handleClick }) => {
  const whenBtnClicked = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleClick(id, title);
  };

  return (
    <div className="admin-card-program">
      <div className="admin-card-content-img">
        <img src={img} alt="admin-card-program" className="admin-card-program-img" />
      </div>

      <div className="admin-card-content">
        <h1 className="full-width h1-normal">{title}</h1>

        <p>{description}</p>

        <BtnSimple
          fullWidth={true}
          borderRadius={false}
          backgroundColor="#615EFF"
          onClick={whenBtnClicked}>
          Consulter
        </BtnSimple>
      </div>
    </div>
  );
};

export default AdminProgram;
