import './style.scss';
import { ReactComponent as Logo } from '@fava/Asset/Icon/Layout/logo.svg';
import useQueryString from '@fava/Hooks/useQueryString';
import useResetSession from '@fava/Hooks/useResetSession';
import PublicLayout from '@fava/Layout/Public';
import {
  getUserAdminInfo,
  getUserAdminStructure,
  getUserStudent,
  getUserTeacher,
} from '@fava/Store/Thunks/fetchAccount';
import { routeAdminPrefix, routeStudentPrefix } from '@fava/Utils';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { faEye, faEyeSlash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetPassword } from '../../../Services/user';

const SetPassword: FC = () => {
  useResetSession();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: false });

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const qs = useQueryString();
  const { push } = useHistory();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const response = await resetPassword({
        password: data.password.trim(),
        passwordConfirmation: data.confirmPassword.trim(),
        code: qs.get('c'),
      });

      LocalStorageUtils.setItem('user_infos', JSON.stringify(response?.data?.user));
      LocalStorageUtils.setItem('user_token', response?.data?.jwt);

      switch (response?.data?.user?.role?.id) {
        // student
        case 1:
          push(`/${routeStudentPrefix}/programs`);
          dispatch(getUserStudent(response?.data?.user?.id) as any);
          break;
        // EstablishmentAdmin
        case 4:
          dispatch(getUserAdminInfo(response?.data?.user?.id) as any);
          push(`/${routeAdminPrefix}/account`);
          break;
        // Professor
        case 6:
          dispatch(getUserTeacher(response?.data?.user?.id) as any);
          push(`/teacher/account`);
          break;
        // StructureAdmin
        case 3:
          dispatch(getUserAdminStructure(response?.data?.user?.id) as any);
          push(`/admin-structure/account`);
          break;
        default:
          console.log(`Sorry, we are out of ${response?.data?.user?.role?.id}.`);
      }
    } catch (err) {
      if (err?.response?.data?.error?.name === 'ValidationError') {
        setError('Vos mots de passes sont  incorrectes');
      } else {
        setError('Une erreur est survenue');
      }
    } finally {
      setIsLoading(false);
    }
  };
  const password = watch('password');
  const confirmPassword = watch('confirmPassword');
  return (
    <PublicLayout>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          position: 'fixed',
          top: '25vh',
          minWidth: '33vw',
        }}>
        <div className="text-center">
          <Logo />
          <p className="text-white-connection" style={{ marginTop: 30 }}>
            Définir mon mot de passe
          </p>
        </div>
        <Form.Group controlId="formPassword" className="form-group-mg-top">
          <div className="label-container">
            <Form.Label className="text-white">Mot de passe</Form.Label>
            {errors.password && <FontAwesomeIcon color="#FF7E7E" icon={faTriangleExclamation} />}
          </div>
          <div className="password-input">
            <Form.Control
              className="rounded-input input-text"
              type={showPassword ? 'text' : 'password'}
              {...register('password', {
                required: 'Le champ est obligatoire',
                minLength: {
                  value: 6,
                  message: 'Le champ mot de passe doit avoir au moins 6 caractères',
                },
              })}
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              className="password-icon"
              color="#004F38"
              onClick={togglePasswordVisibility}
            />
          </div>
          {errors.password && (
            <Form.Text className="text-danger">{errors.password.message as any}</Form.Text>
          )}
        </Form.Group>

        <Form.Group controlId="formConfirmPassword" className="form-group-mg-top">
          <div className="label-container">
            <Form.Label className="text-white">Confirmation mot de passe</Form.Label>
            {errors.confirmPassword && (
              <FontAwesomeIcon color="#FF7E7E" icon={faTriangleExclamation} />
            )}
          </div>
          <div className="password-input">
            <Form.Control
              className="rounded-input input-text"
              type={showConfirmPassword ? 'text' : 'password'}
              {...register('confirmPassword', {
                required: 'Le champ est obligatoire',
                minLength: {
                  value: 6,
                  message: 'Le champ confirmation mot de passe doit avoir au moins 6 caractères',
                },
              })}
            />
            <FontAwesomeIcon
              icon={showConfirmPassword ? faEyeSlash : faEye}
              className="password-icon"
              color="#004F38"
              onClick={toggleConfirmPasswordVisibility}
            />
          </div>
          {errors.confirmPassword && (
            <Form.Text className="text-danger">{errors.confirmPassword.message as any}</Form.Text>
          )}
        </Form.Group>
        {password !== confirmPassword && (
          <p className="text-danger">Les mots de passe ne sont pas identiques</p>
        )}
        {!!error?.trim()?.length && <p className="text-danger">{error}</p>}
        <Button
          className="w-100 form-group-mg-top submit-btn"
          variant="primary"
          type="submit"
          disabled={!!Object.keys(errors).length}>
          {isLoading ? 'Inscription en cours...' : 'Finaliser mon inscription'}
        </Button>
      </Form>
    </PublicLayout>
  );
};

export default SetPassword;
