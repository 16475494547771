import './style.scss';
import BtnSimple from '@fava/Button/Simple';
import { TitlePage } from '@fava/Components';
import useClasse from '@fava/Hooks/useClasse';
import { validationForm } from '@fava/Utils/validationForm';
import Form from 'react-bootstrap/Form';

const AddClasses = () => {
  const { listLevel, currentClass, setCurrentClass, createClasse, loadEdit } = useClasse();

  const handleChange = (e: any) => {
    const { value, name } = e.target;

    setCurrentClass({
      ...currentClass,
      [name]: value,
    });
  };

  const validate = () => {
    const error = validationForm(currentClass, ['level', 'name']);

    setCurrentClass({
      ...currentClass,
      error: error as any,
    });

    if (!Object.keys(error).length) {
      createClasse();
    }
  };

  return (
    <div>
      <div className="margin">
        <TitlePage>Créer une classe</TitlePage>
      </div>

      <div className="margin">
        <Form.Group className={`mb-3 ${currentClass?.error?.level && 'form-error-input'}`}>
          <Form.Label className="color-fava-admin">Niveau de la classe</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="level"
            onChange={handleChange}
            value={currentClass?.level}>
            <option>Choisir un niveau</option>
            {listLevel.map((x: any) => (
              <option value={x.id}>{x.attributes.name || ''}</option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>

      <div className="margin">
        <Form.Group className={`mb-3 ${currentClass?.error?.name && 'form-error-input'}`}>
          <Form.Label className="color-fava-admin">Nom de la classe</Form.Label>
          <Form.Control name="name" onChange={handleChange} value={currentClass?.name} />
        </Form.Group>
      </div>

      <div className="root-center-flex m-2">
        <div>
          <BtnSimple
            disabled={loadEdit}
            backgroundColor="#615EFF"
            borderRadius={false}
            onClick={validate}
            loading={loadEdit}>
            Confirmer
          </BtnSimple>
        </div>
      </div>
    </div>
  );
};

export default AddClasses;
