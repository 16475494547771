import BtnSimple from '@fava/Button/Simple';
import AdminProgram from '@fava/Card/AdminProgram';
import CardHelp from '@fava/Card/Help';
import Loader from '@fava/Components/Loader/Loader';
import useStructure from '@fava/Hooks/useStrucuture';
import { useThunk } from '@fava/Hooks/useThunk';
import { fetchProgramByEtablissement } from '@fava/Store/Thunks/fetchPrograms';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from './Modal';

const Program = () => {
  const [doFetchProgramsByEtablissement, loading] = useThunk(fetchProgramByEtablissement);

  const { getParams } = useStructure();

  const [id, setId] = useState(0);
  const [title, settitle] = useState('');

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const { data } = useSelector((state: any) => {
    return state.programs;
  });

  const handleClickProgram = (idProgram: number, titleProgram: string) => {
    setId(idProgram);
    settitle(titleProgram);
    setOpen(true);
  };

  useEffect(() => {
    doFetchProgramsByEtablissement(getParams('id_etablisement'));
  }, []);

  const contactFava = () => {
    console.log('');
  };

  if (loading) {
    return <Loader bg="loader-custom--transparent" />;
  }

  console.log('data', data);

  return (
    <div>
      <div className="margin-big">
        <h1 className="h1-normal color-fava-admin w-30 size-big-text">Programmes de ma licence</h1>
      </div>

      <div className="root-space-between">
        <div className="midle-width">
          {data.map(program => {
            const getImg = (program.attributes.image?.data || []).length
              ? program.attributes.image?.data[0]
              : {};

            return (
              <AdminProgram
                id={program.id}
                img={getImg?.attributes?.url}
                title={program.attributes.title}
                description={program.attributes.description}
                handleClick={handleClickProgram}
              />
            );
          })}
        </div>

        <div>
          <CardHelp
            title="Ajouter des utilisateurs à votre licence "
            description="L’équipe intim’ed est à votre disponibilité n the other hand, we denounce with righteous indignation"
            backgroundColor="#004F38"
            btn={
              <BtnSimple color="white" borderRadius={false} onClick={contactFava}>
                <div className="text-btn">Contactez intim’ed</div>
              </BtnSimple>
            }
          />
        </div>
      </div>

      <Modal id={id} open={open} handleOpen={handleOpen} title={title} />
    </div>
  );
};

export default Program;
