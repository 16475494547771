import { structureAction } from '@fava/Store/Slices/structureSlice';
import { RootState } from '@fava/Store/store';
import { findStructure, udpateStructure } from '@fava/Store/Thunks/fetchStructure';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { useDispatch, useSelector } from 'react-redux';

const useStructure = () => {
  const data: any = useSelector((state: RootState) => state.structure);

  const account: any = useSelector((state: RootState) => state.account);

  const dispatch = useDispatch();

  const setStructure = (x: any) => {
    dispatch(structureAction.setStructure(x));
  };

  const findStructureRequest = async () => {
    return findStructure(account.data?.id);
  };

  const udpateStructureRequest = async (structure: any) => {
    return udpateStructure(structure || null);
  };

  const setParams = (name: string, value: any) => {
    const lasted: any = LocalStorageUtils.getItem('param') || {};
    LocalStorageUtils.setItem('param', {
      ...lasted,
      [name]: value,
    });
  };

  const getParams = (name: string) => {
    const lasted = LocalStorageUtils.getItem('param');
    return lasted[name];
  };

  return {
    ...data,
    setParams,
    getParams,
    setStructure,
    findStructureRequest,
    udpateStructureRequest,
  };
};

export default useStructure;
