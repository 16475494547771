import './style.scss';
import ButtonFava from '@fava/Components/ButtonFava/ButtonFava';
import Loader from '@fava/Components/Loader/Loader';
import useAccount from '@fava/Hooks/useAccount';
import useAvatar from '@fava/Hooks/useAvatar';
import { FC, useEffect } from 'react';

const Avatar: FC = () => {
  const { loadingList, loadingUpdate, listAvatar, fetchListAvatarRequest, udpateAvatarRequest } =
    useAvatar();

  const { data, setData } = useAccount();

  useEffect(() => {
    fetchListAvatarRequest();
  }, []);

  if (loadingList) {
    return <Loader />;
  }

  const selected = (x: any) => (e: any) => {
    setData({
      ...data,
      avatar: {
        id: x.id,
        url: x.attributes?.image?.data?.attributes?.url,
      },
    });
  };

  return (
    <div className="bg--grey-light">
      <div className="container-fluid container-fluid--max-design p-5">
        <div className="heading-2 text--fava-green text-center">Choisis ton avatar !</div>

        <div className="container">
          <div className="row">
            {listAvatar
              .filter((x: any) => x?.attributes?.image?.data?.attributes?.url)
              .map((x: any) => {
                return (
                  <div className="col col-xs-4 col-md-2">
                    <div
                      className={`profile__media profile__media${x?.id === data?.avatar?.id && '--bordered'
                        } flex-shrink-0`}
                      onClick={selected(x)}>
                      <img
                        src={x?.attributes?.image?.data?.attributes?.url}
                        alt="img title ChipUser"
                      />

                      {x?.id === data?.avatar?.id && (
                        <div className="profile__media-edit">
                          <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M24 1C11.3142 1 1 11.3142 1 24C1 36.6858 11.3142 47 24 47C36.6858 47 47 36.6858 47 24C47 11.3142 36.6858 1 24 1Z"
                              fill="#004F38"
                            />
                            <path
                              d="M14 23.0323L21.6 31L34 18"
                              stroke="white"
                              strokeWidth="5"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="root-center-flex">
          <div className="w-25 m-4">
            <ButtonFava
              disabled={loadingUpdate}
              text={loadingUpdate ? 'Enregistrement... ' : 'Enregistrer'}
              type="button-fava--primary "
              url={udpateAvatarRequest(data?.id_user, data?.avatar?.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Avatar;
