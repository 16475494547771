import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { createAsyncThunk } from '@reduxjs/toolkit';

const qs = require('qs');

const user_infos: string = LocalStorageUtils.getItem('user_infos') || '';
const findOneUser = async (idUser: number) => {
  const params = qs.stringify({
    populate: [
      'structure_admin',
      'establishment_admin',
      'establishment_admin.establishment',
      'professor_user',
      'role',
    ],
  });

  const response: any = await makeRequest(`${apiUrl}/api/users/${idUser}?${params}`, 'GET', {});

  return response.data;
};

const getUserAdminInfo = createAsyncThunk('users/admin', async (id?: string) => {
  const userId = user_infos ? JSON.parse(user_infos).id : id;

  try {
    const params = qs.stringify({
      populate: [
        'structure_admin',
        'establishment_admin',
        'establishment_admin.establishment',
        'professor_user',
        'role',
      ],
    });
    const response: any = await makeRequest(`${apiUrl}/api/users/${userId}?${params}`, 'GET', {});
    return { ...response?.data?.establishment_admin, role: response?.data?.role };
  } catch (error) {
    return { error: 'err_fetch' };
  }
});

const getUserAdminStructure = createAsyncThunk('users/admin', async (id?: string) => {
  const userId = user_infos ? JSON.parse(user_infos).id : id;

  try {
    const params = qs.stringify({
      populate: [
        'structure_admin',
        'establishment_admin',
        'establishment_admin.establishment',
        'professor_user',
        'role',
      ],
    });
    const response: any = await makeRequest(`${apiUrl}/api/users/${userId}?${params}`, 'GET', {});
    return { ...response?.data?.structure_admin, role: response?.data?.role };
  } catch (error) {
    return { error: 'err_fetch' };
  }
});

const getUserTeacher = createAsyncThunk('users/admin', async (id?: string) => {
  const userId = user_infos ? JSON.parse(user_infos).id : id;
  try {
    const params = qs.stringify({
      populate: ['professor_user', 'role'],
    });
    const response: any = await makeRequest(`${apiUrl}/api/users/${userId}?${params}`, 'GET', {});
    return { ...response?.data?.professor_user, role: response?.data?.role };
  } catch (error) {
    return { error: 'err_fetch' };
  }
});

const getUserStudent = createAsyncThunk('users/admin', async (id?: string) => {
  const userId = user_infos ? JSON.parse(user_infos).id : id;
  try {
    const response: any = await makeRequest(`${apiUrl}/api/me-student/${userId}`, 'GET', {});

    return response?.data;
  } catch (error) {
    return { error: 'err_fetch' };
  }
});

const udpateUser = async (data: any, type: string) => {
  let url = `${apiUrl}/api/establishment-users/${data.id}`;

  if (type === 'structure_admin') {
    url = `${apiUrl}/api/establishment-users/${data.id}`;
  }

  if (type === 'professor_user') {
    url = `${apiUrl}/api/professor-users/${data.id}`;
  }

  const response: any = await makeRequest(url, 'PUT', { data });

  return response.data;
};

export {
  findOneUser,
  getUserAdminInfo,
  getUserAdminStructure,
  getUserStudent,
  getUserTeacher,
  udpateUser,
};
