import '../style.scss';
import start from '@fava/Asset/Icon/trophy-dynamic-color.svg';
import { FC } from 'react';

interface IWin {
  score: number;
  textLigth?: boolean;
}

const Win: FC<IWin> = ({ score, textLigth }) => {
  return (
    <div className="position-relative">
      <img src={start} alt="start good-response" className="img-icon-good-reponse" />

      <div
        className={`${textLigth ? 'good-response-color-light' : 'good-response'}  margin-in-score`}>
        <h1>{score}</h1>

        <p className={`p-normal ${textLigth && 'text-light'}`}>bonnes réponses</p>
      </div>
    </div>
  );
};

export default Win;
