import { getUserStudent } from '@fava/Store/Thunks/fetchAccount';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import StudentRoutes from './studentRoute';

const IndexStudent = () => {
  const dispatch = useDispatch();

  const user_infos: any = LocalStorageUtils.getItem('user_infos') || '';
  useEffect(() => {
    if (user_infos) {
      dispatch(getUserStudent(JSON.parse(user_infos)?.id) as any);
    }
  }, [dispatch]);

  return (
    <Router>
      <div className="section-student flex-grow-1">
        <StudentRoutes />
      </div>
    </Router>
  );
};

export default IndexStudent;
