import { LocalStorageUtils } from '@fava/Utils/localStorage';
import configRole from '@fava/Utils/role';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserAdminInfo } from '../Thunks/fetchAccount';

const user_infos = LocalStorageUtils.getItem('dataUser') as any;
interface AccountState {
  blockFetch: boolean;
  data: any;
  loading: boolean;
  type: string;
}
// TODO  MUST TYPE WELL, ERROR SINCE THE BEGINNING OF DEV, MUST SPEAK WITH THE OTHER DEV TO HAVE A COMMON TYPE FOR AN ACCOUNT
const initialState: AccountState = {
  blockFetch: false,
  data: LocalStorageUtils.getItem('dataUser') || { tochka: 0 },
  loading: true,
  type: LocalStorageUtils.getItem('dataUser') ? configRole[user_infos?.role?.name] : '',
};

const accountSlice: any = createSlice({
  name: 'account',
  initialState,

  reducers: {
    setData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setBlockFetch: (state, action: PayloadAction<boolean>) => {
      state.blockFetch = action.payload;
    },

    updateScoring: (state, action: PayloadAction<number>) => {
      state.data = { ...state.data, tochka: state.data.tochka + action.payload };
      LocalStorageUtils.setItem('dataUser', {
        ...state.data,
        tochka: state.data.tochka + action.payload,
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUserAdminInfo.pending, state => {
        state.data = null;
        state.loading = true;
        state.type = '';
      })
      .addCase(getUserAdminInfo.fulfilled, (state, action) => {
        LocalStorageUtils.setItem('dataUser', action.payload);
        const role = configRole[action.payload?.role?.name];
        state.loading = false;
        state.data = action.payload;
        state.type = role;
      })
      .addCase(getUserAdminInfo.rejected, state => {
        state.loading = false;
      });
  },
});

export const accountAction = accountSlice.actions;

export default accountSlice.reducer;
