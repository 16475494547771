import { programsAction } from '@fava/Store/Slices/programsSlice';
import { RootState } from '@fava/Store/store';
import { useDispatch, useSelector } from 'react-redux';

const usePrograms = () => {
  const program: any = useSelector((state: RootState) => state.programs);

  const dispatch = useDispatch();

  const setProgram = (data: any) => {
    dispatch(programsAction.setProgram(data));
  };

  return {
    ...program,
    setProgram,
  };
};

export default usePrograms;
