import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';
import { IUserLoginParams, IUserResetPassordParams, IUserSignupParams } from 'src/global';
import { Chapter, Module, Program, StatBarchart } from 'src/Models';

const login = async (data: IUserLoginParams) => {
  return makeRequest(`${apiUrl}/api/auth/local?populate=*`, 'POST', data);
};

const registerUser = async (data: IUserSignupParams) => {
  const response: any = await makeRequest(`${apiUrl}/api/auth/local/register`, 'POST', data);
  return response.data;
};

const resetPassword = async (data: IUserResetPassordParams) => {
  const response: any = await makeRequest(`${apiUrl}/api/auth/reset-password`, 'POST', data);
  return response;
};

const forgotPassword = async (data: { emailOrUsername: string }) => {
  const response: any = await makeRequest(`${apiUrl}/api/auth/forgot-password`, 'POST', data);
  return response;
};

const getStatisticStudent = async (): Promise<Program[]> => {
  const response: any = await makeRequest(`${apiUrl}/api/statistic-students`, 'GET');
  return response?.data;
};

const getStatisticStudentAdmin = async (id: string): Promise<Program[]> => {
  const response: any = await makeRequest(`${apiUrl}/api/statistic-students/admin/${id}`, 'GET');
  return response?.data;
};

const getStatClass = async (id: string): Promise<Program[]> => {
  const response: any = await makeRequest(`${apiUrl}/api/stat-class/${id}`, 'GET');
  return response?.data;
};

const generateUsername = async data => {
  const response: any = await makeRequest(
    `${apiUrl}/api/users-permissions/generate-username`,
    'POST',
    data
  );
  return response;
};

const calculateAveragePercentageProgram = (data: Module[]): number => {
  if (data.length > 0) {
    const filteredChapters = data.flatMap(module =>
      module.chapters.filter(chapter => chapter.quizzEnd === true)
    );

    const totalPercentage = filteredChapters.reduce(
      (accumulator, chapter) => accumulator + chapter.percentageScoringFinaleQuizz,
      0
    );

    const averagePercentage = totalPercentage / filteredChapters.length || 0;

    return averagePercentage || 0;
  }
  return 0;
};

const calculateAveragePercentage = (data: Module[]): number => {
  if (data.length > 0) {
    const modulePercentages = data.map(module => module.percentage);
    const totalPercentage = modulePercentages.reduce(
      (accumulator, percentage) => accumulator + percentage,
      0
    );
    const averagePercentage = totalPercentage / modulePercentages.length || 0;

    return averagePercentage || 0;
  }
  return 0;
};

const calculateAveragePercentageClass = (data: Module[]): number => {
  if (data.length > 0) {
    const modulePercentages = data.map(module => module.statForModule.progressingRate);
    const totalPercentage = modulePercentages.reduce(
      (accumulator, percentage) => accumulator + percentage,
      0
    );
    const averagePercentage = totalPercentage / modulePercentages.length || 0;

    return averagePercentage || 0;
  }
  return 0;
};

const calculateSuccesProgramClass = (data: Module[]): number => {
  if (data.length > 0) {
    const modulePercentages = data.map(module => module.statForModule.successPercentage);
    const totalPercentage = modulePercentages.reduce(
      (accumulator, percentage) => accumulator + percentage,
      0
    );
    const averagePercentage = totalPercentage / modulePercentages.length || 0;

    return averagePercentage || 0;
  }
  return 0;
};
const calculateAverageChapterDuration = (data: Module[]): string => {
  const totalDuration = data
    .flatMap(module => module.chapters.map(chapter => chapter.chapterDuration))
    .reduce((accumulator, duration) => accumulator + duration, 0);

  const averageDuration = totalDuration / data.length;

  const hours = Math.floor(averageDuration / 60);
  const minutes = Math.floor(averageDuration % 60)
    .toString()
    .padStart(2, '0');

  const formattedDuration = `${hours}h${minutes}`;

  return formattedDuration !== 'NaNhNaN' ? formattedDuration : '0h00';
};

const calculateAverageByChapterDuration = (data: Chapter[]): string => {
  const totalDuration = data
    .flatMap(() => data.map(chapter => chapter.chapterDuration))
    .reduce((accumulator, duration) => accumulator + duration, 0);

  const averageDuration = totalDuration / data.length;

  const hours = Math.floor(averageDuration / 60);
  const minutes = Math.floor(averageDuration % 60)
    .toString()
    .padStart(2, '0');

  const formattedDuration = `${hours}h${minutes}`;

  return formattedDuration !== 'NaNhNaN' ? formattedDuration : '0h00';
};

const calculateTotalChapterDuration = (data: Program[]): string => {
  const totalDuration = data.reduce((accumulator, program) => {
    const moduleDurations = program.modules.reduce((moduleAccumulator, module) => {
      if (module.statusModule === 'finished') {
        const chapterDurationSum = module.chapters.reduce(
          (chapterAccumulator, chapter) => chapterAccumulator + chapter.chapterDuration,
          0
        );
        return moduleAccumulator + chapterDurationSum;
      }
      return moduleAccumulator;
    }, 0);
    return accumulator + moduleDurations;
  }, 0);

  const hours = Math.floor(totalDuration / 60);
  const minutes = Math.floor(totalDuration % 60)
    .toString()
    .padStart(2, '0');

  const formattedDuration = `${hours}h${minutes}`;

  return formattedDuration;
};

const calculateModuleRatio = (data: Program[]): string => {
  let totalModules = 0;
  let modulesWithQuizzEnd = 0;

  data?.forEach(program => {
    program?.modules?.forEach(module => {
      module?.chapters.forEach(chapter => {
        if (chapter?.quizzEnd === true && chapter.percentageScoringFinaleQuizz >= 70) {
          modulesWithQuizzEnd++;
        }
      });
      totalModules++;
    });
  });

  const ratio = `${modulesWithQuizzEnd}/${totalModules}`;

  return ratio;
};

const calculateAveragePercentageChapterBarChart = (data: Chapter[]) => {
  const quizzesEnded = data.filter(item => item.quizzEnd);

  if (quizzesEnded.length === 0) {
    return 0;
  }
  const totalPercentage = quizzesEnded.reduce(
    (sum, item) => sum + item.percentageScoringFinaleQuizz,
    0
  );

  return totalPercentage / quizzesEnded.length;
};

const statForBarchart = (data: Program): StatBarchart[] => {
  const response =
    data?.modules?.length > 0 &&
    data?.modules?.map(res => {
      return {
        name: res.title,
        value: res.percentage,
        comparaisonValue:
          res.chapters.length > 0 ? calculateAveragePercentageChapterBarChart(res.chapters) : 0,
        moyenne: res.chapters.length > 0 ? calculateAverageByChapterDuration(res.chapters) : '',
      };
    });

  return data?.modules?.length > 0
    ? response
    : [
      {
        name: "Le programme n'inclut pas de module.",
        value: 0,
        comparaisonValue: 0,
        moyenne: '0h00',
      },
    ];
};

const statForBarchartClass = (data: Program): StatBarchart[] => {
  const response =
    data?.modules?.length > 0 &&
    data?.modules?.map(res => {
      return {
        name: res.title,
        value: res.statForModule.progressingRate,
        comparaisonValue: res.statForModule.successPercentage,
        moyenne: res.chapters.length > 0 ? calculateAverageByChapterDuration(res.chapters) : '',
      };
    });

  return data?.modules?.length > 0
    ? response
    : [
      {
        name: "Le programme n'inclut pas de module.",
        value: 0,
        comparaisonValue: 0,
        moyenne: '0h00',
      },
    ];
};

export {
  calculateAverageChapterDuration,
  calculateAveragePercentage,
  calculateAveragePercentageClass,
  calculateAveragePercentageProgram,
  calculateModuleRatio,
  calculateSuccesProgramClass,
  calculateTotalChapterDuration,
  forgotPassword,
  generateUsername,
  getStatClass,
  getStatisticStudent,
  getStatisticStudentAdmin,
  login,
  registerUser,
  resetPassword,
  statForBarchart,
  statForBarchartClass,
};
