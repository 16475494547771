import './style.scss';
import { ReactNode } from 'react';

interface ITitle {
  children: ReactNode;
  nbr?: number;
}

const Title = (props: ITitle) => {
  const { children, nbr } = props as ITitle;

  return (
    <div className="heading-1 color-fava-admin">
      {children}
      {nbr > 0 && <span className="span-TitlePage">{nbr}</span>}
    </div>
  );
};

export default Title;
