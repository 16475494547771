/* eslint-disable no-unused-expressions */
import './Footer.scss';
import { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { selectSocket } from '../../Store/Slices/socket.slice';

const Footer: FC<any> = (): ReactElement => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const socket = useSelector(selectSocket);

  const logoutFunction = () => {
    push('/disconnect-sso');
  };
  useEffect(() => {
    if (socket) {
      socket.on('cas_gar_disconnect', (x: any) => {
        if (localStorage.getItem('session') && x.id === localStorage.getItem('session')) {
          const link = document.getElementById('legalMentionsLink') as HTMLAnchorElement;
          if (link) {
            link.click();
            window.location.reload();
          }
        }
      });
    }

    return () => {
      if (socket) {
        socket.off('cas_gar_disconnect');
      }
    };
  }, [socket, dispatch]);

  return (
    <footer>
      <div className="footer" id="footer">
        <ul className="footer_list">
          <li>Intim&apos;Ed 2023</li>
          <li>
            <Link
              className="cgu-label cgu-label__link"
              to="/disconnect-sso"
              id="legalMentionsLink"
              style={{ display: 'none' }}>
              Mentions légales
            </Link>
            <Link
              className="cgu-label cgu-label__link"
              to="/mentions-legales"
              target="_blank"
              rel="noopener noreferrer">
              Mentions légales
            </Link>

            <Link
              className="cgu-label cgu-label__link"
              to="/rgpd"
              target="_blank"
              rel="noopener noreferrer">
              RGPD
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};
export default Footer;
