import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';

const qs = require('qs');

const fetchChapitreById = async (idChapter: number, idModules: number, idProgramme: number) => {
  const params = qs.stringify({
    idModules: idModules as number,
    idProgramme: idProgramme as number,
    idChapter: idChapter as number,
  });

  const response: any = await makeRequest(`${apiUrl}/api/get-chapter?${params}`, 'GET', {});

  return response.data;
};

const fetchChapitreByModules = async (idModules: number, idProgram: number) => {
  const response: any = await makeRequest(
    `${apiUrl}/api/chapters-by-module/${idModules}`,
    'GET',
    {}
  );

  return response;
};

const fetchChapitreByModulesTeacher = async (idModules: number) => {
  const params = qs.stringify(
    {
      populate: ['chapters.ficheStudent', 'chapters.ficheTeacher'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(
    `${apiUrl}/api/modules/${idModules}?${params}`,
    'GET',
    {}
  );
  return response.data;
};

const saveAnswer = async (answer: any[]) => {
  const response: any = await makeRequest(`${apiUrl}/api/save-answer-question`, 'POST', answer);

  return response.data;
};

const fetchChapterById = async (id: number) => {
  const params = qs.stringify(
    {
      populate: [
        'ficheStudent',
        'ficheTeacher',
        'contents',
        'contents.image',
        'contents.video',
        'questions.responses.image',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/chapters/${id}?${params}`, 'GET', {});

  return response.data;
};

export {
  fetchChapitreById,
  fetchChapitreByModules,
  fetchChapitreByModulesTeacher,
  fetchChapterById,
  saveAnswer,
};
