import './style.scss';
import ButtonFava from '@fava/Components/ButtonFava/ButtonFava';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FC, ReactNode, useEffect } from 'react';

interface IDrawer {
  open: boolean;
  handclose: () => void;
  children: ReactNode;
  btnBack?: boolean;
  middleWidth?: boolean;
  isStat?: boolean;
}

const Drawer: FC<IDrawer> = ({
  open,
  children,
  btnBack = true,
  handclose,
  middleWidth,
  isStat,
}) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  return (
    <div>
      {open && <div className="background-black" onClick={handclose} />}

      <div
        className={`drawer ${open ? 'animate-drawer-open' : 'animate-drawer-close'} ${
          middleWidth && 'middle-width'
        }`}>
        <div className="drawer-group">
          {btnBack && (
            <div className="pop-drawer">
              <div className="root-center-flex-start" onClick={handclose}>
                <div className="arrow-back">
                  <IconButton>
                    <ArrowBackIcon fontSize="small" />
                  </IconButton>
                  <p>Retour</p>
                </div>
              </div>
              {isStat && (
                <div className="pop-drawer__btns">
                  <ButtonFava type="button-fava--rose button-fava--small" text="Avancement" />
                  <ButtonFava type="button-fava--fava-green button-fava--small" text="réussite" />
                </div>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Drawer;
