import './style.scss';
import Loader from '@fava/Components/Loader/Loader';
import useAccount from '@fava/Hooks/useAccount';
import { path_img } from '@fava/Utils';
import { useEffect } from 'react';
import { BrowserRouter as Router, NavLink, useHistory } from 'react-router-dom';
import AdminRoutes from './adminRouters';

const IndexAdminStructure = () => {
  const { data, findUserRequest, loading } = useAccount();

  useEffect(() => {
    findUserRequest();
  }, []);

  const { push } = useHistory();

  const logoutFunction = () => push('/');

  if (loading) {
    return <Loader bg="loader-custom--transparent" />;
  }

  return (
    <Router>
      <div className="sidebar-content-wrapper d-flex">
        <div className="sidebar d-flex  flex-column justify-content-start align-items-center flex-shrink-0">
          <div className="sidebar__top d-flex flex-column align-items-center">
            <img
              alt="logo Fava"
              src={`${path_img}/logo.svg`}
              className="site-logo"
              draggable={false}
            />

            <ul className="menu-fava menu-fava--mt menu-fava--mb">
              <li>
                <NavLink
                  to="/admin-structure/etablisement"
                  className="d-flex align-items-center"
                  activeClassName="active">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18.9456 19.1999V19.9499H19.6956H20.5956C20.6471 19.9499 20.6787 19.9584 20.6948 19.9646C20.7102 19.9706 20.7159 19.9759 20.7177 19.9778C20.7195 19.9796 20.7249 19.9853 20.7309 20.0007C20.7371 20.0168 20.7456 20.0484 20.7456 20.0999C20.7456 20.1515 20.7371 20.183 20.7309 20.1991C20.7249 20.2145 20.7195 20.2203 20.7177 20.2221C20.7159 20.2239 20.7102 20.2293 20.6948 20.2352C20.6787 20.2414 20.6471 20.2499 20.5956 20.2499H4.39559C4.34405 20.2499 4.31249 20.2414 4.29639 20.2352C4.28096 20.2293 4.27523 20.2239 4.27342 20.2221C4.27161 20.2203 4.26622 20.2145 4.26029 20.1991C4.25409 20.183 4.24559 20.1515 4.24559 20.0999C4.24559 20.0484 4.25409 20.0168 4.26029 20.0007C4.26622 19.9853 4.27161 19.9796 4.27342 19.9778C4.27523 19.9759 4.28096 19.9706 4.29639 19.9646C4.31249 19.9584 4.34405 19.9499 4.39559 19.9499H5.29559H6.04559V19.1999V6.59998V5.84998H5.29559H4.4488L5.73589 3.75H19.1989L20.4589 5.84998H19.6956H18.9456V6.59998V19.1999ZM11.5956 6.74998H10.8456V7.49998V10.2V10.95H11.5956H13.3956H14.1456V10.2V7.49998V6.74998H13.3956H11.5956ZM9.79558 10.95H10.5456V10.2V7.49998V6.74998H9.79558H7.99559H7.24559V7.49998V10.2V10.95H7.99559H9.79558ZM14.2956 19.9499H15.0456V19.1999V14.6999C15.0456 14.2595 14.8955 13.8342 14.5784 13.5171C14.2613 13.2 13.836 13.0499 13.3956 13.0499H11.5956C11.1551 13.0499 10.7298 13.2 10.4128 13.5171C10.0957 13.8342 9.94558 14.2595 9.94558 14.6999V19.1999V19.9499H10.6956H14.2956ZM16.9956 10.95H17.7456V10.2V7.49998V6.74998H16.9956H15.1956H14.4456V7.49998V10.2V10.95H15.1956H16.9956Z"
                      fill="white"
                      stroke="white"
                      className="menu-fill"
                    />
                  </svg>
                  Établissements
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/admin-structure/account"
                  className="d-flex align-items-center"
                  activeClassName="active">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <g>
                        <path
                          d="M12.375 3C10.0214 3 7.76384 3.93495 6.09971 5.5997C4.43493 7.2637 3.5 9.52135 3.5 11.875C3.5 14.2286 4.43495 16.4861 6.09971 18.1502C7.76372 19.815 10.0214 20.7499 12.375 20.7499C14.7286 20.7499 16.9862 19.815 18.6503 18.1502C20.3151 16.4862 21.25 14.2286 21.25 11.875C21.2477 9.52214 20.312 7.266 18.6479 5.60208C16.9839 3.93808 14.7278 3.00238 12.375 3ZM15.9909 16.6139C15.8284 16.6371 15.6636 16.5945 15.5335 16.4947C15.4027 16.3956 15.3176 16.2478 15.2975 16.0853C15.2077 15.4816 14.9167 14.9259 14.4717 14.5087C13.9012 13.9801 13.1528 13.686 12.375 13.6829C11.6792 13.6612 10.9997 13.8934 10.4626 14.3354C9.92543 14.7773 9.56632 15.3988 9.45253 16.0853C9.41073 16.3949 9.14526 16.6247 8.83336 16.6216H8.75055C8.42083 16.5628 8.19716 16.2517 8.24747 15.9204C8.35118 15.2308 8.63521 14.5815 9.0694 14.0366C9.50437 13.4917 10.0748 13.0707 10.7241 12.8161C9.9703 12.3293 9.4649 11.5398 9.33875 10.6513C9.21259 9.76353 9.47806 8.86421 10.0655 8.1863C10.6537 7.50908 11.5066 7.11979 12.4037 7.11979C13.3007 7.11979 14.1536 7.50908 14.7419 8.1863C15.3301 8.86429 15.5955 9.76361 15.4694 10.6513C15.3424 11.5398 14.8378 12.3292 14.084 12.8161C14.5476 13.015 14.9725 13.2944 15.3386 13.6419C15.9724 14.2487 16.3826 15.0513 16.5026 15.9204C16.5475 16.2517 16.3206 16.559 15.9909 16.6139Z"
                          className="menu-fill"
                        />
                        <path
                          d="M14.2326 10.2237C14.2326 11.2492 13.4006 12.0812 12.3751 12.0812C11.3496 12.0812 10.5176 11.2492 10.5176 10.2237C10.5176 9.19744 11.3496 8.36621 12.3751 8.36621C13.4006 8.36621 14.2326 9.19744 14.2326 10.2237Z"
                          className="menu-fill"
                        />
                      </g>
                    </g>
                  </svg>
                  Mon Compte
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="sidebar__bottom d-flex flex-column align-items-center">
            <img
              alt="logo Fava"
              src={`${path_img}/full_logo.svg`}
              className="full-logo"
              draggable={false}
            />
            <div onClick={logoutFunction} className="cursor-pointer">
              Déconnexion
            </div>
          </div>
        </div>
        <div className="section-content flex-grow-1">
          <AdminRoutes />
        </div>
      </div>
    </Router>
  );
};

export default IndexAdminStructure;
