import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

interface IBtnBack {
  to: string;
  label?: string;
}

const BtnBack: FC<IBtnBack> = ({ to, label }) => {
  const history = useHistory();

  const backProgram = (e: any) => {
    e.preventDefault();
    history.push(to);
  };

  return (
    <div className="root-center-flex-start" onClick={backProgram}>
      <div>
        <IconButton>
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      </div>
      <div>{label || 'Retour'}</div>
    </div>
  );
};

export default BtnBack;
