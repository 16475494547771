import { Route, Switch } from 'react-router-dom';
import AdminClasses from './classes/index';
import Licence from './licences/index';
import MyAccount from './my-account/index';
import StudentDetails from './student/Details/index';
import Student from './student/index';
import Teacher from './teacher/index';

const AdminRoutes = () => (
  <Switch>
    <Route exact={true} path="/admin/account" component={MyAccount} />
    <Route exact={true} path="/admin/classes" component={AdminClasses} />
    <Route exact={true} path="/admin/licences" component={Licence} />
    <Route exact={true} path="/admin/programmes" component={Licence} />
    <Route exact={true} path="/admin/teachers" component={Teacher} />
    <Route exact={true} path="/admin/student" component={Student} />
    <Route exact={true} path="/admin/student/info" component={StudentDetails} />
  </Switch>
);

export default AdminRoutes;
