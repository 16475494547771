/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const structureSlice: any = createSlice({
  name: 'structure',
  initialState: {
    structure: null,
  },
  reducers: {
    setStructure: (state, action: PayloadAction<any>) => {
      state.structure = action.payload;
    },
  },
});

export const structureAction = structureSlice.actions;

export default structureSlice.reducer;
