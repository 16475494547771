/* eslint-disable no-shadow */
export const path_img = '/IMG/';

export const lsPrefix = process.env.REACT_APP_LOCALSTORAGE_PREFIX;

export const apiUrl = process.env.REACT_APP_ENDPOINT;

export const routeAdminPrefix = 'admin';

export const routeStudentPrefix = 'student';

export const enum Status {
  to_start = 'to_start',
  in_progress = 'in_progress',
  done = 'done',
  failed = 'failed',
}
