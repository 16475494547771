/* eslint-disable simple-import-sort/imports */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import useAccount from '@fava/Hooks/useAccount';
import BtnSimple from '@fava/Button/Simple';
import Form from 'react-bootstrap/Form';
import CardHelp from '@fava/Card/Help';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import './style.scss';

const Info = () => {
  const { data, setData } = useAccount();

  const handleChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const contactFava = (e: any) => {
    console.log('ttt');
  };

  return (
    <div className="info-details-tabs">
      <div className="info-details-tabs">
        <div className="root-space-between-center">
          <div className="content-form-info">
            <Form.Group className={`mb-3 ${data?.error?.firstname && 'form-error-input'}`}>
              <Form.Label className="color-fava-admin">Nom</Form.Label>
              <Form.Control
                className=""
                name="firstname"
                onChange={handleChange}
                value={data?.firstname}
                readOnly={localStorage.getItem('session')?.length > 0}
              />
            </Form.Group>

            <Form.Group className={`mb-3 ${data?.error?.lastname && 'form-error-input'}`}>
              <Form.Label className="color-fava-admin">Prénom</Form.Label>
              <Form.Control
                name="lastname"
                onChange={handleChange}
                value={data?.lastname}
                readOnly={localStorage.getItem('session')?.length > 0}
              />
            </Form.Group>

            {!localStorage.getItem('session') && (
              <>
                <Form.Group className={`mb-3 ${data?.error?.email && 'form-error-input'}`}>
                  <Form.Label className="color-fava-admin">Mail</Form.Label>
                  <Form.Control
                    disabled={true}
                    name="email"
                    onChange={handleChange}
                    value={data?.email}
                  />
                </Form.Group>
                <Form.Group className={`mb-3 ${data?.error?.phone && 'form-error-input'}`}>
                  <Form.Label className="color-fava-admin">Numéro de téléphone </Form.Label>
                  <Form.Control name="phone" onChange={handleChange} value={data?.phone} />
                </Form.Group>
                <Form.Group className={`mb-3 ${data?.error?.function && 'form-error-input'}`}>
                  <Form.Label className="color-fava-admin">Poste</Form.Label>
                  <Form.Control name="function" onChange={handleChange} value={data?.function} />
                </Form.Group>
              </>
            )}
          </div>

          {!localStorage.getItem('session') && (
            <div className="w-10">
              <CardHelp
                title="Besoin d’aide ?"
                description="L’équipe intim’ed est à votre disponibilité n the other hand, we denounce with righteous indignation"
                backgroundColor="#004F38"
                btn={
                  <BtnSimple color="white" borderRadius={false} onClick={contactFava}>
                    <div className="text-btn">Contactez intim’ed</div>
                  </BtnSimple>
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Info;
