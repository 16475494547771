/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const currentUserSlice: any = createSlice({
  name: 'currentUser',
  initialState: {
    currentUser: null,
    loading: false,
  },
  reducers: {
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.currentUser = action.payload;
    },
  },
});

export const currentUserAction = currentUserSlice.actions;

export default currentUserSlice.reducer;
