export const DeepArrayObject = (rowOne: any, field: string) => {
  if (!Array.isArray(field)) {
    const splitField = field.split('.');

    let deepData: any = { ...rowOne };

    for (let i = 0; i < splitField.length; i++) {
      deepData = deepData?.[splitField?.[i]];
    }

    return deepData || '';
  }
};
