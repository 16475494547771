import './style.scss';
import { ReactComponent as Logo } from '@fava/Asset/Icon/Layout/logo.svg';
import useResetSession from '@fava/Hooks/useResetSession';
import PublicLayout from '@fava/Layout/Public';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { faEye, faEyeSlash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { forgotPassword } from '../../../Services/user';

const ForgotPassword: FC = () => {
  useResetSession();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: false });

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [sucessFirstParagraph, setSucessFirstParagraph] = useState('');
  const [sucessSecondParagraph, setSucessSecondParagraph] = useState('');

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const response = await forgotPassword({
        emailOrUsername: data.email.trim(),
      });
      if (response.data.userType === 'student') {
        setSucessFirstParagraph('Votre professeur vient de recevoir');
        setSucessSecondParagraph('un lien de réinitialisation.');
      } else {
        setSucessFirstParagraph('Un lien de réinitialisation vous a été');
        setSucessSecondParagraph(' envoyé par mail.');
      }

      setIsSuccess(true);
    } catch (err) {
      if (err?.response?.data?.error?.name === 'BadRequestError') {
        setError("Cet utilisateur n'existe pas");
      } else {
        setError('Une erreur est survenue');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PublicLayout>
      {isSuccess ? (
        <div className="text-center">
          <Logo />
          <p className="text-white-connection" style={{ marginTop: 30 }}>
            {sucessFirstParagraph}
          </p>
          <p className="text-white-connection">{sucessSecondParagraph}</p>
        </div>
      ) : (
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            position: 'fixed',
            top: '25vh',
            minWidth: '33vw',
          }}>
          <div className="text-center">
            <Logo />
            <p className="text-white-connection" style={{ marginTop: 30 }}>
              Mot de passe oublié ?
            </p>
          </div>
          <Form.Group controlId="formEmail" className="form-group-mg-top">
            <div className="label-container">
              <Form.Label className="text-white">Adresse mail ou pseudo</Form.Label>
              {(errors.email || !!error?.trim()?.length) && (
                <FontAwesomeIcon
                  color="#FF7E7E"
                  icon={faTriangleExclamation}
                  className="alert-icon"
                />
              )}
            </div>
            <Form.Control
              type="text"
              className="rounded-input"
              {...register('email', { required: 'Le champ est obligatoire' })}
            />
          </Form.Group>
          {!!error?.trim()?.length && <p className="text-danger">{error}</p>}
          {errors.email && <p className="text-danger">{errors.email.message as string}</p>}
          <Button
            className="w-100 form-group-mg-top submit-btn"
            variant="primary"
            type="submit"
            disabled={!!Object.keys(errors).length}>
            {isLoading ? 'Réinitialisation en cours...' : 'Réinitialiser mon mot de passe'}
          </Button>
          <div className="text-center">
            <a href="/" className="redirect-link">
              Se connecter
            </a>
          </div>
        </Form>
      )}
    </PublicLayout>
  );
};

export default ForgotPassword;
