/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IChapter {
  fetchlistChapter: any[];
  fetchListLoading: boolean;
  fetchListError: any;
  isLoading: boolean;
  chapterTitle: string;
  nbrChapter: number;
  description: string;
  meta: any;
  response: any;
  error: any;
  hasQuizz: boolean;
  id: number;
  nextChapitre: number | null;
  precedentChapitre: number | null;
}

const chapterSlice: any = createSlice({
  name: 'chapter',
  initialState: {
    id: 0,
    listChapter: [],
    chapterTitle: '',
    description: '',
    nbrChapter: 0,
    hasQuizz: false,
    nextChapitre: null,
    precedentChapitre: null,
    oneChapter: {},
    loading: false,
  },
  reducers: {
    setChapter: (state, action: PayloadAction<IChapter>) => {
      state.id = action.payload.id;
      state.hasQuizz = action.payload.hasQuizz;
      state.description = action.payload.description;
      state.nbrChapter = action.payload.nbrChapter;
      state.chapterTitle = action.payload.chapterTitle;
      state.nextChapitre = action.payload.nextChapitre;
      state.precedentChapitre = action.payload.precedentChapitre;
    },
    setList: (state, action: PayloadAction<any[]>) => {
      state.listChapter = action.payload;
    },
    setOneChapter: (state, action: PayloadAction<any>) => {
      state.oneChapter = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const chapterAction = chapterSlice.actions;

export default chapterSlice.reducer;
