import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

const qs = require('qs');

const fetchCurrentUserChapterContents = createAsyncThunk(
  'currentUserChapterContents/fetchCurrentUserChapterContents',
  async ({ token, programId, moduleId, chapterId, contentId }: any) => {
    const params = qs.stringify(
      {
        filters: {
          id: +chapterId,
          modules: {
            id: {
              $eq: +moduleId,
            },
          },
        },
        additionnalFilters: {
          chapterId,
          moduleId,
          programId,
          contentId,
        },
        populate: ['modules', 'img', 'contents', 'contents.image', 'contents.video'],
      },
      {
        encodeValuesOnly: true,
      }
    );
    const response: any = await makeRequest(
      `${apiUrl}/api/current-user-chapter-contents/?${params}`,
      'GET',
      {}
    );
    return response.data;
  }
);

export { fetchCurrentUserChapterContents };
