/* eslint-disable simple-import-sort/imports */
import { FC } from 'react';
import start from '@fava/Asset/Icon/star-dynamic-color.svg';
import '../style.scss';

interface IGoodResponse {
  score: number;
  textLigth?: boolean;
}

const GoodResponse: FC<IGoodResponse> = ({ score, textLigth }) => {
  return (
    <div className="position-relative">
      <img src={start} alt="start good-response" className="img-icon-good-reponse" />

      <div
        className={`${textLigth ? 'good-response-color-light' : 'good-response'}  margin-in-score`}>
        <h1>{score}</h1>

        <p className={`p-normal ${textLigth && 'text-light'}`}>point gagné</p>
      </div>
    </div>
  );
};

export default GoodResponse;
