import { modulesAction } from '@fava/Store/Slices/modulesSlice';
import { RootState } from '@fava/Store/store';
import { useDispatch, useSelector } from 'react-redux';

const useModules = () => {
  const module: any = useSelector((state: RootState) => state.modules);

  const dispatch = useDispatch();

  const setModules = (data: any) => {
    dispatch(modulesAction.setModules(data));
  };

  return {
    ...module,
    setModules,
  };
};

export default useModules;
