const calculCount = (data: any[]) => {
  return data.length || 0;
};

export const head = [
  {
    type: 'text',
    label: 'Nom de la classe',
    key: 'attributes.name',
  },
  {
    type: 'text',
    label: 'niveau',
    key: 'attributes.level.data.attributes.name',
  },
  {
    type: 'link',
    label: 'URL d’inscription',
    key: 'attributes.link',
  },
  {
    type: 'function',
    key: 'attributes.student_users.data',
    label: 'NOMBRE d’UTILISATEURs',
    option: {
      function: calculCount,
    },
  },
];
