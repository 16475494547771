import './style.scss';
import { FC, ReactNode } from 'react';

interface ICardHelp {
  title: string;
  description: string;
  backgroundColor: string;
  btn: ReactNode;
}

const CardHelp: FC<ICardHelp> = ({ title, description, backgroundColor, btn }) => {
  return (
    <div className="card-help" style={{ backgroundColor }}>
      <h1>{title}</h1>

      <p>{description}</p>

      <div>{btn}</div>
    </div>
  );
};

export default CardHelp;
