import './style.scss';
import useQuestionAnswer from '@fava/Hooks/useQuestionAnswer';
import { FC } from 'react';
import BtnResponse from '../BtnResponse';

interface IContentQuestion {
  id: number;
  img?: string;
  title: string;
  listResponse: any[];
  titleLight?: boolean;
  resultDescription?: string;
}

const ContentQuestion: FC<IContentQuestion> = ({
  id,
  img,
  title,
  titleLight,
  listResponse,
  resultDescription,
}) => {
  const { modalValidation, setAnswer, answer } = useQuestionAnswer();
  const filterDataByGoodResponse = data => {
    const filteredData = data.filter(item => item.goodResponse === true);
    const result = filteredData.map(item => {
      return {
        index: data.indexOf(item),
        id: item?.id,
        name: item?.name,
        image: item?.image,
      };
    });
    return result;
  };

  const handleClick = (data: any) => {
    const checkingResponseExist = answer;
    if (answer.length === 0) {
      setAnswer([
        ...answer,
        {
          id,
          responseId: data?.id,
          questionId: id,
          responseText: data.name,
          goodResponse: data?.goodResponse,
          resultDescription,
          goodResponseList: filterDataByGoodResponse(listResponse),
          isMultiple: filterDataByGoodResponse(listResponse).length > 1,
        },
      ]);
    } else {
      const responseChecking = checkingResponseExist.filter(res => {
        return res?.responseId === data?.id;
      });

      if (responseChecking.length > 0) {
        const updatedResponseSelect = answer.filter(currentResponse => {
          return currentResponse.responseId !== data.id;
        });
        setAnswer(updatedResponseSelect);
      } else {
        setAnswer([
          ...answer,
          {
            id,
            responseId: data?.id,
            questionId: id,
            responseText: data.name,
            goodResponse: data?.goodResponse,
            resultDescription,
            goodResponseList: filterDataByGoodResponse(listResponse),
            isMultiple: filterDataByGoodResponse(listResponse).length > 1,
          },
        ]);
      }
    }
  };

  const list = ['A', 'B', 'C', 'D', 'E', 'F'];

  const checkingStatusResponse = (xid: number): string => {
    if (
      filterDataByGoodResponse(listResponse).length === 1 &&
      answer.filter(res => {
        return res.responseId === xid;
      })[0]?.goodResponse
    ) {
      return 'good';
    }

    if (
      filterDataByGoodResponse(listResponse).length > 1 &&
      answer.filter(res => {
        return res.responseId === xid;
      })[0]?.goodResponse
    ) {
      return 'good';
    }
    return 'bad';
  };

  const ifTrouQuestion = (current_title: string) => {
    if (current_title.includes('___')) {
      const splitTitle = current_title.split('___');

      return (
        <div className={`root-center-flex ${titleLight}`} key={Math.random()}>
          <div> {splitTitle[0]}</div>
          <div className={`trout-question-content ${titleLight}`} key={Math.random()} />
          <div>{splitTitle[1]}</div>
        </div>
      );
    }

    return <div className={`root-center-flex ${titleLight}`}>{title}</div>;
  };

  return (
    <div className="root-content-question-response">
      {listResponse.length && <h2>{ifTrouQuestion(title)} </h2>}

      <div className={`root-center-flex ${img ? 'root-content-question-response--with-image' : ''}`}>
        {img && (
          <div className="content-ques__image">
            <img src={img} className="img-apercu-content-question" alt="img-apercu" />
          </div>
        )}

        <div className="content-btn__wrap">
          {listResponse.map((x: any, index: number) => (
            <BtnResponse
              id={x.id}
              key={Math.random()}
              nbr={list[index]}
              response={x}
              text={x.name}
              image={x?.image}
              onClickBtn={handleClick}
              disable={modalValidation.open}
              typeResponse={
                modalValidation.open &&
                  answer.filter(res => {
                    return res.responseId === x.id;
                  }).length > 0 &&
                  answer.filter(res => {
                    return res.responseId === x.id;
                  })[0]?.goodResponse
                  ? 'good'
                  : modalValidation.open &&
                    answer.filter(res => {
                      return res.responseId === x.id;
                    }).length > 0 &&
                    !answer.filter(res => {
                      return res.responseId === x.id;
                    })[0]?.goodResponse
                    ? 'bad'
                    : ''
              }
              clicked={
                answer.filter(res => {
                  return res.responseId === x.id;
                }).length > 0
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentQuestion;
