import './style.scss';
import BtnSimple from '@fava/Button/Simple';
import { Table, TitlePage } from '@fava/Components';
import Drawer from '@fava/Components/Drawer';
import useAccount from '@fava/Hooks/useAccount';
import useClasse from '@fava/Hooks/useClasse';
import { useEffect, useState } from 'react';
import { Program } from 'src/Models';
import { getStatClass } from '../../../../Services/user';
import Add from './Add';
import { head, headEduilib, headNoLink } from './constant';
import Edit from './Edit';

const AdminClasses = () => {
  const [comfirm, setComfirm] = useState(false);
  const [loadingStat, setLoading] = useState(false);
  const [programData, setProgramData] = useState<Program[]>([]);
  const makeComfirm = () => {
    setComfirm(!comfirm);
  };

  const account = useAccount();

  const {
    deleteClasseRequest,
    fetchListClassePopulated,
    fetchLevelRequest,
    loading,
    loadEdit,
    listClasse,
    setCurrentClass,
    currentClass,
    setOpenDrawer,
    openDrawer,
  } = useClasse();

  useEffect(() => {
    if (!openDrawer) {
      setComfirm(false);
      setProgramData([]);
    }
  }, [openDrawer]);

  useEffect(() => {
    fetchListClassePopulated();
    fetchLevelRequest();
  }, []);

  const handleCreate = () => {
    setCurrentClass({
      level: '',
      name: '',
    });
    setOpenDrawer();
  };

  const onSelect = (data: any) => {
    setLoading(true);
    getStatClass(data.id)
      .then(res => {
        setLoading(false);
        setProgramData(res);
      })
      .catch(() => {
        setLoading(false);
      });

    setCurrentClass({
      ...data.attributes,
      level: data.attributes?.level?.data?.id,
      id: data.id,
    });
    setOpenDrawer();
  };

  return (
    <>
      <div className="root-space-between">
        <div>
          <TitlePage nbr={listClasse.length}>Classe </TitlePage>
        </div>

        {account.type !== 'professor_user' && !localStorage.getItem('session') && (
          <div>
            <BtnSimple backgroundColor="#615EFF" onClick={handleCreate}>
              Créer une classe
            </BtnSimple>
          </div>
        )}
      </div>

      <div className="container-student">
        <Table
          head={
            localStorage.getItem('session')
              ? (headNoLink as any)
              : localStorage.getItem('ean')
                ? headEduilib
                : (head as any)
          }
          body={listClasse}
          onSelect={onSelect}
          loading={loading}
        />
      </div>

      <Drawer
        open={openDrawer}
        handclose={setOpenDrawer}
        btnBack={!comfirm}
        middleWidth={!!currentClass?.id && !comfirm}>
        {comfirm ? (
          <div className="comfirm-modal">
            <div>
              <h1 className="h1-normal color-fava-admin">
                Êtes vous sûr de vouloir supprimer cette classe ?
              </h1>

              <p className="text-dark">Cette action est irrévocable.</p>

              <div className="root-center-flex m-2">
                <div>
                  <BtnSimple
                    disabled={loadEdit}
                    backgroundColor="#615EFF"
                    borderRadius={false}
                    onClick={deleteClasseRequest}
                    loading={loadEdit}>
                    Confirmer
                  </BtnSimple>
                </div>
              </div>

              <div className="root-center-flex m-2">
                <div>
                  <BtnSimple
                    disabled={loadEdit}
                    color="#615EFF"
                    borderRadius={false}
                    onClick={makeComfirm}
                    loading={false}>
                    Annuler
                  </BtnSimple>
                </div>
              </div>
            </div>
          </div>
        ) : currentClass?.id ? (
          <Edit makeComfirm={makeComfirm} programData={programData} loadingStat={loadingStat} />
        ) : (
          <Add />
        )}
      </Drawer>
    </>
  );
};

export default AdminClasses;
