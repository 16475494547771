import BtnSimple from '@fava/Button/Simple';
import CardHelp from '@fava/Card/Help';
import Loader from '@fava/Components/Loader/Loader';
import useAccount from '@fava/Hooks/useAccount';
import useEtablishement from '@fava/Hooks/useEtablishement';
import useStructure from '@fava/Hooks/useStrucuture';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { useEffect, useState } from 'react';

const Following = () => {
  const { data, role } = useAccount();

  const { findStructureRequest, structure, setStructure } = useStructure();

  const { findFlollowingEtablishementRequest } = useEtablishement();

  const [loading, setLoading] = useState(false);

  const findStructure = async (id: number) => {
    setLoading(true);

    try {
      let response;
      let licence;

      if (role === 'structure_admin') {
        response = await findStructureRequest(id);
        licence = response.data[0].attributes?.establishments?.data[0]?.attributes.licence;
      }

      if (role === 'establishment_admin') {
        response = await findFlollowingEtablishementRequest(id);
        licence = response.data[0]?.attributes.licence;
      }

      const newStructure = {
        ...response.data[0]?.attributes,
        id: response.data[0].id,
        licence: licence as any,
      };

      setStructure(newStructure);
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.id) {
      findStructure(data?.id);
    }
  }, [data?.id]);

  if (loading) {
    return <Loader bg="loader-custom--transparent" />;
  }

  const contactFava = () => {
    console.log('');
  };

  return (
    <div>
      <div className="margin following-licence">
        <div className="root-space-between">
          <div>
            <div className="margin-big">
              <h1 className="h1-normal color-fava-admin w-30 size-big-text">
                A propos de votre abonnement
              </h1>
            </div>

            <div className="margin">
              <h2 className="color-fava-admin size-title-following font-bold">Période </h2>
              <p className="p-normal text-gray">
                Du{' '}
                {moment(structure?.licence?.data?.attributes?.DateStart).format('DD/MM/YYYY') || ''}{' '}
                au{' '}
                {moment(structure?.licence?.data?.attributes?.DateEnd).format('DD/MM/YYYY') || ''}
              </p>
            </div>

            <div className="margin">
              <h2 className="color-fava-admin size-title-following font-bold">
                Nombre de programme{' '}
              </h2>
              <p className="p-normal text-gray">
                {(structure?.licence?.data?.attributes?.programs?.data || []).length || 0}
              </p>
            </div>

            <div className="margin">
              <h2 className="color-fava-admin size-title-following font-bold">
                Nombre d’utilisateurs maximum{' '}
              </h2>
              <p className="p-normal text-gray">
                {structure?.licence?.data?.attributes?.MaxStudent || 0}
              </p>
            </div>
          </div>

          <div>
            <CardHelp
              title="Renouveler votre abonnement pour la rentrée ! "
              description="L’équipe intim’ed est à votre disponibilité n the other hand, we denounce with righteous indignation"
              backgroundColor="#004F38"
              btn={
                <BtnSimple color="white" borderRadius={false} onClick={contactFava}>
                  <div className="text-btn">Contactez intim’ed</div>
                </BtnSimple>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Following;
