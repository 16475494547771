import ButtonFava from '@fava/Components/ButtonFava/ButtonFava';
import React from 'react';
import { useHistory } from 'react-router-dom';

type StatProps = {
  id: number;
  title: string;
  image: string;
  percentageSuccess: number;
  percentageProgress: number;
  chapterDuration: string;
  handclose?: (id?: number, title?: string) => void;
};
const StatProgram: React.FC<StatProps> = ({
  id,
  title,
  image,
  percentageSuccess,
  percentageProgress,
  chapterDuration,
  handclose,
}) => {
  const history = useHistory();

  const goToContent = () => {
    if (handclose) {
      handclose(id, title);
    } else {
      history.push(`/student/programs/${id}`);
    }
  };

  const roundNumber = (number: number): number => {
    const decimal = number - Math.floor(number);

    if (decimal > 0.5) {
      return Math.ceil(number);
    }
    return Math.floor(number);
  };

  return (
    <div className="list__item flex-grow-1 list__item--bordered list__item--bordered-green  list__item--cornered d-flex flex-column justify-content-between">
      <div className="d-flex">
        <div className=" d-flex flex-grow-1 flex-wrap">
          <div className="list__item-left mb-3 mb-md-0 list__item-media list__item-media--brad-img list__item-media--cover list__item-media--max-h-167">
            <img src={image} alt={title} />
          </div>
          <div className="list__item-right list__item-text list__item-text--medium">
            <div className="heading-3 heading-3--mb-medium text--fava-green">{title}</div>
            <div className="list__line-text">
              <span className="text--cornflower-blue">{roundNumber(percentageSuccess)}%</span> de
              réussite
            </div>
            <div className="list__line-text">
              <span className="text--cornflower-blue">{roundNumber(percentageProgress)}%</span>{' '}
              d&apos;avancement
            </div>
            <div className="list__line-text">
              <span className="text--cornflower-blue"> {chapterDuration || 0} </span> en moyenne
            </div>
          </div>
        </div>
      </div>
      <div className="list__btn">
        <ButtonFava
          text="Consulter"
          type="button-fava--primary "
          url={() => {
            goToContent();
          }}
        />
      </div>
    </div>
  );
};

export default StatProgram;
