import { IQuestionAnswer, QuestionAnswerAction } from '@fava/Store/Reduce/QuestionAnswer';
import { IChapter } from '@fava/Store/Slices/chapterSlice';
import { RootState } from '@fava/Store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useQuestionAnswer = () => {
  const data: IQuestionAnswer = useSelector((state: RootState) => state.questionAnswer);

  const chapter: IChapter | any = useSelector((state: RootState) => state.chapter);

  const modules: any = useSelector((state: RootState) => state.modules);

  const programs: any = useSelector((state: RootState) => state.programs);

  const params: any = useParams();

  const dispatch = useDispatch();

  const setListQuestionAnswer = (list: any[]) => {
    dispatch(QuestionAnswerAction.setListQuestionAnswer(list));
  };

  const setAnswer = (list: any[]) => {
    dispatch(QuestionAnswerAction.setAnswer(list));
  };

  const setTotalLengthQuestion = (nbr: number) => {
    dispatch(QuestionAnswerAction.setTotalLengthQuestion(nbr));
  };

  const setStep = (nbr: number) => {
    dispatch(QuestionAnswerAction.setStep(nbr));
  };

  const setModalValidation = (nbr: any) => {
    dispatch(QuestionAnswerAction.setModalValidation(nbr));
  };

  const setStepQuizz = (nbr: number) => {
    dispatch(QuestionAnswerAction.setStepQuizz(nbr));
  };

  const setResponseUser = (idQuestion: number, response: any) => {
    const listQuestionAnswer: any[] = [...data.answer];

    const question = listQuestionAnswer.find((x: any) => x.id_question === idQuestion);

    if (question) {
      const index = listQuestionAnswer.findIndex((y: any) => y.id_question === idQuestion);

      const currentQuestionResponse = listQuestionAnswer[index] || {};

      let listAnswer: any[] = currentQuestionResponse?.listAnswer || [];

      const isIncludes = listAnswer.find((x: any) => x.id === response.id);

      if (!isIncludes) {
        listAnswer = [...listAnswer, response];
      } else {
        listAnswer = listAnswer.filter(x => x.id !== response.id);
      }

      listQuestionAnswer[index] = {
        id_question: idQuestion,
        id_chapter: chapter.id,
        id_module: modules.id,
        id_program: programs.id,
        listAnswer: listAnswer as any[],
        type: question?.type || 'simple',
      };
    } else {
      const dataPushed = {
        id_question: idQuestion,
        id_chapter: chapter.id,
        id_module: modules.id,
        id_program: programs.id,
        listAnswer: [response],
        type: question?.type || 'simple',
      };

      listQuestionAnswer.push(dataPushed);
    }

    setAnswer(listQuestionAnswer);
  };

  const validateAnswer = () => {
    const listQuestionAnswer: any[] = [...data.answer];
    const getCurrentAnswer = data.answer.find((x: any) => x.id_question === +params.idQuestion);

    const question = data.listQuestionAnswer.find((x: any) => x.id === +params.idQuestion);

    const index = data.answer.findIndex((y: any) => y.id_question === +params.idQuestion);

    if (!getCurrentAnswer) {
      return;
    }

    const currentAnswer = getCurrentAnswer.listAnswer || [];

    const validation: any = {
      open: true,
    };

    if (
      (question.reponsePossible || []).filter((x: any) => x.goodResponse).length <=
      currentAnswer.filter((x: any) => x.goodResponse).length
    ) {
      validation.type = 'success';
    } else {
      validation.type = 'failed';
    }

    validation.description = question.description || '';

    listQuestionAnswer[index] = {
      ...listQuestionAnswer[index],
      type: question.type || 'simple',
      success: validation.type === 'success',
    };

    setAnswer(listQuestionAnswer);

    setModalValidation(validation);
  };

  return {
    ...data,

    setStep,
    setAnswer,
    setStepQuizz,
    validateAnswer,
    setResponseUser,
    setModalValidation,
    setListQuestionAnswer,
    setTotalLengthQuestion,
  };
};

export default useQuestionAnswer;
