import Loader from '@fava/Components/Loader/Loader';
import useAccount from '@fava/Hooks/useAccount';
import {
  getUserAdminInfo,
  getUserAdminStructure,
  getUserStudent,
  getUserTeacher,
} from '@fava/Store/Thunks/fetchAccount';
import { routeAdminPrefix, routeStudentPrefix } from '@fava/Utils';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import configRole from '@fava/Utils/role';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { login } from '../../Services/user';

const LoaderSso: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { push } = useHistory();
  useEffect(() => {
    LocalStorageUtils.clear();
    const fetchData = async () => {
      setIsLoading(true);
      const searchParams = new URLSearchParams(location.search);
      const session = searchParams.get('session');
      const id = searchParams.get('id');
      const mailaka = searchParams.get('znak');

      if (!session || !id || !mailaka) {
        push(`/error`);
        console.log('error', error);
        setError('Informations manquantes dans l’URL.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await login({
          email: mailaka,
          identifier: mailaka,
          password: 'intimedgar77X/*',
        } as any);
        LocalStorageUtils.setItem('user_infos', JSON.stringify(response?.data?.user));
        localStorage.setItem('session', session);
        LocalStorageUtils.setItem('user_token', response?.data?.jwt);
        const role = configRole[response?.data?.user.role.name];

        account.setData(response?.data?.user);

        account.setType(role || '');

        switch (response?.data?.user?.role?.id) {
          // student
          case 1:
            push(`/${routeStudentPrefix}/programs`);
            dispatch(getUserStudent(response?.data?.user?.id) as any);
            window.location.reload();
            break;
          // EstablishmentAdmin
          case 4:
            dispatch(getUserAdminInfo(response?.data?.user?.id) as any);
            push(`/${routeAdminPrefix}/account`);
            break;
          // Professor
          case 6:
            dispatch(getUserTeacher(response?.data?.user?.id) as any);
            push(`/teacher/account`);
            break;
          // StructureAdmin
          case 3:
            dispatch(getUserAdminStructure(response?.data?.user?.id) as any);
            push(`/admin-structure/account`);
            break;
          default:
            console.log(`Sorry, we are out of ${response?.data?.user?.role?.id}.`);
        }
      } catch (err) {
        push(`/error`);
        setError('Une erreur est survenue lors de la connexion.');
        // Vous pourriez vouloir afficher des erreurs plus spécifiques en fonction de `err`
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const account = useAccount();
  return <Loader />;
};

export default LoaderSso;
