import { teacherAction } from '@fava/Store/Slices/teacherSlice';
import { RootState } from '@fava/Store/store';
import {
  createTeacher,
  deleteTeacher,
  EditTeacher,
  fetchTeacher,
} from '@fava/Store/Thunks/fetchTeacher';
import { useDispatch, useSelector } from 'react-redux';

const useTeacher = () => {
  const data: any = useSelector((state: RootState) => state.teacher);

  const account: any = useSelector((state: RootState) => state.account);

  const dispatch = useDispatch();

  const setListTeacher = (x: any) => {
    dispatch(teacherAction.setListTeacher(x));
  };

  const setTeacherSelected = (x: any) => {
    dispatch(teacherAction.setTeacherSelected(x));
  };

  const setLoading = (x: boolean) => {
    dispatch(teacherAction.setLoading(x));
  };

  const createTeacherRequest = async () => {
    return createTeacher({
      ...data.teacherSelected,
      classes: (data.teacherSelected.classes || []).map((x: any) => x.id),
    });
  };

  const EditTeacherRequest = async () => {
    return EditTeacher({
      ...data.teacherSelected,
      id: data.teacherSelected.id,
      classes: (data.teacherSelected.classes || []).map((x: any) => x.id),
    });
  };

  const deleteTeacherRequest = async () => {
    return deleteTeacher(data.teacherSelected.id);
  };

  const fetchTeacherRequest = async (id: number) => {
    setLoading(true);
    try {
      const response = await fetchTeacher(id || account.data?.id, account.type);
      setListTeacher(response?.data || []);
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    ...data,
    setLoading,
    setListTeacher,
    EditTeacherRequest,
    setTeacherSelected,
    createTeacherRequest,
    fetchTeacherRequest,
    deleteTeacherRequest,
  };
};

export default useTeacher;
