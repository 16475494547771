import { avatarAction } from '@fava/Store/Slices/avatarSlice';
import { RootState } from '@fava/Store/store';
import { fetchListAvatar, udpateAvatar } from '@fava/Store/Thunks/fetchAvatar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useAccount from './useAccount';

const useAvatar = () => {
  const data: any = useSelector((state: RootState) => state.avatar);

  const history = useHistory();

  const { setData } = useAccount();

  const dispatch = useDispatch();

  const setListAvatar = (x: any[]) => {
    dispatch(avatarAction.setListAvatar(x));
  };

  const setLoadingList = (x: boolean) => {
    dispatch(avatarAction.setLoadingList(x));
  };

  const setLoadingUpdate = (x: boolean) => {
    dispatch(avatarAction.setLoadingUpdate(x));
  };

  const fetchListAvatarRequest = async (x: boolean) => {
    setLoadingList(true);
    try {
      const response = await fetchListAvatar();
      setListAvatar(response.data);
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoadingList(false);
    }
  };

  const udpateAvatarRequest = (idConneted: number, idAvatar: number) => async (e: any) => {
    setLoadingUpdate(true);
    try {
      const response = await udpateAvatar(idConneted, idAvatar);
      setData(response);
      history.push('/student/statistical');
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoadingUpdate(false);
    }
  };

  return {
    ...data,
    setListAvatar,
    setLoadingList,
    setLoadingUpdate,
    udpateAvatarRequest,
    fetchListAvatarRequest,
  };
};

export default useAvatar;
