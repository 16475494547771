/* eslint-disable simple-import-sort/imports */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import useStructure from '@fava/Hooks/useStrucuture';
import { TitlePage } from '@fava/Components';
import { Grid, IconButton } from '@material-ui/core';
import BtnBack from '@fava/Button/Back';
import { FC, useEffect, useState } from 'react';
import Loader from '@fava/Components/Loader/Loader';
import { calculnbrStudentCount, calculnbrTeacher } from '../constant';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import useEtablishement from '@fava/Hooks/useEtablishement';
import { useHistory } from 'react-router-dom';

const OneEtablissement: FC = () => {
  const { findEtablisementyByIdRequest } = useEtablishement();

  const location = useHistory();

  const { getParams } = useStructure();
  const [etablisement, setEtablisement] = useState<any>({});

  const [loading, setLoading] = useState(false);

  const findOneEtablisement = async () => {
    setLoading(true);
    setEtablisement({});
    try {
      const response = await findEtablisementyByIdRequest(getParams('id_etablisement'));
      setEtablisement(response.data?.attributes || {});
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    findOneEtablisement();
  }, []);

  const backProgram = (to: any) => (e: any) => {
    location.push(to);
  };

  if (loading) {
    return <Loader bg="loader-custom--transparent" />;
  }

  return (
    <div>
      <BtnBack to="/admin-structure/etablisement" />

      <div className="root-space-between">
        <div>
          <TitlePage>{etablisement?.name || ''}</TitlePage>
        </div>
      </div>

      <div className="margin-big">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6} md={6}>
            <div className="card-border-simple p-5">
              <h2 className="h1-normal color-fava-admin">Classes</h2>
              <p>
                {calculnbrStudentCount(etablisement?.classes?.data || [])} élèves dans cet
                établissement.
              </p>
              <div
                className="root-center-flex-start"
                onClick={backProgram(`/admin-structure/etablisement/classe`)}>
                <div className="color-primary text-decoration-underline">Consulter</div>
                <div>
                  <IconButton>
                    <ArrowForwardIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item={true} xs={6} md={6}>
            <div className="card-border-simple p-5">
              <h2 className="h1-normal color-fava-admin">Professeurs</h2>
              <p>
                {calculnbrTeacher(etablisement?.classes?.data || [])} professeurs dans cet
                établissement.
              </p>
              <div
                className="root-center-flex-start"
                onClick={backProgram(`/admin-structure/etablisement/teachers`)}>
                <div className="color-primary text-decoration-underline">Consulter</div>
                <div>
                  <IconButton>
                    <ArrowForwardIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item={true} xs={6} md={6}>
            <div className="card-border-simple p-5">
              <h2 className="h1-normal color-fava-admin">Licence</h2>
              <p>Gérer la licence de cet établissement.</p>
              <div
                className="root-center-flex-start"
                onClick={backProgram(`/admin-structure/etablisement/licences`)}>
                <div className="color-primary text-decoration-underline">Consulter</div>
                <div>
                  <IconButton>
                    <ArrowForwardIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item={true} xs={6} md={6}>
            <div className="card-border-simple p-5">
              <h2 className="h1-normal color-fava-admin">Statistiques</h2>
              <p>Accéder aux temps moyens et aux résultats.</p>
              <div className="root-center-flex-start" onClick={backProgram}>
                <div className="color-primary text-decoration-underline">Consulter</div>
                <div>
                  <IconButton>
                    <ArrowForwardIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OneEtablissement;
