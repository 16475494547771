import './style.scss';
import useQuestionAnswer from '@fava/Hooks/useQuestionAnswer';
import { FC } from 'react';

interface IBtnQuestion {
  id: number;
  text?: string;
  response: any;
  image?: string;
  nbr: number | string;
  onClickBtn: (data: any) => void;
  typeResponse?: string;
  disable?: boolean;
  clicked: boolean;
}

const BtnQuestion: FC<IBtnQuestion> = ({
  id,
  response,
  nbr,
  text,
  typeResponse,
  disable,
  image,
  clicked,
  onClickBtn,
}) => {
  const { modalValidation } = useQuestionAnswer();

  const handleClick = () => {
    if (!modalValidation.open) {
      onClickBtn(response);
    }
  };

  const color = () => {
    if (typeResponse === 'bad') {
      return 'faild-btn';
    }

    if (clicked) {
      return 'btn-clicked-question';
    }

    return '';
  };

  return (
    <div
      className={`content-btn__question ${
        image
          ? 'content-btn__question--four-margin content-btn__question--bg-white cursor-pointer content-btn__question--bordered'
          : ''
      } ${image && color()}`}>
      {!image ? (
        <button
          disabled={disable}
          type="button"
          onClick={handleClick}
          className={`root-btn-question ${color()}`}>
          <span className="flex-shrink-0">{nbr}</span> {text && text}
        </button>
      ) : (
        <div onClick={handleClick} className="response-image response-image--no-height">
          {!text && <span className="response-image-span-relative flex-shrink-0">{nbr}</span>}
          <img
            src={image}
            width={100}
            height={50}
            alt={`question ${nbr}`}
            className={`content-btn__image" ${color()}`}
          />
          {text && (
            <div className="d-flex align-items-center mt-3">
              <span className="response-image-span-relative flex-shrink-0">{nbr}</span>
              <div className="answer-text">{text && text}</div>
            </div>
          )}
        </div>
      )}

      {typeResponse === 'good' && <div className="background-success-question" />}
    </div>
  );
};

export default BtnQuestion;
