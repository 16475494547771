import './style.scss';
import useClasse from '@fava/Hooks/useClasse';
import useStudent from '@fava/Hooks/useStudent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Info = () => {
  const { student, handleUpdateStudent, handleUpdateComfirmPassword } = useStudent();

  const { listClasse, listLevel } = useClasse();

  const [view, setView] = useState(false);

  const handleView = () => {
    setView(!view);
  };

  return (
    <div className="info-details-tabs">
      <h1 className="h1-normal">Informations</h1>

      <div className="width-form-details-student-admin">
        <Form.Group className="mb-3">
          <Form.Label className="color-fava-admin">Nom</Form.Label>
          <Form.Control
            name="firstname"
            value={student?.firstname}
            onChange={handleUpdateStudent}
            disabled={!!localStorage.getItem('session')}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="color-fava-admin">Prénom</Form.Label>
          <Form.Control
            name="lastname"
            value={student?.lastname}
            onChange={handleUpdateStudent}
            disabled={!!localStorage.getItem('session')}
          />
        </Form.Group>

        {!localStorage.getItem('ean') && (
          <Form.Group className="mb-3">
            <Form.Label className="color-fava-admin">Niveau</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="level"
              value={student?.level}
              disabled={!!localStorage.getItem('session')}
              onChange={handleUpdateStudent}>
              <option>Choisir un niveau</option>
              {listLevel.map((x: any) => (
                <option value={x.id}>{x.attributes.name || ''}</option>
              ))}
            </Form.Select>
          </Form.Group>
        )}

        <Form.Group className="mb-3">
          <Form.Label className="color-fava-admin">Classe</Form.Label>
          <Form.Select
            // disabled={true}
            aria-label="Default select example"
            name="class"
            value={student?.class}
            onChange={handleUpdateStudent}
            disabled={!!localStorage.getItem('session')}>
            <option>Choisir une Classe</option>
            {listClasse
              .filter((y: any) => {
                return +(student?.level || '') === y.attributes?.level?.data?.id;
              })
              .map((x: any) => (
                <option value={x.id}>{x?.attributes?.name || ''}</option>
              ))}
          </Form.Select>
        </Form.Group>

        {!localStorage.getItem('session') && (
          <>
            {' '}
            <Form.Group className={`mb-3 ${student?.errorPassword && 'form-error-input'}`}>
              <Form.Label className="color-fava-admin">Nouveau mot de passe</Form.Label>
              <InputGroup hasValidation={true}>
                <Form.Control
                  name="password1"
                  value={student?.password1}
                  type={view ? 'text' : 'password'}
                  aria-describedby="inputGroupPrepend"
                  onChange={handleUpdateStudent}
                  required={true}
                />
                <InputGroup.Text
                  id="inputGroupPrepend"
                  onClick={handleView}
                  className="cursor-pointer">
                  {view ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </InputGroup.Text>
              </InputGroup>

              {student?.errorPassword && (
                <p id="passwordHelpBlock" className="color-red fs-6">
                  {student?.errorPassword}
                </p>
              )}
            </Form.Group>
            <Form.Group className={`mb-3 ${student?.errorPassword && 'form-error-input'}`}>
              <Form.Label className="color-fava-admin">Confirmez le mot de passe</Form.Label>
              <InputGroup hasValidation={true}>
                <Form.Control
                  name="password2"
                  value={student?.password2}
                  type={view ? 'text' : 'password'}
                  aria-describedby="inputGroupPrepend"
                  onChange={handleUpdateComfirmPassword}
                  required={true}
                />
                <InputGroup.Text
                  id="inputGroupPrepend"
                  onClick={handleView}
                  className="cursor-pointer">
                  {view ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </InputGroup.Text>
              </InputGroup>
              {student?.errorPassword && (
                <p id="passwordHelpBlock" className="color-red fs-6">
                  {student?.errorPassword}
                </p>
              )}
            </Form.Group>
          </>
        )}
      </div>
    </div>
  );
};

export default Info;
