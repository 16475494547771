import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';

const qs = require('qs');

const fetchStudentRequest = async (query: any) => {
  const filter: any = {};

  if (query.teacher) {
    filter.class = {
      ...filter.class,
      professor_user: {
        id: +query.teacher,
      },
    };
  }

  if (query.class) {
    filter.class = {
      ...filter.class,
      id: +query.class,
    };
  }

  if (query.admin_etablisement) {
    filter.class = {
      ...filter.class,
      establishment: { establishment_admins: +query.admin_etablisement },
    };
  }

  console.log('filter', filter);

  const params = qs.stringify(
    {
      filters: filter,
      populate: ['users_permissions_user', 'class.professor_user', 'class.level'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/student-users?${params}`, 'GET', {});

  return response.data;
};

const udpateStructure = async (data: any) => {
  const response: any = await makeRequest(`${apiUrl}/api/structures/${data.id}`, 'PUT', { data });

  return response.data;
};

const udpateStudent = async (data: any) => {
  const response: any = await makeRequest(`${apiUrl}/api/update-student/${data.id}`, 'PUT', {
    data: {
      ...data,
      password: data.password1,
    },
  });
  return response.data;
};

const findUserRequest = async (id: number) => {
  const params = qs.stringify(
    {
      populate: ['class', 'class.level'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/student-users/${id}?${params}`, 'GET', {});

  return response.data;
};

export { fetchStudentRequest, findUserRequest, udpateStructure, udpateStudent };
