import './style.scss';
import BlueRadius from '@fava/Button/Simple';
import { TitlePage } from '@fava/Components';
import Loader from '@fava/Components/Loader/Loader';
import useAccount from '@fava/Hooks/useAccount';
import useClasse from '@fava/Hooks/useClasse';
import useStudent from '@fava/Hooks/useStudent';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Info from './Info';
import Notation from './Notation';
import Statistique from './Statistique';

const DetailsInfo = () => {
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const { fetchListClasse, fetchLevelRequest } = useClasse();

  const {
    student,
    findUser,
    udpateStudentRequest,
    loading,
    getParams,
    resetPassword,
    handleUpdateStudent,
  } = useStudent();

  const account = useAccount();

  const { push } = useHistory();

  const [tab, setTab] = useState(0);

  useEffect(() => {
    findUser(getParams('id_student_info'));
    fetchListClasse();
    fetchLevelRequest();
  }, []);

  const onSelect = (tabs: any) => (e: any) => {
    setTab(tabs);
  };

  const handleSave = async () => {
    if (student.password1 !== student.password2) {
      handleUpdateStudent({
        target: { name: 'errorPassword', value: 'Mot de passe ne sont pas identique .' },
      });
      return;
    }

    if (student.password1 && student.password1.length < 6) {
      handleUpdateStudent({
        target: { name: 'errorPassword', value: 'Mot de passe minimum 6 caractères .' },
      });
      return;
    }

    handleUpdateStudent({ target: { name: 'errorPassword', value: null } });

    setLoadingUpdate(true);

    try {
      const response = await udpateStudentRequest();
      if (response?.data?.id) {
        toast.success('La modification a été effectuée avec succès !');
        resetPassword();
      }
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoadingUpdate(false);
    }
  };

  const handclose = () => {
    push(`/teacher/student?teacher=${account.data.id}`);
  };

  return (
    <>
      <div className="root-center-flex-start" onClick={handclose}>
        <div>
          <IconButton>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </div>
        <div>Retour</div>
      </div>

      <div className="root-space-between">
        <div>
          <TitlePage>
            {student?.firstname || ''} {student?.lastname || ''}
          </TitlePage>
        </div>

        {!localStorage.getItem('session') && (
          <div>
            <BlueRadius
              backgroundColor="#615EFF"
              borderRadius={false}
              onClick={handleSave}
              loading={loadingUpdate}>
              Enregistrer les modifications
            </BlueRadius>
          </div>
        )}
        {localStorage.getItem('session') && tab === 1 && (
          <div>
            <BlueRadius
              backgroundColor="#615EFF"
              borderRadius={false}
              onClick={handleSave}
              loading={loadingUpdate}>
              Enregistrer les modifications
            </BlueRadius>
          </div>
        )}
      </div>

      {loading ? (
        <Loader bg="loader-custom--transparent" />
      ) : (
        <>
          <div className="container-details-student-admin-tabs">
            <div className={`container-details-tab ${tab === 0 && 'active'}`} onClick={onSelect(0)}>
              INFORMATIONS
            </div>
            <div className={`container-details-tab ${tab === 1 && 'active'}`} onClick={onSelect(1)}>
              NOTATION
            </div>
            <div className={`container-details-tab ${tab === 2 && 'active'}`} onClick={onSelect(2)}>
              STATISTIQUES
            </div>
          </div>
          <div className="margin">
            {tab === 0 && <Info />}

            {tab === 1 && <Notation />}

            {tab === 2 && <Statistique />}
          </div>
        </>
      )}
    </>
  );
};

export default DetailsInfo;
