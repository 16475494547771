import file from '@fava/Asset/Icon/filesStart.svg';
import profil from '@fava/Asset/Mock/profil.svg';
import CardModules from '@fava/Card/Modules';
import HeaderUser from '@fava/Components/HeaderUser/StudentStepper';
import ContentTimeLine from '@fava/Components/TimeLine/Content';
import ProviderTimeLine from '@fava/Components/TimeLine/Provider';
import useChapter from '@fava/Hooks/useChapter';
import LayoutStudent from '@fava/Layout/Student';
import { fetchChapitreByModules } from '@fava/Store/Thunks/fetchChapter';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { calculateAverageAndRate } from '../../../Services/chapter';

interface RouteParams {
  programId: string;
  moduleId: string;
}

const ModuleStudent = () => {
  const { programId, moduleId } = useParams<RouteParams>();

  const [loading, setLoading] = useState(false);
  const [chapter, setChapter] = useState(null);
  const [status, setStatus] = useState({
    percentage: 0,
  });

  const { listChapter, setList } = useChapter();

  const fecthListChapter = async () => {
    setLoading(true);
    setList([]);
    try {
      const response: any = await fetchChapitreByModules(+moduleId, +programId);
      const { data } = response;
      setList(data?.data || []);
      LocalStorageUtils.setItem('current_modules', data?.data || []);
      setChapter(data?.module);
      setStatus(data?.status);
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fecthListChapter();
  }, []);

  const listChapterQuizz = (listChapter || []).find((y: any) => y.isQuizz);

  return (
    <LayoutStudent
      back={{
        title: 'Modules',
        to: `/student/programs/${programId}`,
      }}
      title={chapter?.title}
      description={
        chapter?.description ||
        'Pourriez-vous ajouter des descriptions dans le back office pour obtenir un rendu similaire à la maquette ? Merci de votre compréhension.'
      }
      loading={loading}>
      <div key={Math.random()}>
        <div>
          <HeaderUser
            total={listChapter.length}
            finish={
              listChapter.filter((x: any) => x.students_id_dones || x.students_id_failed).length
            }
            img={file}
            imgProfile={profil}
            text="chapitres"
            value={status.percentage * 100}
            valueSucces={
              listChapterQuizz?.answersQuestion[0]?.reponse_user?.length > 0
                ? calculateAverageAndRate(listChapterQuizz?.answersQuestion[0]?.reponse_user)
                    ?.percentage
                : 0
            }
            textSuccess={`<span className="big-text "> </span> de réussite`}
            isModule={status.percentage === 1}
          />
        </div>
      </div>

      <div>
        <ProviderTimeLine>
          {listChapter.map((x: any, index: number) => {
            return (
              <ContentTimeLine
                done={x.students_id_dones || x.students_id_failed}
                end={listChapter?.modules && listChapter.length - 1 === index}
                repeat={
                  x.isQuizz &&
                  x?.answersQuestion.length > 0 &&
                  calculateAverageAndRate(x?.answersQuestion[0]?.reponse_user).percentage < 70
                }
                start={index === 0}>
                <CardModules
                  id={x.id}
                  ficheStudentUrl={x?.ficheStudent?.url}
                  module={index + 1}
                  title={x.title}
                  soon={!x.canClick}
                  status={x.status === 'done' || x.status === 'failed' ? 'finished' : x.status}
                  current={x.status === 'resume'}
                  success={x.students_id_dones || x.students_id_failed}
                  time={x.chapterDuration}
                  answersQuestion={x?.answersQuestion[0]?.reponse_user}
                  done={
                    x?.answersQuestion.length > 0 &&
                    calculateAverageAndRate(x?.answersQuestion[0]?.reponse_user).percentage >= 70
                  }
                  hasQuizz={x.isQuizz}
                />
              </ContentTimeLine>
            );
          })}
        </ProviderTimeLine>
      </div>
    </LayoutStudent>
  );
};

export default ModuleStudent;
