export const head: any = [
  {
    type: 'text',
    key: 'attributes.lastname',
    label: 'Nom',
    option: undefined,
  },
  {
    type: 'text',
    key: 'attributes.firstname',
    label: 'Prénom',
    option: undefined,
  },
  {
    type: 'text',
    key: 'attributes.email',
    label: 'Adresse email',
    option: undefined,
  },
  {
    type: 'array-chip',
    key: 'attributes.classes.data',
    label: 'Classes liées',
    option: {
      key: 'attributes.name',
    },
  },
];
