import './style.scss';
import studentIconWhite from '@fava/Asset/Icon/studentIconWhite.svg';
import teacherIconWhite from '@fava/Asset/Icon/teacherIconWhite.svg';
import BtnSimple from '@fava/Button/Simple';
import { Drawer, TitlePage } from '@fava/Components';
import BarchartFava from '@fava/Components/BarChartFava/BarchartFava';
import LinkCopy from '@fava/Components/LinkCopy';
import Loader from '@fava/Components/Loader/Loader';
import StatProgram from '@fava/Components/Statistic/StatProgram';
import useAccount from '@fava/Hooks/useAccount';
import useClasse from '@fava/Hooks/useClasse';
import { FC, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Program } from 'src/Models';
import {
  calculateAverageChapterDuration,
  calculateAveragePercentageClass,
  calculateSuccesProgramClass,
  statForBarchartClass,
} from '../../../../Services/user';
import ChipStudent from './Card/ChipStudent';
import ChipTeacher from './Card/ChipTeacher';

interface ProgramState {
  idProg?: number;
  title?: string;
  classe?: string;
}
interface IEditClasse {
  makeComfirm: () => void;
  programData?: Program[];
  loadingStat?: boolean;
}

const EditClasse: FC<IEditClasse> = ({ makeComfirm, programData, loadingStat }) => {
  const { loadEdit, listLevel, currentClass, setCurrentClass, EditClassFunction, setOpenDrawer } =
    useClasse();

  const account = useAccount();
  const [isOpen, setIsOpen] = useState(false);
  const [programState, setProgramState] = useState<ProgramState>({
    idProg: 0,
    title: '',
  });
  const [programDetails, setProgramDataDetails] = useState<Program[]>([]);

  const openModal = (idProg?: number, title?: string) => {
    setIsOpen(!isOpen);
    setProgramDataDetails([]);
    if (idProg && title) {
      setProgramState({
        idProg,
        title,
      });

      setProgramDataDetails(
        programData.filter(res => {
          return res.id === idProg;
        })
      );
    }
  };

  const handleChange = (e: any) => {
    const { value, name } = e.target;

    setCurrentClass({
      ...currentClass,
      [name]: value,
    });
  };

  return (
    <>
      <div>
        <div className="margin">
          <Container>
            <Row className="root-space-between-center">
              <Col md={4} xs={12}>
                <TitlePage>{currentClass.name || ''}</TitlePage>
              </Col>

              {currentClass?.professor_user?.data?.attributes && (
                <Col md={4} xs={12}>
                  <ChipTeacher
                    img={teacherIconWhite}
                    name={`${currentClass?.professor_user?.data?.attributes.lastname} ${currentClass?.professor_user?.data?.attributes.firstname}`}
                  />
                </Col>
              )}

              {currentClass?.student_users?.data.length > 0 && (
                <Col md={4} xs={12}>
                  <ChipStudent
                    id={currentClass?.id}
                    img={studentIconWhite}
                    nbr={currentClass?.student_users?.data.length}
                    handleCloseDrawer={setOpenDrawer}
                  />
                </Col>
              )}
            </Row>
          </Container>
        </div>

        <div className="margin">
          <Container>
            {account.type !== 'professor_user' ? (
              <>
                <Row>
                  <Col>
                    <Form.Group
                      className={`mb-3 ${currentClass?.error?.level && 'form-error-input'}`}>
                      <Form.Label className="color-fava-admin">Niveau de la classe</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="level"
                        value={currentClass?.level}
                        disabled={!!localStorage.getItem('session')}
                        onChange={handleChange}>
                        <option>Choisir un niveau</option>
                        {listLevel.map((x: any) => (
                          <option value={x.id}>{x.attributes.name || ''}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group
                      className={`mb-3 ${currentClass?.error?.name && 'form-error-input'}`}>
                      <Form.Label className="color-fava-admin">Nom de la classe</Form.Label>
                      <Form.Control
                        name="name"
                        onChange={handleChange}
                        value={currentClass?.name}
                        readOnly={!!localStorage.getItem('session')}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {!localStorage.getItem('session') && (
                  <Row>
                    <Col>
                      <LinkCopy label="Lien d’inscription " link={currentClass.link} />
                    </Col>
                    <Col />
                  </Row>
                )}
              </>
            ) : (
              <>
                <Row>
                  <Col xs={7}>
                    <h2 className="h1-normal">Informations</h2>
                  </Col>
                </Row>

                <Row>
                  <Col xs={7}>
                    <h3 className="color-fava-admin font-weigth-bold p-normal">Niveau</h3>

                    <p className="p-2 ">
                      {listLevel.find((x: any) => x.id === currentClass?.level)?.attributes?.name ||
                        ''}
                    </p>

                    {!localStorage.getItem('session') && (
                      <>
                        <h3 className="color-fava-admin font-weigth-bold p-normal">
                          Lien d’inscription
                        </h3>

                        <LinkCopy border={false} link={currentClass.link} />
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Container>

          <div className="margin">
            <div className="row list">
              <h3 className="pops--title">
                Statistiques{' '}
                {loadingStat && (
                  <Loader bg="loader-custom--transparent" type="loader-custom--relative" />
                )}
                {!loadingStat && programData.length === 0 && (
                  <div className="alert alert-primary" role="alert">
                    Cette classe ne possède pas de programme.
                  </div>
                )}
              </h3>

              {programData?.length > 0 &&
                !loadingStat &&
                programData?.map((data, id) => {
                  return (
                    <div className="col-12 col-sm-6 d-flex">
                      <StatProgram
                        key={Math.random()}
                        id={data?.id}
                        title={data?.title}
                        handclose={openModal}
                        image={data?.image}
                        percentageSuccess={calculateSuccesProgramClass(data.modules)}
                        percentageProgress={calculateAveragePercentageClass(data.modules)}
                        chapterDuration={calculateAverageChapterDuration(data.modules)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {!loadingStat && programData?.length > 0 && !localStorage.getItem('session') && (
          <div className="pops--btn">
            {account.type !== 'professor_user' && (
              <div className="root-center-flex">
                <div className="m-2 root-center-flex w-90">
                  <div className="m-1 w-50">
                    <BtnSimple
                      disabled={loadEdit}
                      color="#615EFF"
                      borderRadius={false}
                      onClick={makeComfirm}
                      fullWidth={true}>
                      Supprimer la classe
                    </BtnSimple>
                  </div>
                  <div className="m-1 w-50">
                    <BtnSimple
                      disabled={loadEdit}
                      backgroundColor="#615EFF"
                      borderRadius={false}
                      onClick={EditClassFunction}
                      loading={loadEdit}
                      fullWidth={true}>
                      Enregistrer
                    </BtnSimple>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {localStorage.getItem('session') && (
          <div className="root-center-flex">
            <div className="m-2 root-center-flex w-90">
              <div className="m-1 w-50">
                <BtnSimple
                  disabled={loadEdit}
                  backgroundColor="#615EFF"
                  borderRadius={false}
                  onClick={() => {
                    setOpenDrawer(false);
                  }}
                  loading={loadEdit}
                  fullWidth={true}>
                  Fermer
                </BtnSimple>
              </div>
            </div>
          </div>
        )}
      </div>
      <Drawer open={isOpen} handclose={openModal} middleWidth={true} isStat={true}>
        <div className="margin-big">
          <div>
            <h1 className="h1-normal h1-title color-fava-admin">
              Statistiques du programme {programState.title}
            </h1>
          </div>

          <BarchartFava statisticalData={statForBarchartClass(programDetails[0])} />
        </div>
      </Drawer>
    </>
  );
};

export default EditClasse;
