import MyAccount from '@fava/Page/dashboard/admin/my-account';
import { Route, Switch } from 'react-router-dom';
import AdminClasses from './classes/index';
import EtablisementList from './etablisement/index';
import OneEtablissement from './etablisement/OneEtablissement/index';
import Licence from './licences/index';
import Teacher from './teacher/index';

const AdminRoutes = () => (
  <Switch>
    <Route exact={true} path="/admin-structure/account" component={MyAccount} />
    <Route exact={true} path="/admin-structure/etablisement" component={EtablisementList} />
    <Route exact={true} path="/admin-structure/etablisement/classe" component={AdminClasses} />
    <Route exact={true} path="/admin-structure/etablisement/teachers" component={Teacher} />
    <Route exact={true} path="/admin-structure/etablisement/licences" component={Licence} />
    <Route exact={true} path="/admin-structure/etablisement/view" component={OneEtablissement} />
  </Switch>
);

export default AdminRoutes;
