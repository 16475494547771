import './style.scss';
import { ReactComponent as Logo } from '@fava/Asset/Icon/Layout/greenLogo.svg';
import { ChipUser } from '@fava/Components';
import Loader from '@fava/Components/Loader/Loader';
import { FC, ReactNode } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';

interface ILayoutPrograms {
  children: ReactNode;
  loading?: boolean;
}

const HeaderStudentHome: FC<ILayoutPrograms> = ({ children, loading }) => {
  const history = useHistory();

  const backToHome = () => {
    history.push('/student/programs');
  };

  return (
    <>
      <Navbar expand="lg" sticky="top" className="navbar-header ">
        <div className="container-fluid container-fluid--max-design">
          <div className="row flex-grow-1">
            <div className="col d-flex flex-column flex-lg-row align-items-center justify-content-lg-between">
              <Navbar.Brand
                className="header-text d-flex flex-column flex-lg-row align-items-center me-0 me-lg-2"
                as="div">
                <Logo style={{ marginRight: '1rem' }} onClick={backToHome} />
                <p className="text-center" style={{ marginTop: '1.6rem' }}>
                  La plateforme ludique d&#39;éducation aux sujets intimes
                </p>
              </Navbar.Brand>
              <Nav className="ms-lg-auto">
                <NavLink to="/student/statistical" activeClassName="none">
                  <ChipUser />
                </NavLink>
              </Nav>
            </div>
          </div>
        </div>
      </Navbar>

      {loading ? <Loader /> : children}
    </>
  );
};

export default HeaderStudentHome;
