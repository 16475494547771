export const validationForm = (data: any, listVerify: string[]) => {
  const error: any = {};

  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  for (let i = 0; i < listVerify.length; i++) {
    if (
      !data?.[listVerify[i]] ||
      `${data?.[listVerify[i]]}`.trim() === '' ||
      (listVerify[i] === 'email' && !`${data?.[listVerify[i]]}`.match(validRegex))
    ) {
      error[listVerify[i]] = true;
    } else {
      delete error[listVerify[i]];
    }
  }

  return error;
};
