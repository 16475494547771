/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const EtablishementSlice: any = createSlice({
  name: 'etablishement',
  initialState: {
    etablishement: null,
    loading: false,
  },
  reducers: {
    setEtablishement: (state, action: PayloadAction<any>) => {
      state.etablishement = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const etablishementAction = EtablishementSlice.actions;

export default EtablishementSlice.reducer;
