import './style.scss';
import CheckIcon from '@material-ui/icons/Check';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { FC, useEffect, useState } from 'react';

interface ILinkCopy {
  link: string;
  border?: boolean;
  label?: string;
}

const LinkCopy: FC<ILinkCopy> = ({ link, border = true, label }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setCopied(false);
  }, [link]);

  const clickIt = () => {
    setCopied(true);
    const copyText: any = document.getElementById('myInput');
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(copyText.value);
  };

  return (
    <>
      {label && <div className="color-fava-admin">{label || ''}</div>}

      <div className={`${border && 'border-link-copy'} content-link-copy`}>
        <input
          type="text"
          className="input-group-click-link-copy"
          value={link}
          id="myInput"
          disabled={true}
        />

        <div className="btn-icon-copy-link">
          <button className="btn-simple" type="button" onClick={clickIt}>
            {copied ? <CheckIcon /> : <FilterNoneIcon />}
          </button>
        </div>
      </div>
    </>
  );
};

export default LinkCopy;
