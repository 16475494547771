import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { useEffect } from 'react';
import useAccount from './useAccount';

const useResetSession = () => {
  const { setData, setBlockFetch } = useAccount();
  useEffect(() => {
    setData({});
    setBlockFetch(false);
    LocalStorageUtils.clear();
  }, []);
};

export default useResetSession;
