/* eslint-disable react/no-unescaped-entities */
import './error.scss';
import React from 'react';

const ErrorGar: React.FC = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-custom">
      <div className="text-center">
        <h1 className="mb-4 display-3">Désolé</h1>
        <h2>
          Vous n'avez pas l'autorisation de vous connecter à cette plateforme. Merci de nous
          contacter.
        </h2>

        <a
          href="mailto:contact@intim-ed.com?subject=Établissement GAR non autorisé"
          className="btn btn-light mt-3 btn-lg">
          Contactez-nous
        </a>
      </div>{' '}
    </div>
  );
};

export default ErrorGar;
