/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchMyProgram,
  fetchProgramByEtablissement,
  fetchPrograms,
  programByTeacher,
} from '../Thunks/fetchPrograms';

const programsSlice: any = createSlice({
  name: 'programs',
  initialState: {
    isLoading: false,
    data: [],
    meta: {},
    error: null,
    id: 0,
    title: '',
    description: '',
    img: '',
  },
  reducers: {
    setProgram: (state, action: PayloadAction<any>) => {
      state.id = action.payload.id;
      state.description = action.payload.description;
      state.title = action.payload.title;
      state.img = action.payload.img;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPrograms.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPrograms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    });
    builder.addCase(fetchPrograms.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    /**  fetch program by user */
    builder.addCase(fetchMyProgram.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMyProgram.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    });
    builder.addCase(fetchMyProgram.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    /**  fetch program by teacher */
    builder.addCase(programByTeacher.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(programByTeacher.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    });
    builder.addCase(programByTeacher.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    /**  fetch program by teacher */

    builder.addCase(fetchProgramByEtablissement.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProgramByEtablissement.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    });
    builder.addCase(fetchProgramByEtablissement.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const programsAction = programsSlice.actions;

export default programsSlice.reducer;
