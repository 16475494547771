import './style.scss';
import StackedCard from '@fava/Components/StackedCard';
import { useThunk } from '@fava/Hooks/useThunk';
import LayoutProgram from '@fava/Layout/Program';
import { fetchPrograms } from '@fava/Store/Thunks/fetchPrograms';
import { Status } from '@fava/Utils';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

interface RouteParams {
  programId: string;
}

const getButtonTextByStatus = (status: string) => {
  let text;
  switch (status) {
    case Status.to_start:
      text = 'Démarrer';
      break;
    case Status.in_progress:
      text = 'Reprendre';
      break;
    case Status.done:
      text = 'Revoir';
      break;

    default:
      text = 'Démarrer';
      break;
  }
  return text;
};

const Programs = () => {
  const [doFetchPrograms, isLoadingPrograms] = useThunk(fetchPrograms);
  const { data } = useSelector((state: any) => {
    return state.programs;
  });

  const token = (LocalStorageUtils.getItem('user_token') || '') as string;

  useEffect(() => {
    if (token.length > 0) {
      doFetchPrograms({ userToken: token });
    }
  }, [doFetchPrograms, token]);

  useEffect(() => {
    if (isLoadingPrograms) {
      document?.body?.classList?.add('content-loading');
    } else {
      document?.body?.classList?.remove('content-loading');
    }
  }, [isLoadingPrograms]);

  return (
    <LayoutProgram loading={isLoadingPrograms}>
      <h1 className="page-title">Mes programmes</h1>
      <Row className="equal-height-cards vertical-card-list" xs={1} sm={1} md={2} lg={2} xl={3}>
        {(data ?? []).map(program => {
          return (
            <div className="col d-flex vertical-card-item" key={program.id}>
              <StackedCard
                id={program.id}
                imageSrc={program?.image || ''}
                altText="image d'un programme"
                cardTitle={program.title}
                cardDescription={program.description}
                buttonText={getButtonTextByStatus(program?.programStatus)}
                buttonLink={`/student/programs/${program.id}`}
                status={program?.programStatus}
                count={program?.programRatio}
                moduleDoneCount={program?.percentage}
              />
            </div>
          );
        })}
      </Row>
    </LayoutProgram>
  );
};

export default Programs;
