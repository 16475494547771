import { etablishementAction } from '@fava/Store/Slices/EtablishementSlice';
import { RootState } from '@fava/Store/store';
import {
  findCountEleveEtablishement,
  findEtablisementyById,
  findFlollowingEtablishement,
  findStructureByAdminEtablishement,
  updateAdminEtablissement,
  updateAdminEtablissementUser,
} from '@fava/Store/Thunks/fetchEtablishement';
import { useDispatch, useSelector } from 'react-redux';

const useEtablishement = () => {
  const data: any = useSelector((state: RootState) => state.etablishement);

  const account: any = useSelector((state: RootState) => state.account);

  const dispatch = useDispatch();

  const setEtablishement = (x: any) => {
    dispatch(etablishementAction.setEtablishement(x));
  };

  const setLoading = (x: boolean) => {
    dispatch(etablishementAction.setLoading(x));
  };

  const countStudent = (resp: any[]) => {
    let count = 0;

    if (resp.length) {
      const arrayListUser = [];

      const etablishement = resp[0].attributes.classes.data || [];

      for (let b = 0; b < etablishement.length; b++) {
        const currentClasse = etablishement[b].attributes.student_users.data || [];

        for (let user = 0; user < currentClasse.length; user++) {
          const currentUser = currentClasse[user];

          arrayListUser.push(currentUser);
        }
      }

      count = arrayListUser.length;
    }

    setEtablishement({
      ...data.etablishement,
      nbr: count,
    });
  };

  const fetchEtablishementRequest = async (id?: number) => {
    setLoading(true);
    try {
      const response = await findCountEleveEtablishement(id || account.data?.id, account.type);
      countStudent(response.data);
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const fetchStructureByEtablishement = async () => {
    return findCountEleveEtablishement(account.data?.id || 0, account.type);
  };

  const updateAdminEtablissementRequestbyAdminRequest = async () => {
    return updateAdminEtablissementUser(account.data);
  };

  const updateAdminEtablissementRequest = async (etablishement: any) => {
    return updateAdminEtablissement(etablishement);
  };

  const findStructureByAdminEtablishementRequest = async () => {
    return findStructureByAdminEtablishement(account.data?.id);
  };

  const findFlollowingEtablishementRequest = async (id: number) => {
    return findFlollowingEtablishement(id, account.type);
  };

  const findEtablisementyByIdRequest = async (id: number) => {
    return findEtablisementyById(id);
  };

  return {
    ...data,
    setLoading,
    setEtablishement,
    findEtablisementyByIdRequest,
    updateAdminEtablissementRequestbyAdminRequest,
    fetchEtablishementRequest,
    fetchStructureByEtablishement,
    updateAdminEtablissementRequest,
    findFlollowingEtablishementRequest,
    findStructureByAdminEtablishementRequest,
  };
};

export default useEtablishement;
