/* eslint-disable simple-import-sort/imports */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { validationForm } from '@fava/Utils/validationForm';
import Autocomplete from '@fava/Components/Autocomplete';
import { TitlePage, Drawer } from '@fava/Components';
import useTeacher from '@fava/Hooks/useTeacher';
import useClasse from '@fava/Hooks/useClasse';
import BtnSimple from '@fava/Button/Simple';
import { FC, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import './style.scss';

interface IFormEdit {
  handleOpen: () => void;
  handleDelete: () => void;
}

const FormEdit: FC<IFormEdit> = ({ handleDelete, handleOpen }) => {
  const [loadingUpdate, setloadingUpdate] = useState(false);

  const [loadingDelete, setLoadingDelete] = useState(false);

  const {
    EditTeacherRequest,
    setTeacherSelected,
    createTeacherRequest,
    teacherSelected,
    setListTeacher,
    listTeacher,
  } = useTeacher();

  const { listClasse, fetchListClasse } = useClasse();

  useEffect(() => {
    fetchListClasse();
  }, []);

  const handleChange = (e: any) => {
    const { value, name } = e.target;

    setTeacherSelected({
      ...teacherSelected,
      [name]: value,
    });
  };

  const save = async () => {
    const error = validationForm(teacherSelected, [
      'firstname',
      'lastname',
      'email',
      'function',
      'classes',
    ]);

    setTeacherSelected({
      ...teacherSelected,
      error: error as any,
    });

    if (Object.keys(error).length) {
      return;
    }

    setloadingUpdate(true);

    try {
      let reponse;

      if (teacherSelected?.id) {
        reponse = await EditTeacherRequest();

        setListTeacher(
          (listTeacher || []).map((x: any) => {
            if (x.id === teacherSelected?.id) {
              return { ...reponse.data };
            }
            return x;
          })
        );
      } else {
        reponse = await createTeacherRequest();
        setListTeacher([...listTeacher, { ...reponse.data }]);
      }
    } catch (err: any) {
      console.error('err', err);
      throw err;
    } finally {
      setloadingUpdate(false);
      handleOpen();
    }
  };

  const changeAutocomplete = (e: any): any => {
    console.log('');
  };

  return (
    <div className="full-height">
      <div className="margin">
        <TitlePage>
          {teacherSelected?.id
            ? `${teacherSelected?.firstname} ${teacherSelected?.lastname}`
            : 'Créer un professeur'}
        </TitlePage>
      </div>

      <div className="margin">
        <Form.Group className={`mb-3 ${teacherSelected?.error?.firstname && 'form-error-input'}`}>
          <Form.Label className="color-fava-admin">Nom</Form.Label>
          <Form.Control
            name="firstname"
            onChange={handleChange}
            value={teacherSelected?.firstname}
            readOnly={!!localStorage.getItem('session')}
          />
        </Form.Group>

        <Form.Group className={`mb-3 ${teacherSelected?.error?.lastname && 'form-error-input'}`}>
          <Form.Label className="color-fava-admin">Prénom</Form.Label>
          <Form.Control
            name="lastname"
            readOnly={!!localStorage.getItem('session')}
            onChange={handleChange}
            value={teacherSelected?.lastname}
          />
        </Form.Group>

        {!localStorage.getItem('session') && (
          <Form.Group className={`mb-3 ${teacherSelected?.error?.email && 'form-error-input'}`}>
            <Form.Label className="color-fava-admin">Mail</Form.Label>
            <Form.Control
              name="email"
              readOnly={!!localStorage.getItem('session')}
              onChange={handleChange}
              value={teacherSelected?.email}
            />
          </Form.Group>
        )}

        <Form.Group className={`mb-3 ${teacherSelected?.error?.function && 'form-error-input'}`}>
          <Form.Label className="color-fava-admin">Poste</Form.Label>
          <Form.Control
            readOnly={!!localStorage.getItem('session')}
            name="function"
            onChange={handleChange}
            value={teacherSelected?.function}
          />
        </Form.Group>

        <Autocomplete
          error={teacherSelected?.error?.classes}
          name="classes"
          value={teacherSelected?.classes || []}
          list={listClasse}
          deep="attributes.name"
          onChange={!localStorage.getItem('session') ? handleChange : (changeAutocomplete as any)}
          label="Classes liées"
        />
      </div>
      {localStorage.getItem('session') && (
        <div className="root-center-flex">
          <div className="m-2 root-center-flex w-90">
            <div className="m-1 w-50">
              <BtnSimple
                backgroundColor="#615EFF"
                borderRadius={false}
                onClick={() => {
                  handleOpen();
                }}
                fullWidth={true}>
                Fermer
              </BtnSimple>
            </div>
          </div>
        </div>
      )}
      {!localStorage.getItem('session') && (
        <div className="bottom-btn-teacher">
          {teacherSelected?.id && (
            <div className="margin-litle">
              <BtnSimple
                disabled={loadingDelete || loadingUpdate}
                color="#615EFF"
                borderRadius={false}
                onClick={handleDelete}
                loading={loadingDelete}
                fullWidth={true}>
                Supprimer le professeur
              </BtnSimple>
            </div>
          )}

          <div className="margin-litle">
            <BtnSimple
              disabled={loadingDelete || loadingUpdate}
              backgroundColor="#615EFF"
              borderRadius={false}
              onClick={save}
              loading={loadingUpdate}
              fullWidth={true}>
              Enregistrer
            </BtnSimple>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormEdit;
