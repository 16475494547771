import './style.scss';
import BtnSimple from '@fava/Button/Simple';
import useChapter from '@fava/Hooks/useChapter';
import { Grid } from '@material-ui/core';

const ProgramContenuQuizz = () => {
  const { oneChapter } = useChapter();

  return (
    <div className="root-center-flex">
      <div className="content-list-teacher-admin-width">
        {(oneChapter?.questions?.data || []).length ? (
          <>
            {(oneChapter?.questions?.data || []).map((x: any) => {
              const goodResponse = x?.attributes.responses.filter((p: any) => p.goodResponse);

              return (
                <div className="margin">
                  <div className="card-border-admin p-4 cursor-pointer">
                    <h1 className="root-contenu-program-teacher-quiz-h1">
                      {x?.attributes?.title || ''}
                    </h1>

                    {goodResponse.length > 0 && (
                      <Grid container={true} alignContent="center">
                        <Grid item={true}>
                          {goodResponse.map((v: any) => {
                            return (
                              <div className="m-1">
                                <BtnSimple backgroundColor="#615EFF" borderRadius={false}>
                                  {v.name}
                                </BtnSimple>
                              </div>
                            );
                          })}
                        </Grid>

                        <Grid item={true}>
                          <p className="p-normal color-fava-admin margin-left">
                            {x?.attributes?.resultDescription || ''}
                          </p>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <h2 className="text-center margin-big h1-normal color-fava-admin">
            Aucun question à ce chapitre
          </h2>
        )}
      </div>
    </div>
  );
};

export default ProgramContenuQuizz;
