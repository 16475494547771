import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';

const qs = require('qs');

const findCountEleveEtablishement = async (id: number, type: string) => {
  let filter: any = {
    establishment_admins: {
      id: id as number,
    },
  };

  if (type === 'structure_admin') {
    filter = {
      id,
    };
  }

  const params = qs.stringify({
    filters: filter,
    populate: {
      classes: {
        populate: ['student_users'],
      },
    },
  });

  const response: any = await makeRequest(`${apiUrl}/api/establishments?${params}`, 'GET', {});

  return response.data;
};

const updateAdminEtablissementUser = async (data: any) => {
  const response: any = await makeRequest(`${apiUrl}/api/establishment-users/${data.id}`, 'PUT', {
    data,
  });

  return response.data;
};

const updateAdminEtablissement = async (data: any) => {
  console.log('updateAdminEtablissementRequest');

  const response: any = await makeRequest(`${apiUrl}/api/establishments/${data.id}`, 'PUT', {
    data,
  });

  return response.data;
};

const findStructureByAdminEtablishement = async (id: number) => {
  const params = qs.stringify(
    {
      filters: {
        establishment_admins: {
          id: id as number,
        },
      },
      populate: ['structure'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/establishments?${params}`, 'GET', {});

  return response.data;
};

const findFlollowingEtablishement = async (id: number, type: string) => {
  let filter: any = {
    establishment_admins: {
      id: id as number,
    },
  };

  if (type === 'structure_admin') {
    filter = {
      id,
    };
  }

  const params = qs.stringify(
    {
      filters: filter,
      populate: ['licence.programs'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/establishments?${params}`, 'GET', {});

  return response.data;
};

const findEtablisementyById = async (id: number) => {
  const params = qs.stringify({
    populate: [
      'classes.student_users',
      'classes.professor_user',
      'licence.programs',
      'establishment_admins',
    ],
  });
  const response: any = await makeRequest(
    `${apiUrl}/api/establishments/${id}?${params}`,
    'GET',
    {}
  );
  return response.data;
};

export {
  findCountEleveEtablishement,
  findEtablisementyById,
  findFlollowingEtablishement,
  findStructureByAdminEtablishement,
  updateAdminEtablissement,
  updateAdminEtablissementUser,
};
