/* eslint-disable react/button-has-type */
import useAccount from '@fava/Hooks/useAccount';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';

const Profile: FC = () => {
  const { data } = useAccount();
  const { push } = useHistory();
  const { setData, setBlockFetch } = useAccount();

  return (
    <div className="block block--mt-1">
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons/font/bootstrap-icons.css"
      />

      <div className="profile d-flex align-items-center">
        {/*        <Link
          className="profile__media profile__media--bordered flex-shrink-0"
          to="/student/avatar">
          <img
            src={data?.avatar?.url || '/static/media/profil.99e89c4ab4ca5aa393ceb04b6485cbeb.svg'}
            alt="img title ChipUser"
          />
          <div className="profile__media-edit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none">
              <path d="M17.1821 6.03763C17.839 5.42763 18.8608 5.44654 19.4947 6.08044L21.9195 8.50524C22.5534 9.13914 22.5723 10.1609 21.9623 10.8178L13.7328 19.6804C13.5436 19.8842 13.2781 19.9999 13 19.9999H9C8.44772 19.9999 8 19.5522 8 18.9999V14.9999C8 14.7219 8.11578 14.4564 8.31955 14.2672L17.1821 6.03763Z" />
              <path d="M8 22.9999C8 22.4477 8.44772 21.9999 9 21.9999H23C23.5523 21.9999 24 22.4477 24 22.9999C24 23.5522 23.5523 23.9999 23 23.9999H9C8.44772 23.9999 8 23.5522 8 22.9999Z" />
            </svg>
          </div>
  </Link> */}
        <div className="profile__text">
          <div className="d-flex align-items-center">
            <div className="profile__name mr-3 " style={{ marginRight: '10px' }}>
              {data?.firstname} {data?.lastname}{' '}
            </div>

            <Link to="/">
              <button
                className="btn btn-primary btn-lg"
                onClick={() => {
                  setData({});
                  setBlockFetch(false);

                  if (localStorage.getItem('session')) {
                    push(`/disconnect-sso`);
                    window.location.reload();
                    LocalStorageUtils.clear();
                    return;
                  }
                  LocalStorageUtils.clear();
                  window.location.reload();
                }}>
                <i className="bi bi-box-arrow-right mr-2" /> Se déconnecter
              </button>
            </Link>
          </div>

          <div className="profile__category d-flex">
            <div className="profile__category-icon flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none">
                <path
                  d="M16.501 4.25H7.50102C6.25882 4.25 5.25102 5.25781 5.25102 6.5V14.75C5.24727 15.6341 5.50882 16.4985 6.00102 17.2326C6.53914 18.0473 7.32946 18.6641 8.25102 18.9876V18.5001C8.25102 18.0857 8.58663 17.7501 9.00102 17.7501C9.4154 17.7501 9.75102 18.0857 9.75102 18.5001V19.2501H14.251V18.5001C14.251 18.0857 14.5866 17.7501 15.001 17.7501C15.4154 17.7501 15.751 18.0857 15.751 18.5001V18.9876C16.6726 18.6641 17.4629 18.0473 18.001 17.2326C18.4932 16.4985 18.7548 15.6341 18.751 14.75V6.5C18.751 5.25781 17.7432 4.25 16.501 4.25ZM14.251 10.25C14.251 10.4488 14.1723 10.64 14.0316 10.7806C13.891 10.9213 13.6998 11 13.501 11H10.501C10.0866 11 9.75103 10.6644 9.75103 10.25V8.75C9.75103 8.33561 10.0866 8 10.501 8H13.501C13.6998 8 13.891 8.07875 14.0316 8.21937C14.1723 8.36 14.251 8.55124 14.251 8.74999V10.25Z"
                  fill="#878585"
                />
                <path
                  d="M15.751 19.7374V20.7499C15.751 21.1643 15.4154 21.4999 15.001 21.4999C14.5866 21.4999 14.251 21.1643 14.251 20.7499V19.9999H9.75098V20.7499C9.75098 21.1643 9.41536 21.4999 9.00098 21.4999C8.58659 21.4999 8.25098 21.1643 8.25098 20.7499V19.7374C7.32942 19.414 6.5391 18.7971 6.00098 17.9824V20.7499C6.00098 21.9921 7.00878 22.9999 8.25098 22.9999H15.751C16.9932 22.9999 18.001 21.9921 18.001 20.7499V17.9824C17.4628 18.7971 16.6725 19.414 15.751 19.7374Z"
                  fill="#878585"
                />
                <path
                  d="M8.25099 1.25001H9.00099C9.19974 1.25001 9.39099 1.32876 9.5316 1.46938C9.67223 1.61001 9.75098 1.80126 9.75098 2V4.25001H7.50098V2C7.50098 1.58562 7.8366 1.25001 8.25099 1.25001Z"
                  fill="#878585"
                />
                <path
                  d="M15.001 1.25001H15.751C15.9497 1.25001 16.141 1.32876 16.2816 1.46938C16.4222 1.61001 16.501 1.80126 16.501 2V4.25001H14.251V2C14.251 1.58562 14.5866 1.25001 15.001 1.25001Z"
                  fill="#878585"
                />
                <path
                  d="M12.751 6.12501H11.251C10.6294 6.12501 10.126 5.62156 10.126 5V3.875C10.126 3.25345 10.6294 2.75 11.251 2.75H12.751C13.3725 2.75 13.876 3.25345 13.876 3.875V5C13.876 5.62156 13.3725 6.12501 12.751 6.12501ZM11.251 3.49988C11.0438 3.49988 10.876 3.66769 10.876 3.87488V4.99988C10.876 5.09926 10.9154 5.19488 10.9857 5.2652C11.056 5.33552 11.1516 5.37489 11.251 5.37489H12.751C12.8504 5.37489 12.946 5.33552 13.0163 5.2652C13.0866 5.19489 13.126 5.09927 13.126 4.99988V3.87488C13.126 3.77551 13.0866 3.67988 13.0163 3.60956C12.946 3.53924 12.8504 3.49987 12.751 3.49987L11.251 3.49988Z"
                  fill="#878585"
                />
              </svg>
            </div>
            <div
              className="
                    text--user-rose">
              Class : {data?.class?.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
