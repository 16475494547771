import { LocalStorageValue } from 'src/global';
import { lsPrefix } from './constant';

export class LocalStorageUtils {
  static setItem(key: string, value: LocalStorageValue): void {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(`${lsPrefix}_${key}`, serializedValue);
  }

  static getItem<T = LocalStorageValue>(key: string): T | null {
    const serializedValue = localStorage.getItem(`${lsPrefix}_${key}`);
    if (serializedValue) {
      try {
        return JSON.parse(serializedValue) as T;
      } catch (error) {
        console.error(`Error parsing localStorage value for key '${key}':`, error);
      }
    }
    return null;
  }

  static removeItem(key: string): void {
    localStorage.removeItem(`${lsPrefix}_${key}`);
  }

  static clear(): void {
    localStorage.clear();
  }
}
