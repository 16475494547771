/* eslint-disable react/no-unescaped-entities */
import './error.scss';
import useResetSession from '@fava/Hooks/useResetSession';
import React from 'react';

const DisconnectedGar: React.FC = () => {
  useResetSession();
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-custom">
      <div className="text-center">
        <h1 className="mb-4 display-3">Au revoir</h1>
        <h2>Vous êtes maintenant déconnecté.</h2>
      </div>{' '}
    </div>
  );
};

export default DisconnectedGar;
