import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';

const qs = require('qs');

const fetchClass = async (id: number, type: string) => {
  let filter: any = {
    establishment: {
      establishment_admins: {
        id: id as any,
      },
    },
  };

  if (type === 'structure_admin') {
    filter = {};
  }

  if (type === 'professor_user') {
    filter = {
      professor_user: {
        id: id as number,
      },
    };
  }

  const params = qs.stringify(
    {
      filters: filter,
      populate: ['level'],
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/classes?${params}`, 'GET', {});

  return response.data;
};

const fetchClassPopulate = async (id: number, type: string) => {
  let filter: any = {
    establishment: {
      establishment_admins: {
        id: id as any,
      },
    },
  };

  if (type === 'structure_admin') {
    filter = {
      establishment: {
        id: id as any,
      },
    };
  }

  if (type === 'professor_user') {
    filter = {
      professor_user: {
        id: id as number,
      },
    };
  }

  console.log('filter', filter);

  const params = qs.stringify(
    {
      filters: filter,
      populate: ['professor_user', 'student_users', 'level'],
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/classes?${params}`, 'GET', {});

  return response.data;
};

const createClasseRequest = async (data: any) => {
  const params = qs.stringify(
    {
      populate: ['professor_user', 'student_users', 'level'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/classes?${params}`, 'POST', {
    data: {
      ...data,
    },
  });

  return response.data;
};

const EditClasseRequest = async (data: any) => {
  const params = qs.stringify(
    {
      populate: ['professor_user', 'student_users', 'level'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/classes/${data.id}?${params}`, 'PUT', {
    data: {
      ...data,
    },
  });

  return response.data;
};

const fetchLevel = async () => {
  const response: any = await makeRequest(`${apiUrl}/api/levels`, 'GET', {});
  return response.data;
};

const deleteClasse = async (id: number) => {
  const response: any = await makeRequest(`${apiUrl}/api/classes/${id}`, 'DELETE', {});
  return response.data;
};

export {
  createClasseRequest,
  deleteClasse,
  EditClasseRequest,
  fetchClass,
  fetchClassPopulate,
  fetchLevel,
};
