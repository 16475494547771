import '../style.scss';
import dommgeIcon from '@fava/Asset/Icon/dommgeIcon.svg';
import validationIcon from '@fava/Asset/Icon/validationIcon.svg';
import Simple from '@fava/Button/Simple';
import ButtonFava from '@fava/Components/ButtonFava/ButtonFava';
import GoodResponse from '@fava/Components/Score/GoodResponse';
import Win from '@fava/Components/Score/Win';
import useModule from '@fava/Hooks/useModule';
import useQuestionAnswer from '@fava/Hooks/useQuestionAnswer';
import ProgressBarComplicated from '@fava/ProgressBar/Complicated';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { calculateAverageAndRate } from '../../../../Services/chapter';

interface ISuccessQuestionProps {
  nextChapters?: () => void;
  isRestart?: boolean;
}
const SuccessQuizz: React.FC<ISuccessQuestionProps> = ({ nextChapters, isRestart }) => {
  const history = useHistory();
  const { programId, moduleId, chapterId }: any = useParams();

  const [loadingSave, setloadingSave] = useState(false);

  const [success, setSuccess] = useState(true);

  const [allChapters, setAllChapters] = useState(
    LocalStorageUtils.getItem('current_modules') || []
  );

  const { answer, setAnswer } = useQuestionAnswer();

  const { id } = useModule();

  const seenAgain = () => {
    LocalStorageUtils.setItem('isQuizRestart', true);
    window.location.reload();
  };
  const getNextId = (arrayObject, currentId) => {
    // Recherche de l'index de l'élément ayant l'ID donné
    const currentIndex = arrayObject.findIndex(item => item.id === parseInt(currentId, 10));

    // Vérification si l'ID donné existe dans le tableau
    if (currentIndex === -1 || currentIndex === arrayObject.length - 1) {
      // L'ID donné n'existe pas ou est le dernier élément du tableau
      return null; // ou renvoyer une valeur par défaut si nécessaire
    }

    return arrayObject[currentIndex + 1]?.id;
  };

  const redirectQuizz = () => {
    history.push(
      `/student/programs/${programId}/modules/${getNextId(
        LocalStorageUtils.getItem('nextModules'),
        moduleId
      )}`
    );
    setAnswer([]);
    nextChapters();
  };

  return (
    <div className="root-center-flex">
      <div className="success-question">
        <div className="p-2">
          <img
            src={calculateAverageAndRate(answer).percentage >= 70 ? validationIcon : dommgeIcon}
            alt="flag-dynamic-color"
          />
        </div>

        <div>
          <div className="p-2">
            <h1 className="h1-normal text-light">
              {calculateAverageAndRate(answer).percentage >= 70
                ? 'Bravo ! Tu as validé ton module !'
                : 'Ton score n’est pas suffisant retente ta chance !'}
            </h1>
          </div>

          <p className="p-normal text-light">
            {calculateAverageAndRate(answer).percentage >= 70
              ? 'Tu as réussi à valider ton module avec succès. Si tu le souhaites, tu peux toujours revoir certains chapitres pour consolider tes connaissances.'
              : "La réussite au Quiz Final nécessite un minimum de 70% pour valider ton module. Si tu souhaites réessayer le Quiz Final, n'hésite pas à revoir les chapitres que tu souhaites réviser préalablement!"}
          </p>

          <div className="root-center-flex p-4">
            <div className="root-center-flex">
              {!isRestart && (
                <GoodResponse
                  score={calculateAverageAndRate(answer).scoring + 1}
                  textLigth={true}
                />
              )}
              <Win score={calculateAverageAndRate(answer).scoring} textLigth={true} />
            </div>

            <div>
              <div className="p-2">
                <ProgressBarComplicated
                  total={allChapters.length}
                  finish={allChapters.length}
                  text="Chapitre"
                />
              </div>

              <div className="p-2">
                <ProgressBarComplicated
                  total={calculateAverageAndRate(answer).totalResponses}
                  finish={calculateAverageAndRate(answer).scoring}
                  text="de réussite"
                  percentage={true}
                  isSuccess={true}
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-8 d-flex p-5 d-flex">
              <div className="row flex-grow-1">
                <div className="col-lg-6">
                  {' '}
                  <div className="ms-4 me-4">
                    <ButtonFava
                      type="button-fava--secondary"
                      text="Refaire le quiz"
                      url={seenAgain}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="ms-4 me-4">
                    {getNextId(LocalStorageUtils.getItem('nextModules'), moduleId) && (
                      <ButtonFava
                        type="button-fava--primary"
                        text="Voir le module suivant"
                        url={redirectQuizz}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessQuizz;
