import file from '@fava/Asset/Icon/folder.svg';
import profil from '@fava/Asset/Mock/profil.svg';
import ModuleCardProgramDetails from '@fava/Common/Card/Program/card';
import HeaderUser from '@fava/Components/HeaderUser/StudentStepper';
import ContentTimeLine from '@fava/Components/TimeLine/Content';
import ProviderTimeLine from '@fava/Components/TimeLine/Provider';
import { useThunk } from '@fava/Hooks/useThunk';
import LayoutStudent from '@fava/Layout/Student';
import { fetchCurrentUserModules } from '@fava/Store/Thunks/fetchModules';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { calculateAverageAndRate } from '../../../Services/chapter';

const Program = () => {
  const { programId }: any = useParams();
  const history = useHistory();

  const [doFetchModules, isLoadingModules] = useThunk(fetchCurrentUserModules);

  const modules = useSelector((state: any) => {
    return state.modules;
  });

  const program = useSelector((state: any) => {
    return state.currentProgram.data;
  });

  useEffect(() => {
    if (programId) {
      doFetchModules({ programId });
    }
  }, [programId, doFetchModules]);

  const handleCardButtonClick = (id: string) => {
    history.push(`/student/programs/${programId}/modules/${id}`);
  };

  const getChaptersHasQuizScore = chapters => {
    const scores =
      chapters.length > 0 &&
      chapters.filter(res => {
        return res.quizzEnd;
      });

    return scores.length > 0 && scores[0]?.answersQuestion[0]?.reponse_user?.length > 0
      ? calculateAverageAndRate(scores[0]?.answersQuestion[0]?.reponse_user)
      : {
          percentage: 0,
        };
  };

  const updateDataWithCanClick = data => {
    return data.map((item, index) => {
      if (
        index === 0 ||
        (index > 0 &&
          (data[index - 1].statusModule === 'finished' ||
            data[index - 1].statusModule === 'resume'))
      ) {
        return { ...item, canClick: true };
      }
      return { ...item, canClick: false };
    });
  };

  return (
    <LayoutStudent
      back={{
        title: 'Programmes',
        to: `/student/programs`,
      }}
      imgLayout={modules?.data[0]?.image}
      title={modules?.data[0]?.title}
      isImage={true}
      description={
        modules?.data[0]?.shortDescription ||
        'Pourriez-vous ajouter des descriptions dans le back office pour obtenir un rendu similaire à la maquette ? Merci de votre compréhension.'
      }
      loading={isLoadingModules}>
      <div>
        <HeaderUser
          key={Math.random()}
          img={file}
          total={modules?.data[0]?.modules?.length}
          finish={
            modules?.data[0]?.modules?.filter(res => {
              return res.statusModule === 'finished';
            }).length
          }
          value={modules?.data[0]?.percentage}
          text={modules?.data[0]?.modules?.length > 1 ? 'modules' : 'module'}
          imgProfile={profil}
        />
      </div>

      <div>
        {modules?.data[0]?.modules?.length && (
          <ProviderTimeLine>
            {updateDataWithCanClick(modules?.data[0].modules).map((x: any, index: number) => {
              return (
                <ContentTimeLine
                  repeat={
                    getChaptersHasQuizScore(x.chapters)?.percentage < 70 &&
                    x?.statusModule === 'finished' &&
                    x?.doesModuleHaveEndQuizz
                  }
                  done={
                    (getChaptersHasQuizScore(x.chapters)?.percentage >= 70 &&
                      x?.statusModule === 'finished' &&
                      x?.doesModuleHaveEndQuizz) ||
                    x?.statusModule === 'finished'
                  }
                  end={modules?.data[0]?.modules && modules.data[0].modules.length - 1 === index}
                  start={index === 0}>
                  <ModuleCardProgramDetails
                    index={index}
                    title={x?.title}
                    count={x?.chapters?.length}
                    chapterDoneCount={
                      x?.chapters?.filter(res => {
                        return res?.students_id_dones || res?.students_id_failed;
                      }).length
                    }
                    successRate={getChaptersHasQuizScore(x.chapters)?.percentage}
                    chapeterRatio={x?.chapeterRatio}
                    doesModuleHaveEndQuizz={x?.doesModuleHaveEndQuizz}
                    status={x?.statusModule}
                    imageUrl={x?.image}
                    id={x.id}
                    statusNextModule={x?.canClick}
                    handleClick={handleCardButtonClick}
                  />
                </ContentTimeLine>
              );
            })}
          </ProviderTimeLine>
        )}
      </div>
    </LayoutStudent>
  );
};

export default Program;
