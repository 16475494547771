import './style.scss';
import BtnBack from '@fava/Button/Back';
import { TitlePage } from '@fava/Components';
import { useState } from 'react';
import Following from './Following';
import Program from './Program';
import User from './User';

const Licence = () => {
  const [tab, setTab] = useState(0);

  const onSelect = (tabs: any) => (e: any) => {
    setTab(tabs);
  };

  return (
    <>
      <BtnBack to="/admin-structure/etablisement/view" />

      <div className="root-space-between">
        <TitlePage>Licence</TitlePage>
      </div>

      <div className="margin">
        <div className="container-details-tabs-licence">
          <div className={`container-details-tab ${tab === 0 && 'active'}`} onClick={onSelect(0)}>
            ABONNEMENT
          </div>
          <div className={`container-details-tab ${tab === 1 && 'active'}`} onClick={onSelect(1)}>
            PROGRAMMES
          </div>
          <div className={`container-details-tab ${tab === 2 && 'active'}`} onClick={onSelect(2)}>
            UTILISATEURS
          </div>
        </div>
      </div>

      <div className="margin">
        {tab === 0 && <Following />}

        {tab === 1 && <Program />}

        {tab === 2 && <User />}
      </div>
    </>
  );
};

export default Licence;
