import { CircularProgress } from '@material-ui/core';
import { FC, memo, Suspense } from 'react';
import { Route, RouteProps } from 'react-router-dom';

const ProtectedLazyRoute: FC<RouteProps> = memo(props => {
  const FallbackComponent = <CircularProgress />;

  return (
    <Suspense fallback={FallbackComponent}>
      <Route {...props} />
    </Suspense>
  );
});

export default ProtectedLazyRoute;
