import './style.scss';
import CloseIcon from '@material-ui/icons/Close';
import { FC } from 'react';

interface ITag {
  onDelete?: (e: any) => void;
  name: string;
}

const Tag: FC<ITag> = ({ onDelete, name }) => {
  return (
    <div className="tag-chip">
      <span>{name}</span>
      {onDelete && !localStorage.getItem('session') && <CloseIcon onClick={onDelete} />}
      <div className="end-Chip" />
    </div>
  );
};

export default Tag;
