import './style.scss';
import iconSuccess from '@fava/Asset/Icon/coupe.svg';
import DoneIcon from '@material-ui/icons/Done';
import { FC } from 'react';

interface Icomplicated {
  total: number;
  finish: number;
  text: string;
  percentage?: boolean;
  isSuccess?: boolean;
}

const ProgressBarComplicated: FC<Icomplicated> = ({
  total,
  finish,
  text,
  percentage,
  isSuccess,
}) => {
  const calcul = total !== 0 ? Math.round((finish * 100) / total) : 0;

  const color = () => {
    if (isSuccess) {
      if (calcul < 70) {
        return '#FF7E7E';
      }

      return '#FD99FF';
    }

    return '#FFEA00';
  };

  return (
    <div className="content-title-complicated" style={{ border: `border: 2px solid ${color()}` }}>
      <div
        className="content-title-percentage"
        style={{ width: `${calcul}%`, backgroundColor: color() }}
      />

      <div className="content-complicated">
        <div className="content-complicated-text">
          <div className="content-flex-end-complicated-h1">
            {percentage ? (
              `${calcul} %`
            ) : (
              <>
                {finish}/{total}
              </>
            )}
          </div>
          <div className="content-flex-end-complicated-p">{` ${text}`}</div>
        </div>
        <div>
          {total === finish &&
            (isSuccess ? <img src={iconSuccess} alt="isSuccess" /> : <DoneIcon />)}
        </div>
      </div>
    </div>
  );
};

export default ProgressBarComplicated;
