import './style.scss';
import Tag from '@fava/Components/Chip/Tag';
import Loader from '@fava/Components/Loader/Loader';
import { DeepArrayObject } from '@fava/Utils';
import Avatar from '@material-ui/core/Avatar';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckIcon from '@material-ui/icons/Check';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { FC, ReactNode, useState } from 'react';
import Table from 'react-bootstrap/Table';

type option = {
  key?: string;
  function?: (link: string) => ReactNode;
};

export interface Ihead {
  key: string;
  label: string;
  type?: 'text' | 'array-chip' | 'function' | 'link';
  option?: option;
}

interface ITableComponent {
  head: Ihead[];
  body: any[];
  onSelect?: (data: any) => void;
  loading?: boolean;
}

const TableComponent: FC<ITableComponent> = ({ head, body, loading, onSelect }) => {
  const [clicked, setClicked] = useState(0);

  const handleClickedUrl = (id: number, link) => (e: any) => {
    setClicked(id);
    navigator.clipboard.writeText(link);
    //    window.open(link, '_blank');
  };

  const className = (indexHead: any, indexBody: any) => {
    if (indexHead === 0 && indexBody === body.length - 1) {
      return 'th-body body-bottom-left';
    }

    if (indexHead === head.length - 1 && indexBody === body.length - 1) {
      return 'th-body body-bottom-right';
    }

    return 'th-body';
  };

  if (loading) {
    return <Loader bg="loader-custom--transparent" />;
  }

  const whenClick = (data: any) => (e: any) => {
    onSelect(data);
  };

  const seleted = (indexHead: number, length: number, data: any) => {
    if (onSelect && indexHead === length - 1) {
      return (
        <div>
          <ArrowForwardIosIcon
            onClick={whenClick(data)}
            className="ArrowForwardIosIcon-on-select-table"
          />
        </div>
      );
    }
  };

  return (
    <div>
      <Table className="table">
        <thead className="thead">
          <tr>
            {(head || []).map((x: Ihead, index: number) => {
              if (index === 0) {
                return <th className="head-top-left">{x.label}</th>;
              }

              if (index === head.length - 1) {
                return <th className="head-top-right">{x.label}</th>;
              }

              return <th>{x.label}</th>;
            })}
          </tr>
        </thead>

        {(body || []).map((y: any, indexBody: number) => {
          return (
            <tr className="tr-body">
              {head.map((x: Ihead, indexHead: number) => {
                if (x.type === 'array-chip') {
                  return (
                    <th>
                      <div className={className(indexHead, indexBody)}>
                        <div className="root-space-between">
                          <div>
                            {(DeepArrayObject(y, x.key) || []).slice(0, 5).map((z: any) => {
                              return (
                                <div className="chip-array">{DeepArrayObject(z, x.option.key)}</div>
                              );
                            })}
                            {(DeepArrayObject(y, x.key) || []).length > 5 && (
                              <div className="limit-array-chip">+5</div>
                            )}
                          </div>
                          {seleted(indexHead, head.length, y)}
                        </div>
                      </div>
                    </th>
                  );
                }

                if (x.type === 'function') {
                  return (
                    <th>
                      <div className={className(indexHead, indexBody)}>
                        <div className="root-space-between">
                          <div>{x.option.function(DeepArrayObject(y, x.key))}</div>
                          {seleted(indexHead, head.length, y)}
                        </div>
                      </div>
                    </th>
                  );
                }

                if (x.type === 'link') {
                  return (
                    <th>
                      <div className={className(indexHead, indexBody)}>
                        <div className="root-space-between">
                          <div>Lien</div>
                          <div>
                            {DeepArrayObject(y, x.key) && (
                              <button
                                className="btn-simple"
                                type="button"
                                onClick={handleClickedUrl(y.id, DeepArrayObject(y, x.key))}>
                                {clicked === y.id ? (
                                  <CheckIcon fontSize="small" />
                                ) : (
                                  <FilterNoneIcon fontSize="small" />
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </th>
                  );
                }

                return (
                  <th>
                    <div className={className(indexHead, indexBody)}>
                      <div className="root-space-between">
                        <div>{DeepArrayObject(y, x.key)}</div>
                        {seleted(indexHead, head.length, y)}
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </Table>
    </div>
  );
};

export default TableComponent;
