import { Ihead } from '@fava/Components/Table';

const getNameTeacher = (data: any) => {
  return `${data?.firstname || ''} ${data?.lastname || ''}`;
};

export const head: Ihead[] = [
  {
    type: 'text',
    key: 'attributes.firstname',
    label: 'Nom',
  },
  {
    type: 'text',
    key: 'attributes.lastname',
    label: 'Prénom',
  },
  // {
  //   type: 'text',
  //   key: 'attributes.users_permissions_user.data.email',
  //   label: 'Adresse email',
  // },
  {
    type: 'text',
    key: 'attributes.class.data.attributes.level.data.attributes.name',
    label: 'Niveau',
  },
  {
    type: 'text',
    key: 'attributes.class.data.attributes.name',
    label: 'Classe liée',
  },
  {
    type: 'function',
    key: 'attributes.class.data.attributes.professor_user.data.attributes',
    label: 'PROFESSEUR RéFéRENT',
    option: {
      function: getNameTeacher,
    },
  },
];

export const headEdulib: Ihead[] = [
  {
    type: 'text',
    key: 'attributes.firstname',
    label: 'Nom',
  },
  {
    type: 'text',
    key: 'attributes.lastname',
    label: 'Prénom',
  },
  // {
  //   type: 'text',
  //   key: 'attributes.users_permissions_user.data.email',
  //   label: 'Adresse email',
  // },

  {
    type: 'text',
    key: 'attributes.class.data.attributes.name',
    label: 'Classe liée',
  },
  {
    type: 'function',
    key: 'attributes.class.data.attributes.professor_user.data.attributes',
    label: 'PROFESSEUR RéFéRENT',
    option: {
      function: getNameTeacher,
    },
  },
];
