import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IModalValidation {
  open: boolean;
  type: 'success' | 'failed';
  exactResponse: number;
  description: string;
}
export interface IQuestionAnswer {
  listQuestionAnswer: any[];
  answer: any[];
  step: number;
  stepQuizz: number;
  modalValidation: IModalValidation;
  totalLengthQuestion: number;
}

const initialState: IQuestionAnswer = {
  step: 0,
  stepQuizz: 0,
  listQuestionAnswer: [],
  totalLengthQuestion: 0,
  modalValidation: {
    open: false,
    type: 'success',
    exactResponse: 1,
    description: '',
  },
  answer: [],
};

const QuestionAnswer = createSlice({
  name: 'questionAnswer',
  initialState,
  reducers: {
    setListQuestionAnswer: (state, action: PayloadAction<any[]>) => {
      state.listQuestionAnswer = action.payload;
      state.totalLengthQuestion = action.payload.length;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setStepQuizz: (state, action: PayloadAction<number>) => {
      state.stepQuizz = action.payload;
    },
    setTotalLengthQuestion: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setAnswer: (state, action: PayloadAction<any[]>) => {
      state.answer = action.payload;
    },
    setModalValidation: (state, action: PayloadAction<any>) => {
      state.modalValidation = action.payload;
    },
  },
});

export const QuestionAnswerAction = QuestionAnswer.actions;

export default QuestionAnswer.reducer;
