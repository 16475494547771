/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import downLoad from '@fava/Asset/Icon/downLoad.svg';
import BtnSimple from '@fava/Button/Simple';
import Loader from '@fava/Components/Loader/Loader';
import useChapter from '@fava/Hooks/useChapter';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Cours from './Cours';
import Quizz from './Quizz';

const ProgramContenu = () => {
  const { iDteacher, programId, moduleId, chapterId }: any = useParams();

  const { fetchOneChapter, loading, oneChapter } = useChapter();

  const search = useLocation().search as string;

  const query = Object.fromEntries(new URLSearchParams(search));

  const [tab, setTab] = useState(0);

  const onSelect = (tabs: any) => (e: any) => {
    setTab(tabs);
  };

  const history = useHistory();

  const backProgram = (e: any) => {
    e.preventDefault();
    history.push(
      `/teacher/${iDteacher}/programs/${programId}/modules/${moduleId}?module=${query.module}`
    );
  };

  const handleDownload = (ficheStudentUrl: string, title: string) => (e: any) => {
    const link = document.createElement('a');
    link.href = ensureHttps(ficheStudentUrl);
    link.setAttribute('download', `${title}.pdf`);
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    fetchOneChapter(chapterId);
  }, []);

  if (loading) {
    return <Loader bg="loader-custom--transparent" />;
  }

  function ensureHttps(url: any) {
    if (!url.startsWith('https://')) {
      url = `https://${url}`;
    }
    return url;
  }

  return (
    <div className="content-program-teacher">
      <div className="root-space-between-center">
        <div className="root-center-flex-start" onClick={backProgram}>
          <div>
            <IconButton>
              <ArrowBackIcon fontSize="small" />
            </IconButton>
          </div>
          <div>Retour aux chapitres</div>
        </div>

        <div>
          <div className="root-center-flex-start">
            <div className="m-2">
              {(oneChapter.ficheStudent?.data || [])?.[0]?.attributes?.url && (
                <BtnSimple
                  onClick={handleDownload(
                    (oneChapter.ficheStudent?.data || [])?.[0]?.attributes?.url || '',
                    oneChapter?.title
                  )}
                  color="#615EFF"
                  borderRadius={false}>
                  <div>Télécharger le PDF élève</div>
                  <img
                    src={downLoad}
                    className="icon-validation-download m-2"
                    alt="icon-validation"
                  />
                </BtnSimple>
              )}
            </div>

            <div className="m-2">
              {(oneChapter.ficheTeacher?.data || [])?.[0]?.attributes?.url && (
                <BtnSimple
                  onClick={handleDownload(
                    (oneChapter.ficheTeacher?.data || [])?.[0]?.attributes?.url || '',
                    oneChapter?.title
                  )}
                  color="#615EFF"
                  borderRadius={false}>
                  <div>Télécharger le PDF enseignant</div>
                  <img
                    src={downLoad}
                    className="icon-validation-download  m-2"
                    alt="icon-validation"
                  />
                </BtnSimple>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="margin-big">
        <div className="root-space-between-center">
          <div>
            <h2 className="card-horizontal__subtitle text-uppercase text--fava-green">
              CHAPITRE {query.chapter || 0}
            </h2>
            <h1 className="h1-normal color-fava-admin">{oneChapter?.title || ''}</h1>
          </div>
        </div>

        <div className="margin">
          <div className="container-details-tabs">
            <div
              className={`container-details-tab-admin ${tab === 0 && 'active'}`}
              onClick={onSelect(0)}>
              COURS
            </div>

            <div
              className={`container-details-tab-admin ${tab === 1 && 'active'}`}
              onClick={onSelect(1)}>
              QUIZ
            </div>
          </div>

          <div className="margin">
            {tab === 0 && <Cours />}
            {tab === 1 && <Quizz />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramContenu;
