import HeaderStudentHome from '@fava/Components/HeaderUser/StudentHome';
import { ChapterContent, Content, ModulesStudent, ProgramStudent } from '@fava/Page';
import Avatar from '@fava/Page/Student/Avatar';
import Programs from '@fava/Page/Student/Programs';
import Statistical from '@fava/Page/Student/Statistical';
import { Route, Switch } from 'react-router-dom';

const StudentRoutes = () => (
  <Switch>
    <Route path="/student/programs/:programId" exact={true} component={ProgramStudent} />
    <Route
      exact={true}
      path="/student/programs/:programId/modules/:moduleId"
      component={ModulesStudent}
    />
    <Route
      path="/student/programs/:programId/modules/:moduleId/chapters/:chapterId"
      exact={true}
      component={ChapterContent}
    />

    <Route
      path="/student/programs/:programId/modules/:moduleId/chapters/:chapterId/contents/:contentId"
      exact={true}
      component={Content}
    />

    <HeaderStudentHome>
      <Route exact={true} path="/student/programs" component={Programs} />
      <Route exact={true} path="/student/statistical" component={Statistical} />
      <Route exact={true} path="/student/avatar" component={Avatar} />
    </HeaderStudentHome>
  </Switch>
);

export default StudentRoutes;
