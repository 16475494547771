import './style.scss';
import file from '@fava/Asset/Icon/folder.svg';
import hour from '@fava/Asset/Icon/hour.svg';
import Loader from '@fava/Components/Loader/Loader';
import Profile from '@fava/Components/Statistic/Profil';
import StatModule from '@fava/Components/Statistic/StatModule';
import StatProgram from '@fava/Components/Statistic/StatProgram';
import { FC, useEffect, useState } from 'react';
import { Program } from 'src/Models';
import {
  calculateAverageChapterDuration,
  calculateAveragePercentage,
  calculateAveragePercentageProgram,
  calculateModuleRatio,
  calculateTotalChapterDuration,
  getStatisticStudent,
} from '../../../Services/user';

const Statistical: FC = () => {
  const [programData, setProgramData] = useState<Program[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const items = [
    { id: 1, stat: '18h45', text: 'temps passés sur le parcours' },

    { id: 2, stat: '4/10', text: 'modules validés' },
  ];

  useEffect(() => {
    setLoading(true);
    document?.body?.classList?.add('content-loading');
    const fetchData = async () => {
      try {
        const response = await getStatisticStudent();
        setProgramData(response);
        setLoading(false);
        document?.body?.classList?.remove('content-loading');
      } catch (error) {
        setLoading(false);
        document?.body?.classList?.remove('content-loading');
        console.error("Une erreur s'est produite :", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="bg--grey-light">
      <div className="container-fluid container-fluid--max-design">
        {loading ? (
          <Loader />
        ) : (
          <div className="row">
            <div className="col">
              <Profile />
              <div className="block">
                <div className="heading-2 text--fava-green ">Statistiques</div>
                <div className="row list">
                  {items.map(data => {
                    return (
                      <div className="col-12 col-sm-6 col-lg-4 d-flex">
                        <StatModule
                          key={Math.random()}
                          stat={
                            data.id === 1
                              ? calculateTotalChapterDuration(programData)
                              : calculateModuleRatio(programData)
                          }
                          text={data.text}
                          img={data.id === 1 ? hour : file}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="block">
                <div className="heading-2 text--fava-green ">Mes programmes</div>
                <div className="row list">
                  {programData.length > 0 &&
                    programData.map((data, id) => {
                      return (
                        <div className="col-12 col-sm-6 col-xl-4 d-flex">
                          <StatProgram
                            key={Math.random()}
                            id={data.id}
                            title={data.title}
                            image={data.image}
                            percentageSuccess={calculateAveragePercentageProgram(data.modules) || 0}
                            percentageProgress={calculateAveragePercentage(data.modules) || 0}
                            chapterDuration={calculateAverageChapterDuration(data.modules)}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Statistical;
