/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
import './style.scss';
import BackStudent from '@fava/Button/BackStudent';
import { TitlePage } from '@fava/Components';
import Loader from '@fava/Components/Loader/Loader';
import useQuestionAnswer from '@fava/Hooks/useQuestionAnswer';
import { path_img } from '@fava/Utils';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { FC, ReactNode, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom';

interface ILayoutStudent {
  nbr?: number;
  type?: string;
  title?: string;
  description?: string;
  loading?: boolean;
  succesFinal?: boolean;
  background?: string;
  imgLayout?: string;
  children: ReactNode;
  back?: any;
  isImage?: boolean;
  isFinaleQuiz?: boolean;
  loadingSaveResponse?: boolean;
  clickBackButton?: () => void;
}

const LayoutStudent: FC<ILayoutStudent> = ({
  nbr,
  type,
  imgLayout,
  children,
  background,
  title,
  description,
  loading,
  back,
  isFinaleQuiz,
  isImage,
  succesFinal,
  loadingSaveResponse,
  clickBackButton,
}) => {
  const history = useHistory();
  const { setAnswer, setModalValidation } = useQuestionAnswer();
  useEffect(() => {
    if (isFinaleQuiz) {
      console.log('Quiz final only');
    }
    return () => {
      console.log("Nettoyage de l'effet");
    };
  }, [isFinaleQuiz]);

  const redirect = (to: string) => () => {
    setAnswer([]);
    setModalValidation({
      open: false,
    });
    LocalStorageUtils.removeItem('isQuizRestart');
    LocalStorageUtils.removeItem('review');
    history.push(to);
    if (clickBackButton) {
      clickBackButton();
    }
  };

  const backToHome = () => {
    history.push('/student/programs');
  };

  return (
    <div className="root-student" style={{ backgroundColor: background || '#FFF6EE' }}>
      <div className="menu-student">
        <div className="content-logo" onClick={backToHome}>
          <img alt="logo Fava" src={`${path_img}/logo.svg`} className="logo" draggable={false} />
        </div>

        {back && (
          <div className="content-border">
            <div className="root-center-flex-start" onClick={redirect(back.to)}>
              <BackStudent />
              <div className="text-title-btn">{back.title}</div>
            </div>
          </div>
        )}

        {!loading ? (
          <div className="content-menu-student">
            {isImage && (
              <div className="content-menu-student__image">
                <img
                  src={imgLayout || `${path_img}/images/sexuality.svg`}
                  alt="img content"
                  className="img-content"
                  draggable={true}
                />
              </div>
            )}

            {isFinaleQuiz || succesFinal ? (
              <div className="content-menu-student__title">
                <TitlePage>Quiz final</TitlePage>
              </div>
            ) : (
              <TitlePage>{title || ''}</TitlePage>
            )}

            <div className="margin color-fava-admin description-layout-student">
              {!isFinaleQuiz ? (
                <ReactMarkdown>{description || ''}</ReactMarkdown>
              ) : (
                'Testons tes connaissances sur le sujet'
              )}
            </div>
          </div>
        ) : (
          <div className="position-relative h-100">
            <Loader bg="loader-custom--transparent" type="loader-custom--relative" />
          </div>
        )}
      </div>

      <div className={`content ${isFinaleQuiz || succesFinal ? 'content__final' : ''}`}>
        <div className="content-children">{loading ? <Loader /> : children}</div>
      </div>
    </div>
  );
};

export default LayoutStudent;
