import '../style.scss';
import { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

interface IChipTeacher {
  img: any;
  name: string;
}

const ChipTeacher: FC<IChipTeacher> = ({ img, name }) => {
  return (
    <div className="chip-teacher-admin-class">
      <Container>
        <Row>
          <Col xs={3} className="no-padding">
            <div className="img-content-chip">
              <img src={img} alt={name} />
            </div>
          </Col>

          <Col xs={9}>
            <div>
              <i>
                <h2>Référent</h2>
              </i>
              <i className="text-decoration-underline text-primary cursor-pointer">{name}</i>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChipTeacher;
