import './style.scss';
import useStudent from '@fava/Hooks/useStudent';
import Form from 'react-bootstrap/Form';

const Notation = () => {
  const { handleUpdateStudent, student } = useStudent();

  return (
    <div className="info-details-tabs">
      <div className="width-form-details-student-admin">
        <h1 className="h1-normal">Notation</h1>

        <Form.Group className="mb-3">
          <Form.Label className="color-fava-admin">Période 1 </Form.Label>
          <Form.Control
            placeholder="Ajouter la note "
            name="notePeriod1"
            value={student?.notePeriod1}
            onChange={handleUpdateStudent}
          //     disabled={!!localStorage.getItem('session')}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="color-fava-admin">Période 2</Form.Label>
          <Form.Control
            placeholder="Ajouter la note "
            name="notePeriod2"
            value={student?.notePeriod2}
            onChange={handleUpdateStudent}
          //   disabled={!!localStorage.getItem('session')}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="color-fava-admin">Période 3</Form.Label>
          <Form.Control
            placeholder="Ajouter la note "
            name="notePeriod3"
            value={student?.notePeriod3}
            onChange={handleUpdateStudent}
          //  disabled={!!localStorage.getItem('session')}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="color-fava-admin">Période 4</Form.Label>
          <Form.Control
            placeholder="Ajouter la note "
            name="notePeriod4"
            value={student?.notePeriod4}
            onChange={handleUpdateStudent}
          // disabled={!!localStorage.getItem('session')}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export default Notation;
