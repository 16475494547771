/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IStudent {
  listStudent: any[];
  loading: boolean;
  student: any;
}

const studentSlice: any = createSlice({
  name: 'student',
  initialState: {
    listStudent: [],
    loading: false,
    student: null,
  },
  reducers: {
    setListStudent: (state, action: PayloadAction<any>) => {
      state.listStudent = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setStudent: (state, action: PayloadAction<any>) => {
      state.student = action.payload;
    },

    resetPassword: state => {
      state.student.password1 = '';
      state.student.password2 = '';
      state.student = {
        ...state.student,
      };
    },
  },
});

export const studentAction = studentSlice.actions;

export default studentSlice.reducer;
