import './style.scss';
import { TitlePage } from '@fava/Components';
import LayoutAdmin from '@fava/Layout/Admin';
import { useState } from 'react';
import Following from './Following';
import Program from './Program';
import User from './User';

const Licence = () => {
  const [tab, setTab] = useState(localStorage.getItem('session') ? 1 : 0);

  const onSelect = (tabs: any) => (e: any) => {
    setTab(tabs);
  };

  return (
    <>
      <div className="root-space-between">
        <TitlePage>{localStorage.getItem('session') ? 'Programmes' : 'Licence'}</TitlePage>
      </div>
      {!localStorage.getItem('session') && (
        <div className="margin">
          <div className="container-details-tabs-licence">
            <div className={`container-details-tab ${tab === 0 && 'active'}`} onClick={onSelect(0)}>
              ABONNEMENT
            </div>
            <div className={`container-details-tab ${tab === 1 && 'active'}`} onClick={onSelect(1)}>
              PROGRAMMES
            </div>
            <div className={`container-details-tab ${tab === 2 && 'active'}`} onClick={onSelect(2)}>
              UTILISATEURS
            </div>
          </div>
        </div>
      )}

      <div className="margin">
        {tab === 0 && <Following />}

        {tab === 1 && <Program />}

        {tab === 2 && <User />}
      </div>
    </>
  );
};

export default Licence;
