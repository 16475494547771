import { createSlice } from '@reduxjs/toolkit';
import { fetchCurrentUserChapterContents } from '../Thunks/fetchCurrentChapter';

const currentUserChapterContentsSlice: any = createSlice({
  name: 'currentUserChapterContents',
  initialState: {
    isLoading: false,
    chapter: {},
    currentContent: {},
    nextContentId: null,
    previousContentId: null,
    error: null,
    count: null,
    step: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchCurrentUserChapterContents.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCurrentUserChapterContents.fulfilled, (state, action) => {
      state.isLoading = false;
      state.chapter = action.payload.data.chapter;
      state.currentContent = action.payload.data.currentContent;
      state.count = action.payload.data.count;
      state.step = action.payload.data.step;
      state.nextContentId = action.payload.data.nextContentId;
      state.previousContentId = action.payload.data.previousContentId;
    });
    builder.addCase(fetchCurrentUserChapterContents.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const currentUserChapterContentsAction = currentUserChapterContentsSlice.action;
export default currentUserChapterContentsSlice.reducer;
