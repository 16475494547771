import './style.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { FC, ReactNode, useState } from 'react';

interface IAccordion {
  title: string;
  children: ReactNode;
}

const Accordion: FC<IAccordion> = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e: any) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <div className="card-border-admin p-4 cursor-pointer" onClick={handleClick}>
      <div className="root-space-between-center">
        <div>
          <h1 className="root-contenu-program-teacher-quiz-h1">{title}</h1>
        </div>

        <div>{open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}</div>
      </div>

      {open && <div>{children}</div>}
    </div>
  );
};

export default Accordion;
