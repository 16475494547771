import BtnSimple from '@fava/Button/Simple';
import CardHelp from '@fava/Card/Help';
import Loader from '@fava/Components/Loader/Loader';
import useEtablishement from '@fava/Hooks/useEtablishement';
import useStructure from '@fava/Hooks/useStrucuture';
import { useEffect } from 'react';

const User = () => {
  const { structure } = useStructure();

  const { fetchEtablishementRequest, loading, etablishement } = useEtablishement();

  useEffect(() => {
    fetchEtablishementRequest();
  }, []);

  const contactFava = () => {
    console.log('');
  };

  if (loading) {
    return <Loader bg="loader-custom--transparent" />;
  }

  return (
    <div>
      <div className="margin">
        <h1 className="h1-normal color-fava-admin">Nombre d’utilisateurs</h1>
      </div>

      <div className="root-space-between">
        <div>
          <div>
            <b className="fs-1 color-fava-admin">{etablishement?.nbr || 0}</b> /{' '}
            {structure?.licence?.data?.attributes?.MaxStudent || 0}
          </div>
        </div>

        <div>
          <CardHelp
            title="Ajouter des utilisateurs à votre licence "
            description="L’équipe intim’ed est à votre disponibilité n the other hand, we denounce with righteous indignation"
            backgroundColor="#004F38"
            btn={
              <BtnSimple color="white" borderRadius={false} onClick={contactFava}>
                <div className="text-btn">Contactez intim’ed</div>
              </BtnSimple>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default User;
