/* eslint-disable simple-import-sort/imports */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Drawer } from '@fava/Components';
import useTeacher from '@fava/Hooks/useTeacher';
import BtnSimple from '@fava/Button/Simple';
import { FC, useEffect, useState } from 'react';
import FormEdit from './Form';
import './style.scss';

interface IEditTeacher {
  handleOpen: () => void;
  open: boolean;
}

const EditTeacher: FC<IEditTeacher> = ({ handleOpen, open }) => {
  const [comfirm, setComfirm] = useState(false);

  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    if (!open) {
      setComfirm(false);
    }
  }, [open]);

  const { deleteTeacherRequest, teacherSelected, setListTeacher, listTeacher } = useTeacher();

  const handleDelete = () => {
    setComfirm(!comfirm);
  };

  const deleteTeacher = async () => {
    setLoadingDelete(true);
    try {
      const reponse = await deleteTeacherRequest();
      setListTeacher(listTeacher.filter((x: any) => x.id !== teacherSelected?.id));
    } catch (err: any) {
      console.error('err', err);
      throw err;
    } finally {
      setLoadingDelete(false);
      handleOpen();
    }
  };

  return (
    <Drawer open={open} handclose={handleOpen} btnBack={!comfirm}>
      {comfirm ? (
        <div className="comfirm-modal">
          <div>
            <h1 className="h1-normal color-fava-admin">
              Êtes vous sûr de vouloir supprimer ce professeur ?
            </h1>
            <p className="text-dark">Cette action est irrévocable.</p>
            <div className="root-center-flex-column">
              <div className="midle-width margin-litle">
                <BtnSimple
                  disabled={loadingDelete}
                  backgroundColor="#615EFF"
                  borderRadius={false}
                  onClick={deleteTeacher}
                  loading={loadingDelete}
                  fullWidth={true}>
                  Confirmer
                </BtnSimple>
              </div>
              <div className="midle-width margin-litle">
                <BtnSimple
                  disabled={loadingDelete}
                  color="#615EFF"
                  borderRadius={false}
                  onClick={handleDelete}
                  loading={false}
                  fullWidth={true}>
                  Annuler
                </BtnSimple>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <FormEdit handleDelete={handleDelete} handleOpen={handleOpen} />
      )}
    </Drawer>
  );
};

export default EditTeacher;
