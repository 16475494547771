import { createSlice } from '@reduxjs/toolkit';
import { fetchProgramById } from '../Thunks/fetchPrograms';

const currentProgramSlice: any = createSlice({
  name: 'currentPrograms',
  initialState: {
    isLoading: false,
    data: {},
    meta: {},
    error: null,
    id: 0,
    title: '',
    description: '',
    img: '',
  },
  reducers: {},
  extraReducers(builder) {
    /** fetch program by id */
    builder.addCase(fetchProgramById.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProgramById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    });
    builder.addCase(fetchProgramById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const currentProgramAction = currentProgramSlice.actions;

export default currentProgramSlice.reducer;
