import './style.scss';
import Tag from '@fava/Components/Chip/Tag';
import { DeepArrayObject } from '@fava/Utils';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import CheckIcon from '@material-ui/icons/Check';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FC, FormEvent, useRef, useState } from 'react';

interface IOption {
  id: number;
  [x: string | number | symbol]: unknown;
}

interface IValue {
  id: number;
  [x: string | number | symbol]: unknown;
}

interface IAutocomplete {
  label?: string;
  value: any[];
  list: any[];
  deep: string;
  name: string;
  onChange: (e: FormEvent<HTMLInputElement> | any) => void;
  error: boolean;
}

const AutoComplete: FC<IAutocomplete> = ({ label, name, value, list, deep, error, onChange }) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleDelete = (id: number) => (e: any) => {
    const newListValue = value.filter((x: any) => x.id !== id);

    onChange({
      target: {
        name: name as string,
        value: newListValue,
      },
    });
  };

  const handlechange = (option: IOption) => (e: any) => {
    let listValue: IValue[] = value;

    const verify = listValue.find((x: any) => x.id === option.id);

    if (verify) {
      listValue = value.filter((x: any) => x.id !== option.id);
    } else {
      listValue = [...listValue, { ...option }];
    }

    onChange({
      target: {
        name: name as string,
        value: listValue,
      },
    });

    handleToggle();
  };

  return (
    <div className={error ? 'form-error-input' : ''}>
      <div>
        {label && (
          <label htmlFor="input-wrapper" className="Label color-fava-admin">
            {label}
          </label>
        )}

        <div className="input-wrapper" onClick={handleToggle}>
          {value.map((val: IValue, index: number) => (
            <Tag name={DeepArrayObject(val, deep)} onDelete={handleDelete(val.id)} />
          ))}

          {!localStorage.getItem('session') && (
            <div className="icon-aucomplete">{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
          )}
        </div>

        {open && list.length > 0 && !localStorage.getItem('session') && (
          <div className="list-box">
            <ClickAwayListener onClickAway={handleToggle}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                {(list || []).map((option, index) => {
                  return (
                    <MenuItem className="list-option-autocomplete" onClick={handlechange(option)}>
                      <span>{DeepArrayObject(option, deep)}</span>
                      {value.find((x: any) => x.id === option.id) && <CheckIcon fontSize="small" />}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoComplete;
