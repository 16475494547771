import './style.scss';
import { ReactComponent as ValidatedIcon } from '@fava/Asset/Icon/validationIcon.svg';
import ButtonFava from '@fava/Components/ButtonFava/ButtonFava';
import FavaProgressBar from '@fava/Components/ProgressBar/ProgressBarFava';
import { Status } from '@fava/Utils/constant';
import { FC } from 'react';
import Image from 'react-bootstrap/Image';
import { defaultTheme, doneTheme, failedTheme, inProgressTheme } from './constant';

interface IProps {
  index: number;
  id: string;
  title: string;
  count: number;
  chapterDoneCount: number;
  successRate: number;
  imageUrl: string;
  chapeterRatio?: string;
  doesModuleHaveEndQuizz: boolean;
  status?: string;
  statusNextModule?: boolean;
  handleClick: (moduleId: string) => void;
}

const getThemeByStatus = (status: string, successRate) => {
  if (status === Status.to_start) {
    return defaultTheme;
  }
  if (status === Status.in_progress) {
    return inProgressTheme;
  }

  if (status === Status.failed) {
    return failedTheme;
  }

  if (status === Status.done || status === 'finished') {
    return doneTheme;
  }
  return defaultTheme;
};

const ModuleCardProgramDetails: FC<IProps> = ({
  index,
  id,
  title,
  count,
  chapterDoneCount,
  successRate,
  doesModuleHaveEndQuizz,
  imageUrl,
  status,
  chapeterRatio,
  statusNextModule,
  handleClick,
}) => {
  const moduleNumber = index + 1;

  const infoLabel = `${chapeterRatio} Chapitres `;
  const infoNow = (chapterDoneCount / count) * 100;
  const successLabel = `${successRate} % de réussite`;
  const theme = getThemeByStatus(status, successRate);

  const goToModule = () => {
    if (statusNextModule) {
      handleClick(id);
    }
  };
  const getButtonText = () => {
    if (statusNextModule && status === 'to_start') {
      return 'Démarrer';
    }
    if (statusNextModule && status === 'finished' && doesModuleHaveEndQuizz && successRate <= 70) {
      return 'Repasser';
    }

    if (
      (statusNextModule && status === 'finished' && doesModuleHaveEndQuizz && successRate >= 70) ||
      (statusNextModule && status === 'finished' && !doesModuleHaveEndQuizz)
    ) {
      return 'Revoir';
    }
    if (status === 'resume') {
      return 'Reprendre';
    }
    return 'A venir';
  };

  return (
    <div
      className={`card-horizontal card-horizontal--min-168 card-horizontal--ml ${status === 'resume'
          ? 'bg--fava-green'
          : getButtonText() === 'A venir'
            ? 'bg--disabled'
            : 'bg--white'
        } d-flex flex-column flex-lg-row align-items-center`}>
      <div className="card-horizontal__media">
        <Image src={imageUrl} className="fv-module-image" />
      </div>

      <div className="card-horizontal__details">
        <div>
          <div className="card-horizontal__subtitle text-uppercase text--fava-green">
            Module {moduleNumber}
          </div>
        </div>
        <div>
          <div className="card-horizontal__title card-horizontal__title--mb text--fava-green">
            {title}
          </div>
        </div>
        <div className="d-flex card-horizontal__progress-special-media-wrapper">
          <div className="progress-collection card-horizontal__progress">
            <FavaProgressBar
              key={Math.random()}
              text={infoLabel}
              value={infoNow}
              isSuccesBar={false}
              color="#FFEA00"
            />

            {doesModuleHaveEndQuizz && status === 'finished' && (
              <FavaProgressBar
                key={Math.random()}
                text={successLabel}
                value={successRate}
                isSuccesBar={true}
                color={successRate >= 70 ? '#FD99FF' : '#FF0000'}
              />
            )}
          </div>

          <div>
            {successRate >= 70 && status === 'finished' && (
              <>
                {' '}
                <ValidatedIcon style={{ width: 75, height: 75 }} />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="card-horizontal__link d-flex align-items-center">
        <ButtonFava
          disabled={!statusNextModule}
          type={`button-fava--${getButtonText() === 'Repasser'
              ? 'vivid-tangerine'
              : getButtonText() === 'Démarrer' ||
                getButtonText() === 'Reprendre' ||
                getButtonText() === 'A venir'
                ? 'primary'
                : 'secondary'
            }`}
          text={getButtonText()}
          url={goToModule}
        />
      </div>
    </div>
  );
};

export default ModuleCardProgramDetails;
