import './style.scss';
import start from '@fava/Asset/Icon/start.svg';
import profil from '@fava/Asset/Mock/profil.svg';
import useAccount from '@fava/Hooks/useAccount';
import { FC } from 'react';

const ChipUser: FC = () => {
  const { data } = useAccount();

  return (
    data?.firstname &&
    data?.lastname && (
      <div className="chip-user-contains"> {/* if has avatar add class chip-user-contains__hasavatar */}
        <div className="root-center-flex-start">
          { /* <div className="chip-user-contains__avatar">
            <img
              src={data?.avatar?.url || profil}
              alt="img title ChipUser"
              className="img-title-ChipUser"
            />
    </div> */ }
          <div className="content-element-ChipUser">
            <h3>
              {data?.firstname} {data?.lastname}{' '}
            </h3>
            <div className="content-score-ships">
              <div>{data?.tochka || 0} Pts</div>
              <span>
                <img src={start} alt="img tittle ChipUser" className="img-span-ChipUser" />
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ChipUser;
