import { chapterAction, IChapter } from '@fava/Store/Slices/chapterSlice';
import { RootState } from '@fava/Store/store';
import { fetchChapitreByModules, fetchChapterById } from '@fava/Store/Thunks/fetchChapter';
import { useDispatch, useSelector } from 'react-redux';
import { useThunk } from './useThunk';

const useChapter = () => {
  const chapter: IChapter | any = useSelector((state: RootState) => state.chapter);

  const dispatch = useDispatch();

  const [fetchChapterByProgram, fetchChapterLoading, fetchChapterloadingError] =
    useThunk(fetchChapitreByModules);

  const setChapter = (list: any) => {
    dispatch(chapterAction.setChapter(list));
  };

  const setList = (list: any[]) => {
    dispatch(chapterAction.setList(list));
  };

  const setOneChapter = (Onechapter: any) => {
    dispatch(chapterAction.setOneChapter(Onechapter));
  };

  const setLoading = (bool: any) => {
    dispatch(chapterAction.setLoading(bool));
  };

  const fetchOneChapter = async (id: number) => {
    setOneChapter({});
    setLoading(true);

    try {
      const response = await fetchChapterById(id);
      setOneChapter(response?.data?.attributes || null);
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    ...chapter,
    fetchChapterLoading,
    fetchChapterByProgram,
    fetchChapterloadingError,
    setChapter,
    setOneChapter,
    setLoading,
    setList,
    fetchOneChapter,
  };
};

export default useChapter;
