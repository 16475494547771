import './style.scss';
import React from 'react';
import { StatBarchart } from 'src/Models';

type BarchartData = {
  statisticalData?: StatBarchart[];
};

const BarchartFava: React.FC<BarchartData> = ({ statisticalData }) => {
  const roundNumber = (number: number): number => {
    const decimal = number - Math.floor(number);

    if (decimal > 0.5) {
      return Math.ceil(number);
    }
    return Math.floor(number);
  };

  return (
    /* Bar chart template comparison  */
    <div className="bar-chart-outer-wrap">
      <div className="bar-chart bar-chart--horizontal p-rel">
        {/* Bar chart horizontal groups: comparisons */}
        <div className="bar-chart__horizontal-groups p-rel">
          {/* Bar chart horizontal group */}
          {statisticalData.map(res => (
            <div className="bar-chart__horizontal-group  p-rel">
              <div className="bar-chart__horizontal-bar-title">{res.name}</div>
              <div
                className="bar-chart__horizontal-bar bar-chart__horizontal-bar--first p-rel"
                style={{ width: `${res.value}%` }}>
                <div
                  className={`bar-chart__fill-background p-rel ${
                    res.value > res.comparaisonValue ? 'bar-chart__fill-background--bottom' : ''
                  } `}
                />
                <div
                  className={`bar-chart__digit d-flex align-items-center p-abs ${
                    res.value <= 20 ? 'bar-chart__digit--out' : ''
                  }`}>
                  <span>{`${roundNumber(res.value)}%`}</span>
                </div>
              </div>
              <div
                className="bar-chart__horizontal-bar bar-chart__horizontal-bar--second p-rel"
                style={{ width: `${res.comparaisonValue}%` }}>
                <div
                  className={`bar-chart__fill-background p-rel bar-chart__fill-background--compare ${
                    res.value < res.comparaisonValue
                      ? 'bar-chart__fill-background--compare-top'
                      : ''
                  }`}
                />
                <div
                  className={`bar-chart__digit d-flex align-items-center p-abs ${
                    res.comparaisonValue <= 20 ? 'bar-chart__digit--out' : ''
                  }`}>
                  <span>{`${roundNumber(res.comparaisonValue)}%`}</span>
                </div>
              </div>
              <div className="bar-chart__duration text--cornflower-blue">
                {res.moyenne} en moyenne
              </div>
            </div>
          ))}

          {/* End of bar chart horizontal group Bar chart horizontal group */}
        </div>
        {/* End of bar chart horizontal groups: comparisons */}
      </div>
    </div>
    /*   End of bar chart template comparison */
  );
};

export default BarchartFava;
