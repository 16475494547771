import './style.scss';
import BtnSimple from '@fava/Button/Simple';
import { TitlePage } from '@fava/Components';
import useAccount from '@fava/Hooks/useAccount';
import useEtablishement from '@fava/Hooks/useEtablishement';
import useStrucuture from '@fava/Hooks/useStrucuture';
import { validationForm } from '@fava/Utils/validationForm';
import { useState } from 'react';
import Info from './Info';
import Structure from './Structure';

const MyAccount = () => {
  const account = useAccount();

  const hooksStructure = useStrucuture();

  const { updateAdminEtablissementRequest } = useEtablishement();

  const [structure, setStructure] = useState({});

  const [loading, setLoading] = useState(false);

  const [tab, setTab] = useState(0);

  const onSelect = (tabs: any) => (e: any) => {
    setTab(tabs);
  };

  const validationFormulaire = () => {
    const error = validationForm(
      tab === 0 ? account.data : structure,
      tab === 0
        ? ['firstname', 'lastname', 'email', 'phone', 'function']
        : ['name', 'siret', 'address', 'postalCode', 'city', 'phone']
    );

    if (tab === 0) {
      account.setData({
        ...account.data,
        error: error as any,
      });
    } else {
      setStructure({
        ...structure,
        error: error as any,
      });
    }

    if (!Object.keys(error)?.length) {
      return true;
    }

    return false;
  };

  const handleUpdate = async () => {
    if (!validationFormulaire()) {
      return;
    }

    setLoading(true);

    try {
      let reponse;

      if (tab === 1) {
        if (account.type === 'establishment_admin') {
          reponse = await updateAdminEtablissementRequest(structure);
          return;
        }

        reponse = await hooksStructure.udpateStructureRequest(structure);

        return;
      }

      reponse = await account.updateUserRequest();
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="root-space-between">
        <div>
          <TitlePage>Mon compte</TitlePage>
        </div>

        {!localStorage.getItem('session') && (
          <div>
            <BtnSimple
              disabled={loading}
              loading={loading}
              backgroundColor="#615EFF"
              borderRadius={false}
              onClick={handleUpdate}>
              Enregistrer les modifications
            </BtnSimple>
          </div>
        )}
      </div>

      {account.type !== 'professor_user' && !localStorage.getItem('session') && (
        <div className="margin">
          <div className="container-details-tabs">
            <div
              className={`container-details-tab-admin ${tab === 0 && 'active'}`}
              onClick={onSelect(0)}>
              INFORMATIONS PERSONNELLES
            </div>
            <div
              className={`container-details-tab-admin ${tab === 1 && 'active'}`}
              onClick={onSelect(1)}>
              {account.type === 'establishment_admin' ? 'MON ÉTABLISSEMENT' : 'MA STRUCTURE'}
            </div>
          </div>
        </div>
      )}

      <div className="margin">
        {tab === 0 && <Info />}
        {tab === 1 && <Structure structure={structure} setStructure={setStructure} />}
      </div>
    </>
  );
};

export default MyAccount;
