import AdminModule from '@fava/Common/Card/AdminModule';
import Loader from '@fava/Components/Loader/Loader';
import { useThunk } from '@fava/Hooks/useThunk';
import { fetchModulesByTeacher } from '@fava/Store/Thunks/fetchModules';
import { Grid, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

const ProgramModulesTeacher = () => {
  const { iDteacher, programId }: any = useParams();

  const history = useHistory();

  const modules = useSelector((state: any) => {
    return state.modules;
  });

  const [doFetchModules, isLoadingModules] = useThunk(fetchModulesByTeacher);

  useEffect(() => {
    if (programId) {
      doFetchModules({ programId });
    }
  }, [programId, doFetchModules]);

  const handleCardButtonClick = (id_number: number) => (id: number) => {
    history.push(
      `/teacher/${iDteacher}/programs/${programId}/modules/${id}?module=${id_number + 1}`
    );
  };

  const backProgram = () => {
    history.push(`/teacher/${iDteacher}/programs`);
  };

  if (isLoadingModules) {
    return <Loader bg="loader-custom--transparent" />;
  }

  return (
    <div className="content-program-teacher">
      <div className="root-center-flex-start" onClick={backProgram}>
        <div>
          <IconButton>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </div>
        <div>Retour aux programmes</div>
      </div>

      <div className="root-space-between">
        <div>
          <h1 className="h1-normal color-fava-admin">
            {modules?.data.length ? modules?.data[0]?.title : ''}
          </h1>
        </div>

        {modules?.data.length && (
          <div className="card-border-admin w-50">
            <p className="color-fava-admin">
              {`${modules?.data[0]?.shortDescription}`.substring(0, 350)}{' '}
              {`${modules?.data[0]?.shortDescription}`.length > 350 && '...'}
            </p>
          </div>
        )}
      </div>

      <div className="margin-big">
        {modules?.data[0]?.modules?.length && (
          <Grid container={true} spacing={2}>
            {modules?.data[0]?.modules.map((x: any, index: number) => (
              <Grid item={true} md={6} xs={12} lg={4}>
                <AdminModule
                  id={x?.id}
                  onclick={handleCardButtonClick(index)}
                  img={x?.image}
                  module={index + 1}
                  title={x?.title}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default ProgramModulesTeacher;
