/* eslint-disable simple-import-sort/imports */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import useStructure from '@fava/Hooks/useStrucuture';
import { Table, TitlePage } from '@fava/Components';
import { head } from './constant';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const Structure: FC = () => {
  const { findStructureRequest, setParams } = useStructure();

  const location = useHistory();

  const [listEtablisement, setListEtablisement] = useState([]);

  const [loading, setLoading] = useState(false);

  const findStructure = async () => {
    setLoading(true);
    setListEtablisement([]);
    try {
      const response = await findStructureRequest();
      setListEtablisement(response.data?.[0].attributes?.establishments?.data || []);
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    findStructure();
  }, []);

  const onSelect = (data: any) => {
    setParams('id_etablisement', data?.attributes?.establishment_admins?.data?.[0]?.id || 0);
    setParams('id_etablisement', data?.id || 0);
    location.push('/admin-structure/etablisement/view');
  };

  return (
    <div>
      <div className="root-space-between">
        <div>
          <TitlePage nbr={listEtablisement.length}>Établissements</TitlePage>
        </div>
      </div>

      <div className="container-student">
        <Table head={head as any} body={listEtablisement} onSelect={onSelect} loading={loading} />
      </div>
    </div>
  );
};

export default Structure;
