/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const avatarSlice: any = createSlice({
  name: 'avatar',
  initialState: {
    listAvatar: [],
    loadingList: false,
    loadingUpdate: false,
    error: null,
  },
  reducers: {
    setListAvatar: (state, action: PayloadAction<any>) => {
      state.listAvatar = action.payload;
    },
    setLoadingList: (state, action: PayloadAction<any>) => {
      state.loadingList = action.payload;
    },
    setLoadingUpdate: (state, action: PayloadAction<any>) => {
      state.loadingUpdate = action.payload;
    },
  },
});

export const avatarAction = avatarSlice.actions;

export default avatarSlice.reducer;
