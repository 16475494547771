import './style.scss';
import { FC } from 'react';

interface IProgram {
  id?: number;
  img: any;
  module: number;
  title: string;
  description?: string;
  onclick?: (e: any) => void;
}

const Program: FC<IProgram> = ({ id, img, module, title, description, onclick }) => {
  const handleClick = (e: any) => {
    if (onclick) {
      e.preventDefault();
      e.stopPropagation();
      onclick(id);
    }
  };

  return (
    <div className={`card-program-admin ${onclick && 'cursor-pointer'}`} onClick={handleClick}>
      <div className="root-center-flex-start">
        <div>
          <img src={img} alt={title} className="card-program-img" />
        </div>

        <div className="card-program-content-admin">
          <div className="root-space-between">
            <div>
              <h3>Module {module}</h3>

              <h1>{title}</h1>

              {description && (
                <p>
                  {`${description}`.substring(0, 100)} {`${description}`.length > 100 && '...'}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Program;
