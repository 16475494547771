import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';

const qs = require('qs');

const fetchTeacher = async (id: number, type: string) => {
  if (id) {
    let filter: any = {
      classes: {
        establishment: {
          establishment_admins: {
            id,
          },
        },
      },
    };

    if (type === 'structure_admin') {
      filter = {
        classes: {
          establishment: {
            id,
          },
        },
      };
    }

    const params = qs.stringify(
      {
        filters: filter,
        populate: ['classes'],
        sort: ['createdAt:desc'],
      },
      {
        encodeValuesOnly: true,
      }
    );

    const response: any = await makeRequest(`${apiUrl}/api/professor-users?${params}`, 'GET', {});

    return response.data;
  }
};

const createTeacher = async (paylod: any) => {
  const params = qs.stringify({
    populate: ['classes'],
  });

  const response: any = await makeRequest(`${apiUrl}/api/professor-users?${params}`, 'POST', {
    data: paylod,
  });
  return response.data;
};

const EditTeacher = async (paylod: any) => {
  const params = qs.stringify({
    populate: ['classes'],
  });

  const response: any = await makeRequest(
    `${apiUrl}/api/professor-users/${paylod.id}?${params}`,
    'PUT',
    { data: paylod }
  );
  return response.data;
};

const deleteTeacher = async (id: number) => {
  const response: any = await makeRequest(`${apiUrl}/api/professor-users/${id}`, 'DELETE', {});
  return response.data;
};

export { createTeacher, deleteTeacher, EditTeacher, fetchTeacher };
