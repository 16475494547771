import './style.scss';
import BtnFilter from '@fava/Common/Button/Filter';
import { Table, TitlePage } from '@fava/Components';
import ChipFilter from '@fava/Components/Chip/Tag';
import useAccount from '@fava/Hooks/useAccount';
import useClasse from '@fava/Hooks/useClasse';
import useStudent from '@fava/Hooks/useStudent';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { head, headEdulib } from './constant';

const Student = () => {
  const { fetchStudent, listStudent, loading, setParams } = useStudent();

  const [classSelected, setClassSelected] = useState<any>({});

  const account = useAccount();

  const { fetchListClasse, listClasse } = useClasse();

  const { push } = useHistory();

  useEffect(() => {
    if (account.data?.id) {
      fetchStudent({
        teacher: account.data?.id,
        class: classSelected?.id || null,
      });

      fetchListClasse();
    }
  }, [account.data?.id, classSelected.id]);

  const onSelect = (data: any) => {
    setParams('id_student_info', data.id);
    push(`/teacher/student/info`);
  };

  const handleFilter = (data: any) => {
    setClassSelected(data);
  };

  const handleDelete = () => {
    setClassSelected({});
  };

  return (
    <>
      <div className="root-space-between">
        <div>
          <TitlePage nbr={listStudent.length}>Elèves</TitlePage>
        </div>

        <div className="root-space-between">
          <BtnFilter
            listFilter={listClasse || []}
            option="attributes.name"
            value={0}
            onSelect={handleFilter}>
            Filtrer par classe <FilterListIcon fontSize="large" />
          </BtnFilter>
        </div>
      </div>

      <div className="container-student">
        {classSelected?.attributes?.name && (
          <ChipFilter name={classSelected?.attributes?.name || ''} onDelete={handleDelete} />
        )}
        <Table
          loading={loading}
          head={localStorage.getItem('ean') ? headEdulib : (head as any)}
          body={listStudent}
          onSelect={onSelect}
        />
      </div>
    </>
  );
};

export default Student;
