import './index.scss';
import React from 'react';

interface DynamicTextProps {
  bg?: string;
  type?: string;
}

const Loader: React.FC<DynamicTextProps> = ({ bg = '', type = 'loader-custom--absolute' }) => {
  return (
    <div className={`loader-custom ${bg} ${type} d-flex justify-content-center align-items-center`}>
      {type === 'loader-custom--absolute' ? (
        <div className="blink">
          <img alt="logo Fava" src="/IMG/logo.svg" className="logo" draggable="false" />
        </div>
      ) : (
        <div className="loader" />
      )}
    </div>
  );
};

export default Loader;
