import { DeepArrayObject } from '@fava/Utils';
import moment from 'moment';

export const calculCount = (data: any[]) => {
  return data.length || 0;
};

export const calculnbrStudentCount = (data: any) => {
  let total = 0;

  for (let i = 0; i < data.length; i++) {
    total += DeepArrayObject(data[i], 'attributes.student_users.data').length;
  }

  return total;
};

export const calculnbrTeacher = (data: any) => {
  const listTeacher = [];

  for (let i = 0; i < data.length; i++) {
    listTeacher.push(DeepArrayObject(data[i], 'attributes.professor_user.data.id'));
  }

  const removeDuplicat: any = [...new Set(listTeacher.filter(x => x !== null && x !== ''))];

  return removeDuplicat.length;
};

export const dateLicene = (data: any) => {
  return moment(data).format('DD/MM/YYYY') || '';
};

export const head = [
  {
    type: 'text',
    label: 'Établissements',
    key: 'attributes.name',
  },
  {
    type: 'function',
    label: 'NOMBRE DE classes',
    key: 'attributes.classes.data',
    option: {
      function: calculCount,
    },
  },
  {
    type: 'text',
    label: 'ADMIN Établissement',
    key: 'attributes.name',
  },
  {
    type: 'function',
    label: 'NOMBRE D’UTILISATEURS',
    key: 'attributes.classes.data',
    option: {
      function: calculnbrStudentCount,
    },
  },
  {
    type: 'function',
    label: "Licence valide jusqu'au...",
    key: 'attributes.licence.data.attributes.DateEnd',
    option: {
      function: dateLicene,
    },
  },
];
