/* eslint-disable no-unused-expressions */
import './style.scss';
import Loader from '@fava/Components/Loader/Loader';
import useAccount from '@fava/Hooks/useAccount';
import { path_img } from '@fava/Utils';
import { CircularProgress } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, NavLink, useHistory, useParams } from 'react-router-dom';
import AdminRoutes from './adminRouters';

const IndexTeacher = () => {
  const dispatch = useDispatch();

  const { data, type, findUserRequest, loading } = useAccount();

  const param: any = useParams();

  useEffect(() => {
    findUserRequest();
  }, [dispatch]);

  const { push } = useHistory();

  const logoutFunction = () => {
    !localStorage.getItem('session') ? push('/') : push('/disconnect-sso');
  };

  if (loading) {
    return <Loader bg="loader-custom--transparent" />;
  }

  return (
    <Router>
      <div className="sidebar-content-wrapper d-flex">
        <div className="sidebar d-flex  flex-column justify-content-start align-items-center flex-shrink-0">
          <div className="sidebar__top d-flex flex-column align-items-center">
            <img
              alt="logo Fava"
              src={`${path_img}/logo.svg`}
              className="site-logo"
              draggable={false}
            />
            <ul className="menu-fava menu-fava--mt menu-fava--mb">
              <li>
                <NavLink
                  to={`/teacher/${data?.id}/programs`}
                  className="d-flex align-items-center"
                  activeClassName="active">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path
                        d="M8.93887 3.20117C7.64672 3.20199 6.72768 3.34847 6.07804 3.81C5.58049 4.25272 5.16151 4.88119 5.16804 5.71508V17.8853C5.16804 18.7871 5.40454 19.5367 5.92092 20.0523C6.4373 20.5679 7.19097 20.7986 8.09441 20.7986H17.7508C18.4979 20.7986 19.0323 20.7773 19.4202 20.3927C19.8081 20.0073 19.8326 19.4705 19.8326 18.7299V8.22909C19.8326 8.11043 19.7778 7.99259 19.6886 7.91485C19.3359 7.752 18.948 7.63744 18.7132 7.41077C18.4169 7.11618 18.1567 6.66609 18.1567 5.93779C18.1567 5.31832 18.4178 4.84286 18.7263 4.51062C18.903 4.29623 19.2345 4.10718 19.4137 4.03927C19.6313 4.03599 19.8269 3.83796 19.8269 3.62028C19.8269 3.40261 19.6313 3.20457 19.4137 3.2013L8.93887 3.20117ZM8.93887 4.03914H18.0453C17.6525 4.49332 17.3186 5.13078 17.3186 5.93766C17.3186 6.75353 17.601 7.37302 17.9602 7.81H8.93902C7.72872 7.81082 6.97911 7.60296 6.56924 7.29936C6.15936 6.99576 6.00622 6.59559 6.00622 5.96385C6.00622 5.17417 6.1658 4.77318 6.56268 4.49087C6.95956 4.20856 7.71734 4.03998 8.93917 4.03916L8.93887 4.03914ZM10.5952 11.1619H14.3922C14.8603 11.1226 14.8055 11.5097 14.8047 11.7879C14.8145 12.2298 14.7695 12.4172 14.4184 12.4188H10.5952C10.1295 12.4671 10.1958 12.0629 10.1958 11.7903C10.1844 11.3444 10.2425 11.1635 10.5952 11.1619Z"
                        className="menu-fill"
                      />
                    </g>
                  </svg>
                  Programmes
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/teacher/student"
                  className="d-flex align-items-center"
                  activeClassName="active">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.9776 16.29L11.9776 16.2899L8.11483 14.2673C8.39343 15.4657 9.15616 16.5145 10.248 17.1452L11.9776 16.29ZM11.9776 16.29L11.9854 16.294C12.145 16.3752 12.3207 16.4168 12.498 16.4168H12.4983C12.6757 16.4168 12.8514 16.3751 13.0109 16.294L13.011 16.294L13.0188 16.2899L16.8815 14.2673M11.9776 16.29L16.8815 14.2673M16.8815 14.2673C16.6028 15.4656 15.8401 16.5145 14.7483 17.1452C13.3556 17.9488 11.6409 17.9489 10.2483 17.1454L16.8815 14.2673Z"
                      className="menu-stroke menu-fill"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M5.37341 11.1601L12.4985 14.895L21.4385 10.2112L12.4985 5.52734L3.55469 10.2112L4.62343 10.7699V14.8761L3.87343 16.1248H6.12343L5.37343 14.8761L5.37341 11.1601Z"
                      className="menu-stroke menu-fill"
                      strokeWidth="1.5"
                    />
                  </svg>
                  Élèves
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/teacher/classes"
                  className="d-flex align-items-center"
                  activeClassName="active">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="group">
                      <path
                        d="M7.29034 4.26732C6.88711 5.06113 6.66021 5.95924 6.66021 6.90906C6.66021 7.8588 6.88707 8.75688 7.29026 9.55065C5.88594 9.48789 4.76588 8.32875 4.76588 6.90899C4.76588 5.48919 5.88597 4.33003 7.29034 4.26732ZM15.1456 6.90908C15.1456 8.36904 13.9612 9.55341 12.5013 9.55341C11.0413 9.55341 9.85697 8.36904 9.85697 6.90908C9.85697 5.44911 11.0413 4.26474 12.5013 4.26474C13.9613 4.26474 15.1456 5.44911 15.1456 6.90908ZM20.2367 6.90922C20.2367 8.32901 19.1167 9.48817 17.7123 9.55088C18.1155 8.75707 18.3424 7.85897 18.3424 6.90915C18.3424 5.95941 18.1156 5.06133 17.7124 4.26756C19.1167 4.33032 20.2367 5.48946 20.2367 6.90922ZM20.0392 17.0913C20.0392 16.0507 19.828 15.0581 19.446 14.1552C20.4552 15.1024 21.0847 16.4479 21.0847 17.9401C21.0847 18.7809 20.5069 19.4868 19.7264 19.682C19.9286 19.139 20.0392 18.5518 20.0392 17.9401V17.0913ZM3.91797 17.9401C3.91797 16.448 4.54745 15.1025 5.55662 14.1553C5.17463 15.0582 4.96351 16.0509 4.96351 17.0913V17.9401C4.96351 18.5512 5.07334 19.1384 5.27591 19.682C4.49555 19.4866 3.91797 18.7808 3.91797 17.9401ZM8.16031 17.0912C8.16031 14.6933 10.1035 12.7501 12.5014 12.7501C14.8993 12.7501 16.8425 14.6933 16.8425 17.0912V17.94C16.8425 18.9314 16.0383 19.7356 15.0469 19.7356H9.95586C8.96446 19.7356 8.16031 18.9314 8.16031 17.94V17.0912Z"
                        className="menu-stroke menu-fill"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                  Classes
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/teacher/account"
                  className="d-flex align-items-center"
                  activeClassName="active">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <g>
                        <path
                          d="M12.375 3C10.0214 3 7.76384 3.93495 6.09971 5.5997C4.43493 7.2637 3.5 9.52135 3.5 11.875C3.5 14.2286 4.43495 16.4861 6.09971 18.1502C7.76372 19.815 10.0214 20.7499 12.375 20.7499C14.7286 20.7499 16.9862 19.815 18.6503 18.1502C20.3151 16.4862 21.25 14.2286 21.25 11.875C21.2477 9.52214 20.312 7.266 18.6479 5.60208C16.9839 3.93808 14.7278 3.00238 12.375 3ZM15.9909 16.6139C15.8284 16.6371 15.6636 16.5945 15.5335 16.4947C15.4027 16.3956 15.3176 16.2478 15.2975 16.0853C15.2077 15.4816 14.9167 14.9259 14.4717 14.5087C13.9012 13.9801 13.1528 13.686 12.375 13.6829C11.6792 13.6612 10.9997 13.8934 10.4626 14.3354C9.92543 14.7773 9.56632 15.3988 9.45253 16.0853C9.41073 16.3949 9.14526 16.6247 8.83336 16.6216H8.75055C8.42083 16.5628 8.19716 16.2517 8.24747 15.9204C8.35118 15.2308 8.63521 14.5815 9.0694 14.0366C9.50437 13.4917 10.0748 13.0707 10.7241 12.8161C9.9703 12.3293 9.4649 11.5398 9.33875 10.6513C9.21259 9.76353 9.47806 8.86421 10.0655 8.1863C10.6537 7.50908 11.5066 7.11979 12.4037 7.11979C13.3007 7.11979 14.1536 7.50908 14.7419 8.1863C15.3301 8.86429 15.5955 9.76361 15.4694 10.6513C15.3424 11.5398 14.8378 12.3292 14.084 12.8161C14.5476 13.015 14.9725 13.2944 15.3386 13.6419C15.9724 14.2487 16.3826 15.0513 16.5026 15.9204C16.5475 16.2517 16.3206 16.559 15.9909 16.6139Z"
                          className="menu-fill"
                        />
                        <path
                          d="M14.2326 10.2237C14.2326 11.2492 13.4006 12.0812 12.3751 12.0812C11.3496 12.0812 10.5176 11.2492 10.5176 10.2237C10.5176 9.19744 11.3496 8.36621 12.3751 8.36621C13.4006 8.36621 14.2326 9.19744 14.2326 10.2237Z"
                          className="menu-fill"
                        />
                      </g>
                    </g>
                  </svg>
                  Mon Compte
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="sidebar__bottom d-flex flex-column align-items-center">
            <img
              alt="logo Fava"
              src={`${path_img}/full_logo.svg`}
              className="full-logo"
              draggable={false}
            />
            <div onClick={logoutFunction} className="cursor-pointer">
              Déconnexion
            </div>
          </div>
        </div>
        <div className="section-content flex-grow-1">
          <AdminRoutes />
        </div>
      </div>
    </Router>
  );
};

export default IndexTeacher;
