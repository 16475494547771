/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { FC, useState } from 'react';
import useQuestionAnswer from '@fava/Hooks/useQuestionAnswer';
import './style.scss';

interface IContentQuestion {
  id: number;
  title: string;
  listQuestion: any[];
}

const ContentQuestion: FC<IContentQuestion> = ({ id, title, listQuestion }) => {
  const [clicked, setClicked] = useState(false);

  const { setResponseUser, answer } = useQuestionAnswer();

  const handleClick = (answerClicked: any) => (data: any) => {
    if (!clicked) {
      data.preventDefault();
      data.stopPropagation();
      setResponseUser(id, answerClicked);
      setClicked(!clicked);
    }
  };

  const iSClick = (current_id: number) => {
    const verify_if_exist = answer.find((x: any) => x.id_question === id);

    if (verify_if_exist) {
      const current_answer = verify_if_exist;

      if (!current_answer.listAnswer.find((y: any) => y.id === current_id)) {
        return true;
      }

      return false;
    }

    return false;
  };

  const list = ['A', 'B', 'C', 'D', 'E', 'F'];

  return (
    <div className="root-content-question-response">
      <h2>{title}</h2>

      <div className="root-center-flex">
        <div className="grid-container">
          {listQuestion.map((x: any, index: number) => (
            <div
              className="content-img-question"
              style={{ backgroundImage: `url(${x.urlimg})` }}
              onClick={handleClick(x)}>
              {!iSClick(x.id) ? (
                <span>{list[index]}</span>
              ) : (
                <div className="content-not-click-img-question" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentQuestion;
