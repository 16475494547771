import './style.scss';
import BtnSimple from '@fava/Button/Simple';
import { TitlePage } from '@fava/Components';
import Loader from '@fava/Components/Loader/Loader';
import usePrograms from '@fava/Hooks/usePrograms';
import { useThunk } from '@fava/Hooks/useThunk';
import { programByTeacher } from '@fava/Store/Thunks/fetchPrograms';
import { CircularProgress, Grid } from '@material-ui/core';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const ProgramTeacher = () => {
  const param: any = useParams();

  const { push } = useHistory();

  const { data, setProgram } = usePrograms();

  const [doFetchPrograms, isLoadingPrograms] = useThunk(programByTeacher);

  useEffect(() => {
    setProgram([]);
    doFetchPrograms();
  }, []);

  const redirectModules = (id: number) => (e: any) => {
    push(`/teacher/${param.iDteacher}/programs/${id}`);
  };

  if (isLoadingPrograms) {
    return <Loader bg="loader-custom--transparent" />;
  }

  return (
    <div className="content-program-teacher">
      <div className="root-space-between">
        <div>
          <TitlePage nbr={data.length}>Programmes</TitlePage>
        </div>
      </div>

      {isLoadingPrograms ? (
        <CircularProgress />
      ) : (
        <div className="margin">
          <Grid container={true} spacing={3}>
            {data.map((x: any) => {
              return (
                <Grid item={true} md={6} xs={12} lg={4}>
                  <div className="program-card-teacher">
                    <Grid container={true}>
                      <Grid item={true} xs={5}>
                        <div>
                          <img src={x?.image} alt={x?.title} />
                        </div>
                      </Grid>

                      <Grid item={true} xs={7}>
                        <div className="program-teacher-content">
                          <div>
                            <h1 className="h1-normal color-fava-admin m-0">{x?.title}</h1>
                            <p className="p-normal margin">
                              {`${x?.description}`.substring(0, 70)}{' '}
                              {`${x?.description}`.length > 70 && '...'}
                            </p>
                          </div>

                          <div className="w-100">
                            <BtnSimple
                              fullWidth={true}
                              disabled={false}
                              backgroundColor="#615EFF"
                              borderRadius={false}
                              onClick={redirectModules(x.id)}
                              loading={false}>
                              Consulter
                            </BtnSimple>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default ProgramTeacher;
