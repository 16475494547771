import './style.scss';
import downLoad from '@fava/Asset/Icon/downLoad.svg';
import imgQuizzF from '@fava/Asset/Icon/quiz-final.svg';
import iconValidation, {
  ReactComponent as ValidatedIcon,
} from '@fava/Asset/Icon/validationIcon.svg';
import ButtonFava from '@fava/Components/ButtonFava/ButtonFava';
import ChipTime from '@fava/Components/Chip/ChipTime';
import FavaProgressBar from '@fava/Components/ProgressBar/ProgressBarFava';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { calculateAverageAndRate, updateStatusChapters } from '../../../Services/chapter';

interface IModules {
  id: number;
  module: number;
  title: string;
  success?: any;
  soon?: boolean;
  current?: boolean;
  isValidate?: boolean;
  done?: boolean;
  status?: string;
  hasQuizz?: boolean;
  nextChaptersId?: string;
  time: number;
  ficheStudentUrl?: string;
  answersQuestionId?: string | number;
  answersQuestion?: any;
}

interface RouteParams {
  programId: string;
  moduleId: string;
}

const Modules: FC<IModules> = ({
  id,
  module,
  title,
  success,
  soon,
  current,
  isValidate,
  done,
  time,
  status,
  hasQuizz,
  ficheStudentUrl = '',
  answersQuestionId,
  answersQuestion,
  nextChaptersId,
}) => {
  const history = useHistory();
  const { programId, moduleId } = useParams<RouteParams>();

  const [isLoading, setIsLoading] = useState(false);

  const goToContent = async (from: string, isQuizz?: boolean) => {
    setIsLoading(true);
    try {
      switch (from) {
        case 'start':
          updateStatusChapters(id, 'progress');
          LocalStorageUtils.removeItem('isQuizRestart');
          LocalStorageUtils.removeItem('review');
          break;

        case 'success':
          try {
            if (isQuizz) {
              LocalStorageUtils.setItem('isQuizRestart', true);
            } else {
              LocalStorageUtils.removeItem('isQuizRestart');
              LocalStorageUtils.setItem('review', true);
            }
          } catch (error) {
            console.error("Une erreur s'est produite lors de l'appel API :", error);
          }
          break;
        default:
        // code block
      }

      history.push(
        `/student/programs/${programId}/modules/${moduleId}/chapters/${id}/contents/read`
      );
    } catch (error) {
      console.error('Error while fetching chapter status');
    } finally {
      setIsLoading(false);
    }
  };

  const btn = () => {
    if (current) {
      return (
        <ButtonFava
          type="button-fava--primary "
          text="Reprendre"
          url={() => {
            goToContent('resume');
          }}
        />
      );
    }

    if (soon) {
      return (
        <ButtonFava
          type="button-fava--secondary"
          text="À venir"
          url={() => {
            goToContent('soon');
          }}
        />
      );
    }

    if ((success && !hasQuizz) || (success && hasQuizz && done)) {
      return (
        <ButtonFava
          type="button-fava--secondary"
          text="Revoir"
          url={() => {
            goToContent('success');
          }}
        />
      );
    }

    if (success && hasQuizz && !done) {
      return (
        <ButtonFava
          type="button-fava--vivid-tangerine"
          text="Refaire le quiz"
          url={() => {
            goToContent('success', hasQuizz);
          }}
        />
      );
    }

    return (
      <ButtonFava
        type="button-fava--primary"
        text="Démarrer"
        url={() => {
          goToContent('start');
        }}
      />
    );
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = ficheStudentUrl;
    link.setAttribute('download', `${title}.pdf`);
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getButtonText = () => {
    if (status === 'resume') {
      return 'resume';
    }
    if (soon) {
      return 'A venir';
    }
  };

  return (
    <div
      className={`card-horizontal card-horizontal--min-168  card-horizontal--ml ${status === 'resume'
        ? hasQuizz
          ? 'bg--fava-green card-horizontal--with-artwork'
          : 'bg--fava-green'
        : getButtonText() === 'A venir'
          ? 'bg--disabled'
          : hasQuizz
            ? 'bg--white card-horizontal--with-artwork'
            : 'bg--white'
        } d-flex flex-column flex-lg-row align-items-center`}>
      <div className="card-horizontal__details" key={Math.random()}>
        <div>
          <div className="card-horizontal__subtitle text-uppercase text--fava-green">
            CHAPITRE {module}
          </div>
        </div>
        <div>
          <div className="card-horizontal__title card-horizontal__title--mb text--fava-green">
            {title}
          </div>
        </div>
        <div>
          <div className="d-flex card-horizontal__progress-special-media-wrapper">
            {hasQuizz && success && (
              <div className="d-flex align-items-center mb-3">
                <FavaProgressBar
                  isSuccesBar={true}
                  text={`${calculateAverageAndRate(answersQuestion)?.percentage}% de réussite`}
                  color="white"
                  value={calculateAverageAndRate(answersQuestion)?.percentage}
                />
              </div>
            )}{' '}
            {done && hasQuizz && success && (
              <div className="mb-3">
                <ValidatedIcon style={{ width: 75, height: 75 }} />
              </div>
            )}
          </div>{' '}
        </div>
        <div className="d-flex align-items-center">
          <div className="me-3">
            <ChipTime time={time} />
          </div>
          {success && done && ficheStudentUrl && hasQuizz && (
            <div onClick={handleDownload}>
              <div className="cursor-pointer validation-download download-text d-flex align-items-center">
                <div>Télécharger en PDF</div>
                <div className="ms-3">
                  <img src={downLoad} className="icon-validation-download" alt="icon-validation" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="card-horizontal__link d-flex align-items-center"> {btn()}</div>
    </div>
  );
};

export default Modules;
