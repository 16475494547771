import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchCurrentUserChapter = createAsyncThunk(
  'contents/fetchContents',
  async ({ token }: { token: string; chapterId: number }) => {
    const response: any = await makeRequest(
      `${apiUrl}/api/current-user-chapter-contents`,
      'GET',
      {}
    );
    return response.data;
  }
);

export { fetchCurrentUserChapter };
