import './style.scss';
import React from 'react';

type ButtonProps = {
  type: string;
  text: string;
  disabled?: boolean;
  url?: () => void;
};

const ButtonFava: React.FC<ButtonProps> = ({ type, text, url, disabled }) => {
  return (
    <button className={`button-fava ${type}`} type="button" onClick={url} disabled={disabled}>
      {text}
    </button>
  );
};

export default ButtonFava;
