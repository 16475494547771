import axios from 'axios';
import { LocalStorageUtils } from './localStorage';

export const makeRequest = async (
  url: string,
  method: string,
  body?: any,
  optionalHeaders?: any
) => {
  const options: any = {
    method,
    url,
  };

  if (body) {
    options.data = body;
  }
  const authToken = LocalStorageUtils.getItem('user_token') || '';
  const headers: any = {
    'Content-Type': 'application/json',
  };
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }
  if (optionalHeaders) {
    Object.keys(optionalHeaders).forEach((key: string) => {
      if (optionalHeaders[key]) {
        headers[key] = optionalHeaders[key];
      }
    });
  }

  if (headers) {
    options.headers = headers;
  }

  try {
    return await axios(options);
  } catch (error: any) {
    if (error.message.includes('401') || error.message.includes('403')) {
      window.location.replace('/');
      console.log('401');
    }
    throw error;
  }
};
