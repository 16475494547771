import './style.scss';
import Loader from '@fava/Components/Loader/Loader';
import { FC, ReactNode } from 'react';

interface ILayoutPrograms {
  loading: boolean;
  children: ReactNode;
}

const LayoutProgram: FC<ILayoutPrograms> = ({ children, loading }) => {
  return (
    <div className="bg--sea-shell-peach min-h-100vh-minus-header">
      <div className="container-fluid container-fluid--max-design">
        {loading ? <Loader /> : children}
      </div>
    </div>
  );
};

export default LayoutProgram;
