import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';

const qs = require('qs');

const checkChapter = async ({ token, programId, moduleId, chapterId }: any) => {
  const params = qs.stringify(
    {
      filters: {
        id: +chapterId,
        modules: {
          id: {
            $eq: +moduleId,
          },
        },
      },
      additionnalFilters: {
        chapterId,
        moduleId,
        programId,
      },
      populate: ['modules', 'img', 'contents', 'contents.image', 'contents.video'],
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response: any = await makeRequest(
    `${apiUrl}/api/current-user-chapter-check?${params}`,
    'GET',
    {}
  );
  return response.data;
};

const updateUserChapterContent = async (token, data) => {
  const response: any = await makeRequest(
    `${apiUrl}/api/update-user-chapter-content`,
    'POST',
    data
  );
  return response.data;
};

const updateStatusChapters = async (id, data) => {
  const response: any = await makeRequest(`${apiUrl}/api/chapeters-status/${id}`, 'PUT', {
    status: data,
  });
  return response.data;
};

const updateAnswers = async id => {
  const response: any = await makeRequest(`${apiUrl}/api/answer-questions/${id}`, 'PUT', {
    data: { status: 'oldResponses' },
  });
  return response;
};

const getContentChapters = async id => {
  const response: any = await makeRequest(`${apiUrl}/api/chapters-contents/${id}`, 'GET', {});
  return response;
};

// TO DO TYPIG FUNCTIONS
const roundNumber = (number: number): number => {
  const decimal = number - Math.floor(number);

  if (decimal > 0.5) {
    return Math.ceil(number);
  }
  return Math.floor(number);
};

const calculateAverageAndRate = data => {
  const groupedResponses = {};
  data.forEach(response => {
    const { id } = response;
    groupedResponses[id] = groupedResponses[id] ? [...groupedResponses[id], response] : [response];
  });

  const totalPoints = Object.values(groupedResponses).reduce((points: any, responses: any) => {
    if (responses.length === 1 && responses[0].goodResponse === true) {
      return points + 1;
    }
    if (responses.every(response => response.goodResponse === true)) {
      return points + 1;
    }
    return points;
  }, 0) as any;
  const scoring = totalPoints;
  const avg =
    Object.values(groupedResponses).length > 0
      ? totalPoints / Object.values(groupedResponses).length
      : 0;
  const percentage = avg * 100;
  return {
    totalResponses: Object.values(groupedResponses).length,
    avg,
    scoring,
    percentage: roundNumber(percentage),
  };
};

export {
  calculateAverageAndRate,
  checkChapter,
  getContentChapters,
  updateAnswers,
  updateStatusChapters,
  updateUserChapterContent,
};
