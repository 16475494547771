import './style.scss';
import CheckboxesFaild from '@fava/Asset/Icon/CheckboxesFaild.svg';
import CheckboxesSuccess from '@fava/Asset/Icon/CheckboxesSuccess.svg';
import SimpleBtn from '@fava/Button/Simple';
import ButtonFava from '@fava/Components/ButtonFava/ButtonFava';
import useQuestionAnswer from '@fava/Hooks/useQuestionAnswer';
import { FC } from 'react';

interface IValidation {
  toFinish: string;
  nextUrl: string;
  text?: string;
  type?: string;
  responseQuiz?: string;
  goodResponseList?: any[];

  onNext?: () => void;
}

const Validation: FC<IValidation> = ({
  toFinish,
  nextUrl,
  text,
  type,
  onNext,
  responseQuiz,

  goodResponseList,
}) => {
  const { modalValidation, step, setStep, setModalValidation } = useQuestionAnswer();

  const nextStep = () => {
    setModalValidation({
      open: false,
    });

    onNext();
  };
  const list = ['A', 'B', 'C', 'D', 'E', 'F'];

  return (
    <div
      className={`content-validation-border-bottom-content ${
        modalValidation.open && 'content-view-modal'
      } ${type === 'failed' && 'view-modal-failed'}`}>
      <div className="content-validation-wrap">
        <div className="incorrect-ans">
          <div>
            <img
              src={type === 'failed' ? CheckboxesFaild : CheckboxesSuccess}
              className="icon-validation-answer"
              alt="icon validation answer"
            />
          </div>
          <div className="correct-ans">
            <h2>{type === 'failed' ? 'Réponse incorrecte' : 'Bonne réponse !'}</h2>
          </div>
        </div>

        {type === 'failed' && (
          <div className="response-failed">
            <h3>
              {goodResponseList && goodResponseList.length > 1
                ? 'Les réponses étaient'
                : 'La réponse était'}
            </h3>
            {goodResponseList &&
              goodResponseList.map(res => (
                <div className={res.name ? 'answer' : 'answer answer--no-text'} key={res?.id}>
                  <span>{list[res?.index]}</span> {res?.name}{' '}
                </div>
              ))}
          </div>
        )}

        <div className="response-correct">
          <h3>Pourquoi ?</h3>
          <p>
            {text ||
              "Veuillez ajouter l'explication dans le back office, s'il vous plaît. Nous vous remercions de votre compréhension."}
          </p>
        </div>

        <div className="d-flex justify-content-center">
          <div className="row justify-content-end flex-grow-1">
            <div className="col-9">
              <ButtonFava type="button-fava--primary" text="Continuer" url={nextStep} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Validation;
