import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';

const qs = require('qs');

const findStructure = async (id: number) => {
  const params = qs.stringify(
    {
      filters: {
        structure_admins: {
          id: id as number,
        },
      },
      populate: [
        'structure_admins',
        'establishments.classes.student_users',
        'establishments.licence.programs',
        'establishments.establishment_admins',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/structures?${params}`, 'GET', {});

  return response.data;
};

const udpateStructure = async (data: any) => {
  const response: any = await makeRequest(`${apiUrl}/api/structures/${data.id}`, 'PUT', { data });

  return response.data;
};

const findStructureById = async (id: number) => {
  const params = qs.stringify(
    {
      filters: {
        structure_admins: {
          id: id as number,
        },
      },
      populate: [
        'structure_admins',
        'establishments.classes.student_users',
        'establishments.classes.professor_user',
        'establishments.licence.programs',
        'establishments.establishment_admins',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/structures/${id}?${params}`, 'GET', {});
  return response.data;
};

export { findStructure, findStructureById, udpateStructure };
