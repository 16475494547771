/* eslint-disable simple-import-sort/imports */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import useStructure from '@fava/Hooks/useStrucuture';
import { TitlePage, Table } from '@fava/Components';
import useAccount from '@fava/Hooks/useAccount';
import useTeacher from '@fava/Hooks/useTeacher';
import BtnBack from '@fava/Button/Back';
import { useEffect } from 'react';
import { head } from './constant';
import './style.scss';

const Teacher = () => {
  const { getParams } = useStructure();

  const { fetchTeacherRequest, listTeacher, loading } = useTeacher();

  const adminConneted = useAccount();

  useEffect(() => {
    if (!adminConneted.loading) {
      fetchTeacherRequest(getParams('id_etablisement'));
    }
  }, [adminConneted.loading]);

  return (
    <>
      <BtnBack to="/admin-structure/etablisement/view" />

      <div className="root-space-between">
        <div>
          <TitlePage nbr={listTeacher.length}>Professeurs de l’établissement</TitlePage>
        </div>
      </div>
      <div className="container-group">
        <Table head={head} body={listTeacher} loading={loading} />
      </div>
    </>
  );
};

export default Teacher;
