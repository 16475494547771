import React from 'react';

type StatProps = {
  stat: string;
  text: string;
  img: any;
};

const StatModule: React.FC<StatProps> = ({ stat, text, img }) => {
  return (
    <div className="list__item flex-grow-1 list__item--bordered list__item--cornered  d-flex align-items-start align-items-md-center">
      <div className="list__item-media list__item-media--size-68 list__item-media--padded list__item-media--mr-a  flex-shrink-0">
        <img src={img} alt={text} />
      </div>
      <div>
        <div className="text--cornflower-blue list__item-text list__item-text--bold">{stat}</div>
        <div className="list__item-text text--fava-green">{text}</div>
      </div>
    </div>
  );
};

export default StatModule;
