import './style.scss';
import Line from '@fava/Common/ProgressBar/Line';
import { FC, ReactNode } from 'react';
import { Stack } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/esm/Container';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import ButtonFava from '../ButtonFava/ButtonFava';

interface IProps {
  cardTitle: string;
  cardDescription: string;
  startButtonText?: string;
  previousButtonText?: string;
  nextButtonText?: string;
  isLoading?: boolean;
  isVideo?: boolean;
  children: ReactNode;
  step: number;
  stepCount: number;
  handleNext: () => void;
  handlePrevious: () => void;
}

const ContentCard: FC<IProps> = ({
  cardTitle,
  cardDescription,
  children,
  step,
  stepCount,
  isLoading = false,
  handleNext,
  isVideo,
  handlePrevious,
  startButtonText = "C'est parti !",
  previousButtonText = 'Précédent',
  nextButtonText = 'Suivant',
}) => {
  const now = (step / stepCount) * 100;

  return (
    <Container className="d-flex flex-column align-items-center justify-content-between content-children__container">
      <Line total={stepCount} finish={step} backgroundColor="#FFEA00" />
      <h1 className="content-title">{cardTitle}</h1>
      <div className={isVideo ? 'quiz__video' : 'quiz__image'}>{children}</div>
      <div className="margin content-children__desc" style={{ color: '#004F38' }}>
        <ReactMarkdown rehypePlugins={[rehypeRaw] as any}>{cardDescription || ''}</ReactMarkdown>
      </div>
      <div className="row w-100 justify-content-center">
        {step === 1 ? (
          <div className="col-lg-3">
            <ButtonFava
              type="button-fava--primary"
              text={isLoading ? `${startButtonText}...` : startButtonText}
              url={handleNext}
            />
          </div>
        ) : (
          <div className="col-lg-6 d-flex">
            <div className="ms-4 me-4 w-100">
              <ButtonFava
                type="button-fava--secondary"
                text={isLoading ? `${previousButtonText}...` : previousButtonText}
                url={handlePrevious}
              />
            </div>

            <div className="ms-4 me-4 w-100">
              <ButtonFava
                type="button-fava--primary"
                text={isLoading ? `${nextButtonText}...` : nextButtonText}
                url={handleNext}
              />
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ContentCard;
