import './style.scss';
import Loader from '@fava/Components/Loader/Loader';
import { CircularProgress } from '@material-ui/core';
import { FC, MouseEvent, ReactNode } from 'react';

interface Isimple {
  children: ReactNode;
  onClick?: (data?: MouseEvent<HTMLElement>) => void;
  borderRadius?: boolean;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  littleBtn?: boolean;
}

const simple: FC<Isimple> = ({
  children,
  borderRadius = true,
  color,
  backgroundColor,
  disabled,
  onClick,
  loading,
  fullWidth,
  littleBtn,
}) => {
  return loading ? (
    <Loader bg="loader-custom--transparent" type="loader-custom--relative" />
  ) : (
    <button
      type="button"
      onClick={onClick}
      className={`root-btn-simple
       ${borderRadius ? 'borderRadius' : 'notBorderRadius'}
       ${fullWidth && 'full-width'}
       ${littleBtn && 'little-btn'}
       `}
      style={{
        border: `2px solid ${color || backgroundColor}`,
        color: backgroundColor ? 'white' : color,
        background: backgroundColor || 'white',
      }}
      disabled={disabled}>
      {children}
    </button>
  );
};

export default simple;
