import '../style.scss';
import dynamicColor from '@fava/Asset/Icon/flag-dynamic-color.svg';
import Simple from '@fava/Button/Simple';
import ButtonFava from '@fava/Components/ButtonFava/ButtonFava';
import GoodResponse from '@fava/Components/Score/GoodResponse';
import Win from '@fava/Components/Score/Win';
import useChapter from '@fava/Hooks/useChapter';
import useModule from '@fava/Hooks/useModule';
import usePrograms from '@fava/Hooks/usePrograms';
import useQuestionAnswer from '@fava/Hooks/useQuestionAnswer';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { calculateAverageAndRate, updateStatusChapters } from '../../../../Services/chapter';

interface ISuccessQuestionProps {
  nextChapters?: () => void;
  isRestart?: boolean;
}

const SuccessQuestion: React.FC<ISuccessQuestionProps> = ({ nextChapters, isRestart }) => {
  const history = useHistory();

  const [loadingSave, setloadingSave] = useState(false);

  const [loadingFetchNex, setloadingFetchNex] = useState(false);

  const { programId, moduleId, chapterId }: any = useParams();

  const { answer, setAnswer } = useQuestionAnswer();

  const seenAgainTest = () => {
    history.push(
      `/student/programs/${programId}/modules/${moduleId}/chapters/${chapterId}/contents/${Math.random()}`
    );
    LocalStorageUtils.setItem('review', 'true');
    nextChapters();
    setAnswer([]);
  };

  const getNextId = (arrayObject, currentId) => {
    // Recherche de l'index de l'élément ayant l'ID donné
    const currentIndex = arrayObject.findIndex(item => item.id === parseInt(currentId, 10));

    // Vérification si l'ID donné existe dans le tableau
    if (currentIndex === -1 || currentIndex === arrayObject.length - 1) {
      // L'ID donné n'existe pas ou est le dernier élément du tableau
      return null; // ou renvoyer une valeur par défaut si nécessaire
    }

    return arrayObject[currentIndex + 1]?.id;
  };

  const nextChapter = async () => {
    setAnswer([]);
    history.push(
      `/student/programs/${programId}/modules/${moduleId}/chapters/${getNextId(
        LocalStorageUtils.getItem('current_modules'),
        chapterId
      )}/contents/read`
    );

    updateStatusChapters(
      getNextId(LocalStorageUtils.getItem('current_modules'), chapterId),
      'progress'
    );
    nextChapters();
  };

  return (
    <div className="root-center-flex">
      <div className="success-question">
        <div className="p-2">
          <img src={dynamicColor} alt="flag-dynamic-color" />
        </div>

        <div>
          <div className="p-2 good-response__title">
            <h1 className="h1-normal">Bravo ! Tu as terminé ton chapitre</h1>
          </div>

          <p className="p-normal">
            {/*   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quiss */}
          </p>

          <div className="root-center-flex">
            {!isRestart && <GoodResponse score={calculateAverageAndRate(answer).scoring + 1} />}
            <Win score={calculateAverageAndRate(answer).scoring} />
          </div>

          <div className="row justify-content-center mt-3">
            <div className="d-flex justify-content-center">
              <div className="ms-4 me-4">
                <ButtonFava
                  disabled={loadingFetchNex}
                  type="button-fava--secondary"
                  text=" Revoir le chapitre"
                  url={seenAgainTest}
                />
              </div>
              <div className="ms-4 me-4">
                {getNextId(LocalStorageUtils.getItem('current_modules'), chapterId) && (
                  <ButtonFava
                    disabled={loadingFetchNex}
                    type="button-fava--primary"
                    text="Chapitre suivant"
                    url={nextChapter}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessQuestion;
