import '../style.scss';
import BtnSimple from '@fava/Button/Simple';
import { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

interface ICardSimple {
  id?: number;
  img: any;
  title: string;
  success: number;
  grow: number;
  mean: string;
  onclick?: (e: any) => void;
}

const CardSimple: FC<ICardSimple> = ({ id, img, title, success, grow, mean, onclick }) => {
  const functionClick = () => {
    if (onclick) {
      onclick(id);
    }
  };

  return (
    <div className="card-simple-admin-program-stat">
      <Container className="no-padding">
        <Row>
          <Col lg={5} md={12}>
            <div>
              <img src={img} alt={title} />
            </div>
          </Col>

          <Col lg={7} md={12}>
            <div className="card-simple-content">
              <h1 className="h1-normal color-fava-admin">{title}</h1>

              <p>
                <span>{success} %</span> de réussite
              </p>
              <p>
                <span>{grow} %</span> d&apos;avancement
              </p>
              <p>
                <span>{mean}</span> en moyenne
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="position-relative">
        <BtnSimple
          fullWidth={true}
          disabled={false}
          backgroundColor="#615EFF"
          borderRadius={false}
          onClick={functionClick}
          loading={false}
          littleBtn={true}>
          Consulter
        </BtnSimple>
      </div>
    </div>
  );
};

export default CardSimple;
