import Loader from '@fava/Components/Loader/Loader';
import { useEffect, useState } from 'react';

const Pdc = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ENDPOINT}/api/legal-notice?populate[0]=pdc`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setData(jsonData?.data?.attributes?.pdc?.data?.attributes?.url);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (data) {
    return (
      <div className="main-content">
        {!loading && data && (
          <div
            dangerouslySetInnerHTML={{
              __html: `
                <iframe
                src="${data}?cache=${Math.random()}#toolbar=0&navpanes=1&scrollbar=0"
                frameborder="0"
                style="width: 100%; height:100vh;"
                height="100%"
                width="100%"
                ${window.innerWidth <= 600 ? 'scale="1.2"' : ''}
                >
                </iframe>
                `,
            }}
          />
        )}
      </div>
    );
  }
};

export default Pdc;
