import './style.scss';
import sexualite from '@fava/Asset/Mock/sexualite.svg';
import sexualite1 from '@fava/Asset/Mock/sexualite1.svg';
import { TitlePage } from '@fava/Components';
import useClasse from '@fava/Hooks/useClasse';
import CardSimple from '@fava/Page/dashboard/admin/classes/Card/cardSimple';
import { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const EditClasse: FC = () => {
  const { currentClass } = useClasse();

  return (
    <div>
      <div className="margin">
        <Container>
          <Row className="root-space-between-center">
            <Col md={4} xs={12}>
              <TitlePage>{currentClass?.name || ''}</TitlePage>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="margin">
        <Container>
          <Row>
            <Col md={6} xs={12}>
              <CardSimple img={sexualite} title="Sexualité" success={79} grow={32} mean="2h30" />
            </Col>
            <Col md={6} xs={12}>
              <CardSimple img={sexualite1} title="Les règles" success={79} grow={80} mean="2h30" />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default EditClasse;
