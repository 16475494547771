import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { createAsyncThunk } from '@reduxjs/toolkit';

const qs = require('qs');

const fetchPrograms = createAsyncThunk(
  'programs/fetch',
  async ({ userToken }: { userToken: string }) => {
    const response: any = await makeRequest(`${apiUrl}/api/programs-students`, 'GET', {});

    return response;
  }
);

const fetchProgramById = createAsyncThunk(
  'currentProgram/fetchById',
  async ({ programId }: { programId: number }) => {
    const response: any = await makeRequest(`${apiUrl}/api/programs/${programId}`, 'GET', {});

    return response.data;
  }
);

const fetchProgramByEtablissement = createAsyncThunk(
  'programEtablissement/fetchById',
  async (etablisementId: number) => {
    console.log('etablisementId', etablisementId);
    const response: any = await makeRequest(
      `${apiUrl}/api/programs-by-etablisement/${etablisementId}`,
      'GET',
      {}
    );
    return response.data;
  }
);

const fetchMyProgram = createAsyncThunk('my-program/fetch', async () => {
  const response: any = await makeRequest(`${apiUrl}/api/my-programs`, 'GET', {});

  return response.data;
});

const programByTeacher = createAsyncThunk('program-by-teacher/fetch', async () => {
  const response: any = await makeRequest(`${apiUrl}/api/programs-teacher`, 'GET', {});

  return response;
});

export {
  fetchMyProgram,
  fetchProgramByEtablissement,
  fetchProgramById,
  fetchPrograms,
  programByTeacher,
};
