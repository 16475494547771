import './custom.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import Router from '@fava/Router';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Footer from './Components/footer/Footer';
import useAccount from './Hooks/useAccount';

function App() {
  const account = useAccount();

  return (
    <BrowserRouter>
      <Router />
      <ToastContainer />
      {account?.data?.id && <Footer />}
    </BrowserRouter>
  );
}

export default App;
