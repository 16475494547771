/* eslint-disable prettier/prettier */
import './style.scss';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface DynamicTextProps {
  text: string;
  value: number;
  color?: string;
  isSuccesBar: boolean;
  size?: string;
}

const FavaProgressBar: React.FC<DynamicTextProps> = ({ text, color, value, isSuccesBar, size }) => {

  return (
    <div
      className={`progress-simple ${size ? 'progress-simple--big' : ""} position-relative d-flex align-items-center ${!isSuccesBar
        ? 'progress-simple--border-turbo'
        : isSuccesBar && value >= 70
          ? 'progress-simple--border-rose'
          : isSuccesBar && value < 70
            ? 'progress-simple--border-vivid-tangerine'
            : ''
        } bg--white`}>
      <div
        className={`progress-simple__bg  position-absolute  ${!isSuccesBar
          ? 'bg--turbo'
          : isSuccesBar && value >= 70
            ? 'bg--rose'
            : isSuccesBar && value < 70
              ? 'bg--vivid-tangerine'
              : ''
          }`}
        style={{ width: `${value}%` }}
      />
      <div className="progress-simple__content position-relative d-flex align-items-center justify-content-between flex-grow-1">
        <div className={`progress-simple__text ${size ? 'progress-simple__text progress-simple__text--big' : ""} text--fava-green`} >
          <div className='d-flex align-items-center' dangerouslySetInnerHTML={{ __html: text }} />

        </div>
        {!isSuccesBar && value === 100 && (
          <div className="progress-simple__media">
            <FontAwesomeIcon icon={faCheck} />
          </div>
        )}
        {isSuccesBar && value >= 70 && (
          <div className="progress-simple__media">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="12"
              viewBox="0 0 16 14"
              fill="none">
              <path
                d="M4.36816 13H8.00086H11.6335"
                stroke="#004F38"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M8.00021 9.61346L9.11822 8.68973C10.4794 7.56529 11.2787 5.81213 11.2787 3.95257V1H4.72168V3.95257C4.72168 5.81213 5.52105 7.56529 6.8822 8.68973L8.00021 9.61346Z"
                stroke="#004F38"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.72151 1H1C1 1 1 6.66211 5.32215 6.66211"
                stroke="#004F38"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.2784 1H14.9999C14.9999 1 14.9999 6.66211 10.6777 6.66211"
                stroke="#004F38"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 9.61328V12.9999"
                stroke="#004F38"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default FavaProgressBar;
