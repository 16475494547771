import './style.scss';
import Loader from '@fava/Components/Loader/Loader';
import useAccount from '@fava/Hooks/useAccount';
import useEtablishement from '@fava/Hooks/useEtablishement';
import useStructure from '@fava/Hooks/useStrucuture';
import { FC, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';

interface IStructure {
  structure: any;
  setStructure: (data: any) => void;
}

const Structure: FC<IStructure> = ({ structure, setStructure }) => {
  const { role } = useAccount();

  const { findStructureRequest } = useStructure();

  const { findStructureByAdminEtablishementRequest } = useEtablishement();

  const [loading, setLoading] = useState(false);

  const findStructure = async () => {
    setLoading(true);

    try {
      let response;

      if (role === 'structure_admin') {
        response = await findStructureRequest();
      }

      if (role === 'establishment_admin') {
        const fetch = await findStructureByAdminEtablishementRequest();

        delete fetch.data[0].attributes.structure;

        response = {
          data: [
            {
              attributes: fetch.data[0].attributes,
              id: fetch.data[0].id,
            },
          ],
        };
      }

      setStructure(
        {
          ...response.data[0].attributes,
          id: response.data[0].id,
        } || null
      );
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    findStructure();
  }, []);

  const handleChange = (e: any) => {
    setStructure({
      ...structure,
      [e.target.name]: e.target.value,
    });
  };

  if (loading) {
    return <Loader bg="loader-custom--transparent" type="loader-custom--relative" />;
  }

  return (
    <div className="info-details-tabs">
      <div className="content-form-info">
        <Form.Group className={`mb-3 ${structure?.error?.name && 'form-error-input'}`}>
          <Form.Label className="color-fava-admin">Nom de la structure </Form.Label>
          <Form.Control name="name" value={structure?.name} onChange={handleChange} />
        </Form.Group>

        <Form.Group className={`mb-3 ${structure?.error?.siret && 'form-error-input'}`}>
          <Form.Label className="color-fava-admin">Numéro de SIRET </Form.Label>
          <Form.Control name="siret" value={structure?.siret} onChange={handleChange} />
        </Form.Group>

        <Form.Group className={`mb-3 ${structure?.error?.address && 'form-error-input'}`}>
          <Form.Label className="color-fava-admin">Adresse </Form.Label>
          <Form.Control name="address" value={structure?.address} onChange={handleChange} />
        </Form.Group>

        <Form.Group className={`mb-3 ${structure?.error?.postalCode && 'form-error-input'}`}>
          <Form.Label className="color-fava-admin">Code postal </Form.Label>
          <Form.Control name="postalCode" value={structure?.postalCode} onChange={handleChange} />
        </Form.Group>

        <Form.Group className={`mb-3 ${structure?.error?.city && 'form-error-input'}`}>
          <Form.Label className="color-fava-admin">Ville </Form.Label>
          <Form.Control name="city" value={structure?.city} onChange={handleChange} />
        </Form.Group>

        <Form.Group className={`mb-3 ${structure?.error?.phone && 'form-error-input'}`}>
          <Form.Label className="color-fava-admin">Numéro de téléphone </Form.Label>
          <Form.Control name="phone" value={structure?.phone} onChange={handleChange} />
        </Form.Group>
      </div>
    </div>
  );
};

export default Structure;
