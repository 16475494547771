import './style.scss';
import file from '@fava/Asset/Icon/folder.svg';
import hour from '@fava/Asset/Icon/hour.svg';
import { Drawer } from '@fava/Components';
import BarchartFava from '@fava/Components/BarChartFava/BarchartFava';
import Loader from '@fava/Components/Loader/Loader';
import StatModule from '@fava/Components/Statistic/StatModule';
import StatProgram from '@fava/Components/Statistic/StatProgram';
import useStudent from '@fava/Hooks/useStudent';
import { CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Program } from 'src/Models';
import {
  calculateAverageChapterDuration,
  calculateAveragePercentage,
  calculateAveragePercentageProgram,
  calculateModuleRatio,
  calculateTotalChapterDuration,
  getStatisticStudentAdmin,
  statForBarchart,
} from '../../../../../../Services/user';

interface ProgramState {
  idProg?: number;
  title?: string;
}
const Statistique = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [programData, setProgramData] = useState<Program[]>([]);
  const [programDetails, setProgramDataDetails] = useState<Program[]>([]);
  const { getParams } = useStudent();

  // const { id }: any = useParams();
  const [programState, setProgramState] = useState<ProgramState>({
    idProg: 0,
    title: '',
  });
  const items = [
    { id: 1, stat: '18h45', text: 'temps passés sur le parcours' },

    { id: 2, stat: '4/10', text: 'modules validés' },
  ];

  const openModal = (idProg?: number, title?: string) => {
    setIsOpen(!isOpen);

    if (idProg && title) {
      setProgramState({
        idProg,
        title,
      });

      setProgramDataDetails(
        programData.filter(res => {
          return res.id === idProg;
        })
      );
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      try {
        const data = await getStatisticStudentAdmin(getParams('id_student_info'));
        setLoading(false);
        setProgramData(data);
      } catch (error) {
        setLoading(false);
        console.error('Erreur lors de la récupération des données :', error);
      }
    };

    fetchDataAsync();
  }, [getParams('id_student_info')]);

  return (
    <>
      <Drawer open={isOpen} handclose={openModal} middleWidth={true} isStat={true}>
        <div className="margin-big">
          <div>
            <h1 className="h1-normal h1-title color-fava-admin">
              Statistiques du programme {programState.title}
            </h1>
          </div>
          {loading ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            <BarchartFava statisticalData={statForBarchart(programDetails[0])} />
          )}
        </div>
      </Drawer>
      {loading ? (
        <Loader bg="loader-custom--transparent" />
      ) : (
        <div className="row">
          <div className="col">
            <div className="block">
              <div className="heading-2 text--fava-green ">Statistiques</div>
              <div className="row list">
                {items.map(data => {
                  return (
                    <div className="col-12 col-lg-6 col-xl-4 d-flex">
                      <StatModule
                        key={Math.random()}
                        stat={
                          data.id === 1
                            ? calculateTotalChapterDuration(programData)
                            : calculateModuleRatio(programData)
                        }
                        text={data.text}
                        img={data.id === 1 ? hour : file}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="block">
              <div className="heading-2 text--fava-green ">Programmes</div>
              <div className="row list">
                {programData.length > 0 &&
                  programData.map(data => {
                    return (
                      <div className="col-12 col-lg-6 col d-flex">
                        <StatProgram
                          key={Math.random()}
                          id={data.id}
                          title={data.title}
                          image={data.image}
                          percentageSuccess={calculateAveragePercentageProgram(data.modules) || 0}
                          percentageProgress={calculateAveragePercentage(data.modules) || 0}
                          handclose={openModal}
                          chapterDuration={calculateAverageChapterDuration(data.modules)}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Statistique;
