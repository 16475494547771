import './style.scss';
import FavaProgressBar from '@fava/Components/ProgressBar/ProgressBarFava';
import useAccount from '@fava/Hooks/useAccount';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

interface HeaderUserProps {
  img?: string;
  imgProfile?: string;
  total?: number;
  finish?: number;
  text?: string;
  value?: number;
  valueSucces?: number;
  textSuccess?: string;
  isModule?: boolean;
}

const HeaderUser: FC<HeaderUserProps> = ({
  img,
  text,
  value,
  finish,
  total,
  isModule,
  valueSucces,
  textSuccess,
  imgProfile,
}) => {
  const { data } = useAccount();

  return (
    <div className="d-flex header-user align-items-center justify-content-start">
      <div className="header-user__media header-user__media--min-75">
        <img src={img} alt="img tittle chapitre" className="img-title-chapitre" />
      </div>
      <div>
        <FavaProgressBar
          value={value}
          text={`<span class="progress__unit">${finish}/${total} </span> <span>${text}</span>`}
          color="white"
          isSuccesBar={false}
          size="big"
        />
      </div>

      {isModule && (
        <div className="ms-5">
          <FavaProgressBar
            value={valueSucces}
            text={`<span class="progress__unit">${valueSucces}% </span><span>${textSuccess}</span>`}
            color="white"
            isSuccesBar={true}
            size="big"
          />
        </div>
      )}

      <div className="header-user__right">
        {' '}
        <NavLink to="/student/statistical" activeClassName="none">
          <div className="user-summary d-flex bg--cornflower-blue"> {/* if has avatar add class user-summary__hasavatar */}
            { /* <div className="user-summary__media bg--user-rose">
              <img src={imgProfile} alt="user profile" />
      </div> */ }
            <div className="user-summary__right text--white">
              <div className="user-summary__name">
                {data?.firstname || 'Jane'} {data?.lastname || 'Dae'}{' '}
              </div>
              <div className="user-summary__points">
                <div className="d-flex align-items-center">
                  {data?.tochka || 0} Pts
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none">
                    <path
                      d="M7.04894 1.08037C7.3483 0.15906 8.6517 0.15906 8.95106 1.08037L10.0206 4.37217C10.1545 4.78419 10.5385 5.06315 10.9717 5.06315H14.4329C15.4016 5.06315 15.8044 6.30277 15.0207 6.87217L12.2205 8.90661C11.87 9.16125 11.7234 9.61262 11.8572 10.0246L12.9268 13.3164C13.2261 14.2378 12.1717 15.0039 11.388 14.4345L8.58778 12.4C8.2373 12.1454 7.7627 12.1454 7.41221 12.4L4.61204 14.4345C3.82833 15.0039 2.77385 14.2378 3.0732 13.3164L4.14277 10.0246C4.27665 9.61262 4.12999 9.16125 3.7795 8.90661L0.979333 6.87217C0.195619 6.30277 0.598395 5.06315 1.56712 5.06315H5.02832C5.46154 5.06315 5.8455 4.78419 5.97937 4.37217L7.04894 1.08037Z"
                      fill=""
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>{' '}
        </NavLink>
      </div>
    </div>
  );
};

export default HeaderUser;
