/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchCurrentUserModules,
  fetchModules,
  fetchModulesByTeacher,
} from '../Thunks/fetchModules';

const modulesSlice: any = createSlice({
  name: 'modules',
  initialState: {
    isLoading: false,
    data: [],
    meta: {},
    id: 0,
    title: '',
    description: '',
    img: '',
    error: null,
    count: 0,
    doneModulesCount: 0,
  },
  reducers: {
    setModules: (state, action: PayloadAction<any>) => {
      state.id = action.payload.id;
      state.description = action.payload.description;
      state.title = action.payload.title;
      state.img = action.payload.img;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchModules.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchModules.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    });
    builder.addCase(fetchModules.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(fetchCurrentUserModules.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCurrentUserModules.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.count = action.payload.count;
      state.doneModulesCount = action.payload.doneModulesCount;
      state.meta = action.payload.meta;
    });
    builder.addCase(fetchCurrentUserModules.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    /** fetchModulesByTeacher */
    builder.addCase(fetchModulesByTeacher.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchModulesByTeacher.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.count = action.payload.count;
      state.doneModulesCount = action.payload.doneModulesCount;
      state.meta = action.payload.meta;
    });
    builder.addCase(fetchModulesByTeacher.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const modulesAction = modulesSlice.actions;

export default modulesSlice.reducer;
