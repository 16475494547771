import { accountAction } from '@fava/Store/Slices/accountSlice';
import { RootState } from '@fava/Store/store';
import { findOneUser, udpateUser } from '@fava/Store/Thunks/fetchAccount';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import configRole from '@fava/Utils/role';
import { useDispatch, useSelector } from 'react-redux';

const useAccount = () => {
  const user_infos: string = LocalStorageUtils.getItem('user_infos') || '';

  const parse = user_infos ? JSON.parse(user_infos) : {};

  const data: any = useSelector((state: RootState) => state.account);

  const dispatch = useDispatch();

  const setData = (x: any) => {
    dispatch(accountAction.setData(x));
  };

  const setLoading = (x: boolean) => {
    dispatch(accountAction.setLoading(x));
  };

  const setType = (x: string) => {
    dispatch(accountAction.setType(x));
  };

  const setBlockFetch = (x: boolean) => {
    dispatch(accountAction.setBlockFetch(x));
  };

  const findUserRequest = async (id?: string) => {
    const role = configRole[parse?.role?.name];
    setLoading(true);
    setData({});
    try {
      const response = await findOneUser(id || parse.id);
      setType(configRole[response?.role?.name]);
      setData(response[role]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setBlockFetch(true);
    }
  };

  const updateUserRequest = async () => {
    return udpateUser(data.data, data.type);
  };

  return {
    ...data,
    role: configRole[parse?.role?.name],
    userConnected: parse,
    setData,
    setType,
    findUserRequest,
    setBlockFetch,
    updateUserRequest,
  };
};

export default useAccount;
