import './style.scss';
import img from '@fava/Asset/Icon/iconBack.svg';

const BackStudent = () => {
  return (
    <div className="back-student-root">
      <img src={img} alt="btn back" className="back-student-root-img" draggable={true} />
    </div>
  );
};

export default BackStudent;
