import AdminModule from '@fava/Common/Card/AdminModule';
import { Drawer } from '@fava/Components';
import { fetchModulesByProgram } from '@fava/Store/Thunks/fetchModules';
import { CircularProgress } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';

interface IProgram {
  id: number;
  open: boolean;
  title: string;
  handleOpen: () => void;
}

const Program: FC<IProgram> = ({ open, handleOpen, id, title }) => {
  const [loading, setLoading] = useState(false);

  const [program, setProgram] = useState([]);

  const getModuleByProgram = async () => {
    setLoading(true);
    try {
      const response = await fetchModulesByProgram(id);
      setProgram(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getModuleByProgram();
    }
  }, [open]);

  return (
    <Drawer open={open} handclose={handleOpen}>
      <div className="margin-big">
        <div>
          <h1 className="h1-normal color-fava-admin">
            Détail du programme <span className="text-lowercase"> {title} </span>
          </h1>
        </div>

        {loading ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : (
          program.length &&
          program.map((x: any, index: number) => {
            return (
              <AdminModule
                module={index + 1}
                title={x?.attributes?.title}
                description={x?.attributes?.description}
                img={x?.attributes?.img?.data?.attributes?.url}
              />
            );
          })
        )}
      </div>
    </Drawer>
  );
};

export default Program;
