import { Route, Switch } from 'react-router-dom';
import AdminClasses from '../admin/classes/index';
import MyAccount from '../admin/my-account/index';
import Program from './Program';
import ProgramModulesChapitre from './Program/Chapitre/index';
import ProgramContenu from './Program/Contenu/index';
import ProgramModules from './Program/Modules/index';
import Student from './student';
import StudentDetails from './student/Details/index';

const AdminRoutes = () => (
  <Switch>
    <Route exact={true} path="/teacher/student" component={Student} />
    <Route exact={true} path="/teacher/student/info" component={StudentDetails} />
    <Route exact={true} path="/teacher/account" component={MyAccount} />
    <Route exact={true} path="/teacher/classes" component={AdminClasses} />
    <Route exact={true} path="/teacher/:iDteacher/programs" component={Program} />
    <Route path="/teacher/:iDteacher/programs/:programId" exact={true} component={ProgramModules} />
    <Route
      path="/teacher/:iDteacher/programs/:programId/modules/:moduleId"
      exact={true}
      component={ProgramModulesChapitre}
    />

    <Route
      path="/teacher/:iDteacher/programs/:programId/modules/:moduleId/chapter/:chapterId"
      exact={true}
      component={ProgramContenu}
    />
  </Switch>
);

export default AdminRoutes;
