import './style.scss';
import { DeepArrayObject } from '@fava/Utils';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FC, MouseEvent, ReactNode, useState } from 'react';

interface IBtnFilter {
  children: ReactNode;
  listFilter: any[];
  option: string;
  value: number;
  onSelect: (data: any) => void;
}

const BtnFilter: FC<IBtnFilter> = ({ children, listFilter, option, value, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selected = (data: any) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect(data);
    handleClose();
  };

  return (
    <div>
      <button type="button" className="root-btn-filter" onClick={handleClick}>
        {children}
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className="menu-filter-list filter-class">
        {listFilter.map((x: any) => (
          <MenuItem onClick={selected(x)}> {DeepArrayObject(x, option)}</MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default BtnFilter;
