/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import useQuestionAnswer from '@fava/Hooks/useQuestionAnswer';
import imgQuizz from '@fava/Asset/Icon/logo-quizz.svg';
import ContentQuestionImg from './ContentQuestionImg';
import SwipeableViews from 'react-swipeable-views';
import LayoutStudent from '@fava/Layout/Student';
import Line from '@fava/Common/ProgressBar/Line';
import ContentQuestion from './ContentQuestion';
import { useParams } from 'react-router-dom';
import SimpleBtn from '@fava/Button/Simple';
import Validation from './Validation';
import useChapter from '@fava/Hooks/useChapter';
import useModule from '@fava/Hooks/useModule';
import './style.scss';

interface RouteParams {
  programId: string;
  moduleId: string;
  chapterId: string;
  idQuestion: string;
}

const ChapterQuestion = () => {
  const { step, listQuestionAnswer, totalLengthQuestion, validateAnswer } = useQuestionAnswer();
  const { id } = useModule();
  const { chapterTitle, description, nbrChapter, loading } = useChapter();

  const { programId, moduleId, chapterId, idQuestion } = useParams<RouteParams>();

  return (
    <LayoutStudent
      back={{
        title: 'Chapitres',
        to: `/student/programs/${programId}/modules/${moduleId}`,
      }}
      nbr={nbrChapter}
      type="Chapitre"
      title={chapterTitle}
      description={description}
      background="#E3EEEB"
      loading={loading}>
      <div className="root-center-flex position-relative">
        <img src={imgQuizz} className="img-logo-quizz" alt="img logo quizz" />
        <Line total={totalLengthQuestion} finish={step} backgroundColor="#FFEA00" />
      </div>

      <SwipeableViews axis="x" index={step} enableMouseEvents={false}>
        {listQuestionAnswer.map(() => {
          const findCurrent = listQuestionAnswer.find((x: any) => x.id === +idQuestion);

          if (!findCurrent) {
            return <div />;
          }

          if (findCurrent.imgResponse) {
            return (
              <ContentQuestionImg
                id={findCurrent.id}
                title={findCurrent.title}
                listQuestion={findCurrent.reponsePossible}
              />
            );
          }

          return (
            <ContentQuestion
              id={findCurrent.id}
              title={findCurrent.title}
              img={findCurrent.img}
              listResponse={findCurrent.reponsePossible}
            />
          );
        })}
      </SwipeableViews>

      <div className="root-center-flex">
        <SimpleBtn borderRadius={false} backgroundColor="#615EFF" onClick={validateAnswer}>
          {step > 0 ? 'Valider' : 'C’est parti !'}
        </SimpleBtn>
      </div>

      <Validation
        toFinish={`/student/programs/${programId}/modules/${moduleId}/chapters/${chapterId}/success-question`}
        nextUrl={`/student/programs/${programId}/modules/${moduleId}/chapters/${chapterId}/chapter-question`}
      />
    </LayoutStudent>
  );
};

export default ChapterQuestion;
