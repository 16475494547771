import Loader from '@fava/Components/Loader/Loader';
import React, { useEffect } from 'react';

const LoaderSsoEduilib: React.FC = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token');
    const ean = urlParams.get('ean');

    if (accessToken) {
      const endpoint = `${process.env.REACT_APP_ENDPOINT}/api/sso-edulib`;
      const url = new URL(endpoint);
      url.searchParams.append('access_token', accessToken);
      if (ean) {
        url.searchParams.append('ean', ean);
        localStorage.setItem('ean', ean);
      }

      fetch(url.toString(), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
      })
        .then(response => {
          if (response.redirected) {
            window.location.href = response.url;
          } else if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
          } else {
            return response.json();
          }
        })
        .then(data => {
          if (data) {
            window.location.href = data.ur;
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } else {
      console.error('Access token or EAN is missing in the URL');
    }
  }, []);

  return <Loader />;
};

export default LoaderSsoEduilib;
