import { ProtectedLazyRoute } from '@fava/Common';
import {
  ChapterContent,
  Content,
  ForgotPassword,
  Login,
  ModulesStudent,
  NoFound,
  Programs,
  ProgramStudent,
  QuestionChapitreStudent,
  QuizzStudent,
  SuccessQuestion,
  SuccessQuizz,
} from '@fava/Page';
import IndexAdmin from '@fava/Page/dashboard/admin';
import IndexAdminStructure from '@fava/Page/dashboard/admin-structure';
import IndexStudent from '@fava/Page/dashboard/student';
import IndexTeacher from '@fava/Page/dashboard/teacher';
import DisconnectedGar from '@fava/Page/error-gar/disconnected';
import ErrorGar from '@fava/Page/error-gar/error';
import LoaderSso from '@fava/Page/loader-sso/loader-sso';
import LoaderSsoEduilib from '@fava/Page/loader-sso/sso-eduilib';
import Cgu from '@fava/Page/Public/Cgu';
import Pdc from '@fava/Page/Public/Pdc';
import ResetPassword from '@fava/Page/Public/ResetPassword';
import SetPassword from '@fava/Page/Public/SetPassword';
import Register from '@fava/Page/Student/Register';
import { BrowserRouter as Routers, Switch } from 'react-router-dom';

const Router = () => {
  return (
    <Routers>
      <Switch>
        <ProtectedLazyRoute path="/admin" component={IndexAdmin} />
        <ProtectedLazyRoute path="/admin-structure" component={IndexAdminStructure} />
        <ProtectedLazyRoute path="/student" component={IndexStudent} />
        <ProtectedLazyRoute path="/teacher" component={IndexTeacher} />
        <ProtectedLazyRoute path="/error" component={ErrorGar} />
        <ProtectedLazyRoute path="/gar-login" component={LoaderSso} />
        <ProtectedLazyRoute path="/edulib-login" component={LoaderSso} />
        <ProtectedLazyRoute path="/mentions-legales" component={Cgu} />
        <ProtectedLazyRoute path="/rgpd" component={Pdc} />
        <ProtectedLazyRoute path="/student/program" exact={true} component={ProgramStudent} />
        <ProtectedLazyRoute
          path="/student/programs/:programId/modules/:moduleId"
          exact={true}
          component={ModulesStudent}
        />
        <ProtectedLazyRoute path="/student/my-programs" exact={true} component={Programs} />
        <ProtectedLazyRoute
          path="/student/programs/:programId"
          exact={true}
          component={ProgramStudent}
        />
        <ProtectedLazyRoute
          path="/student/programs/:programId/modules/:moduleId/chapters/:chapterId"
          exact={true}
          component={ChapterContent}
        />
        <ProtectedLazyRoute
          path="/student/programs/:programId/modules/:moduleId/chapters/:chapterId/contents/:contentId"
          exact={true}
          component={Content}
        />
        <ProtectedLazyRoute
          path="/student/programs/:programId/modules/:moduleId/chapters/:chapterId/chapter-question/:idQuestion"
          exact={true}
          component={QuestionChapitreStudent}
        />
        <ProtectedLazyRoute
          path="/student/programs/:programId/modules/:moduleId/chapters/:chapterId/success-question"
          exact={true}
          component={SuccessQuestion}
        />
        <ProtectedLazyRoute
          path="/student/programs/:programId/modules/:moduleId/chapters/:chapterId/quizz/:idQuestion"
          exact={true}
          component={QuizzStudent}
        />
        <ProtectedLazyRoute
          path="/student/programs/:programId/modules/:moduleId/chapters/:chapterId/quizz"
          exact={true}
          component={QuizzStudent}
        />
        <ProtectedLazyRoute path="/student/success-quizz" exact={true} component={SuccessQuizz} />
        <ProtectedLazyRoute path="/register/:classId" exact={true} component={Register} />
        <ProtectedLazyRoute path="/set-password" exact={true} component={SetPassword} />
        <ProtectedLazyRoute path="/reset-password" exact={true} component={ResetPassword} />
        <ProtectedLazyRoute path="/forgot-password" exact={true} component={ForgotPassword} />
        <ProtectedLazyRoute path="/disconnect-sso" exact={true} component={DisconnectedGar} />
        <ProtectedLazyRoute path="/" exact={true} component={Login} />
        <ProtectedLazyRoute path="/auth-edulib" exact={true} component={LoaderSsoEduilib} />
        <ProtectedLazyRoute path="*" exact={true} component={NoFound} />
      </Switch>{' '}
    </Routers>
  );
};

export default Router;
