import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const qs = require('qs');

const fetchModules = createAsyncThunk('modules/fetch', async (programId: number) => {
  const params = qs.stringify(
    {
      filters: {
        program: {
          id: programId,
        },
      },
      populate: ['img'],
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`${apiUrl}/api/modules?${params}&populate=*`);
  return response.data;
});

const fetchCurrentUserModules = createAsyncThunk(
  'modules/fetchCurrentUserModules',
  async ({ programId }: { programId: number }) => {
    const response: any = await makeRequest(
      `${apiUrl}/api/programs-students/${programId}`,
      'GET',
      {}
    );

    LocalStorageUtils.setItem('nextModules', response?.data[0]?.modules);

    return response;
  }
);

const fetchModulesByTeacher = createAsyncThunk(
  'modules/fetchModulesByTeacher',
  async ({ programId }: { programId: number }) => {
    const response: any = await makeRequest(
      `${apiUrl}/api/programs-admin-teacher/${programId}`,
      'GET',
      {}
    );
    return response;
  }
);

const fetchModulesByProgram = async (programId: number) => {
  const params = qs.stringify(
    {
      id_program: programId,
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(
    `${apiUrl}/api/admin-get-modules-program?${params}`,
    'GET',
    {}
  );
  return response.data;
};

export { fetchCurrentUserModules, fetchModules, fetchModulesByProgram, fetchModulesByTeacher };
