/* eslint-disable no-param-reassign */
import downLoad from '@fava/Asset/Icon/downLoad.svg';
import BtnSimple from '@fava/Button/Simple';
import { ChipTime } from '@fava/Components';
import Loader from '@fava/Components/Loader/Loader';
import { fetchChapitreByModulesTeacher } from '@fava/Store/Thunks/fetchChapter';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const ProgramModulesChapitreTeacher = () => {
  const { iDteacher, programId, moduleId }: any = useParams();

  const [loading, setLoading] = useState(false);

  const search = useLocation().search as string;

  const query = Object.fromEntries(new URLSearchParams(search));

  const [module, setModule] = useState<any>({});

  const fetchChapter = async () => {
    setLoading(true);

    try {
      const response = await fetchChapitreByModulesTeacher(moduleId);
      setModule(response.data);
    } catch (error: any) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChapter();
  }, []);

  const history = useHistory();

  const backProgram = (e: any) => {
    e.preventDefault();
    history.push(`/teacher/${iDteacher}/programs/${programId}`);
  };

  const handleDownload = (ficheStudentUrl: string, title: string) => (e: any) => {
    const link = document.createElement('a');
    link.href = ficheStudentUrl;
    link.setAttribute('download', `${title}.pdf`);
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRedirect = (idChapter: number, nbrChapter: number) => (e: any) => {
    history.push(
      `/teacher/${iDteacher}/programs/${programId}/modules/${moduleId}/chapter/${idChapter}?module=${query.module}&chapter=${nbrChapter}`
    );
  };
  function ensureHttps(url: any) {
    if (!url.startsWith('https://')) {
      url = `https://${url}`;
    }
    return url;
  }
  if (loading) {
    return <Loader bg="loader-custom--transparent" />;
  }

  return (
    <div className="content-program-teacher ">
      <div className="root-center-flex-start" onClick={backProgram}>
        <div>
          <IconButton>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </div>

        <div>Retour aux modules</div>
      </div>

      <div className="margin-big">
        <div className="root-space-between">
          <div>
            <h2 className="card-horizontal__subtitle text-uppercase text--fava-green">
              MODULE {query.module || 0}
            </h2>
            <h1 className="h1-normal color-fava-admin">{module?.attributes?.title || ''}</h1>
          </div>

          {module?.attributes?.description && (
            <div className="card-border-admin w-50">
              <p className="color-fava-admin">
                {`${module?.attributes?.description}`.substring(0, 350)}{' '}
                {`${module?.attributes?.description}`.length > 350 && '...'}
              </p>
            </div>
          )}
        </div>

        <div className="margin">
          {module?.attributes?.chapters?.data &&
            module?.attributes?.chapters?.data?.length &&
            module?.attributes?.chapters?.data.map((x: any, index: number) => {
              return (
                <div className="card-horizontal margin">
                  <div className="root-space-between-center">
                    <div className="w-100">
                      <div className="p-normal color-fava-admin root-center-flex-start">
                        <div className="card-horizontal__subtitle text-uppercase text--fava-green">
                          {' '}
                          CHAPITRE {index + 1}{' '}
                        </div>
                        {x?.attributes.chapterDuration && (
                          <div className="margin-left">
                            <ChipTime time={x?.attributes.chapterDuration} />
                          </div>
                        )}
                      </div>

                      <h1 className="h1-normal color-fava-admin">{x?.attributes.title || ''}</h1>

                      <div className="root-space-between-end">
                        <div className="root-center-flex-start">
                          <div className="m-2">
                            {x.attributes.ficheStudent?.data?.[0]?.attributes?.url && (
                              <BtnSimple
                                onClick={handleDownload(
                                  ensureHttps(x.attributes.ficheStudent?.data?.[0]?.attributes?.url) || '',
                                  x?.title
                                )}
                                color="#615EFF">
                                <div>Télécharger le PDF élève</div>
                                <img
                                  src={downLoad}
                                  className="icon-validation-download m-2"
                                  alt="icon-validation"
                                />
                              </BtnSimple>
                            )}
                          </div>

                          <div className="m-2">
                            {x.attributes.ficheTeacher?.data?.[0]?.attributes?.url && (
                              <BtnSimple
                                onClick={handleDownload(
                                  ensureHttps(x.attributes.ficheTeacher?.data?.[0]?.attributes?.url) || '',
                                  x?.title
                                )}
                                color="#615EFF">
                                <div>Télécharger le PDF enseignant</div>
                                <img
                                  src={downLoad}
                                  className="icon-validation-download  m-2"
                                  alt="icon-validation"
                                />
                              </BtnSimple>
                            )}
                          </div>
                        </div>

                        <div className="w-25">
                          <BtnSimple
                            onClick={handleRedirect(x?.id, index + 1)}
                            backgroundColor="#615EFF"
                            borderRadius={false}
                            fullWidth={true}>
                            Consulter
                          </BtnSimple>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ProgramModulesChapitreTeacher;
