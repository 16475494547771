import './style.scss';
import DoneIcon from '@material-ui/icons/Done';
import { FC } from 'react';

interface IProgressBarLine {
  total: number;
  finish: number;
  backgroundColor: string;
}

const ProgressBarLine: FC<IProgressBarLine> = ({ total, finish, backgroundColor }) => {
  const calcul = total !== 0 ? Math.round((finish * 100) / total) : 0;

  return (
    <div className="content-line-Simple">
      <div
        className="content-line-percentage"
        style={{ width: `${calcul}%`, backgroundColor: backgroundColor as string }}
      />

      <div className="content-Simple">
        <div>{total === finish && <DoneIcon fontSize="small" />}</div>
      </div>
    </div>
  );
};

export default ProgressBarLine;
