import { IStudent, studentAction } from '@fava/Store/Slices/studentSlice';
import { RootState } from '@fava/Store/store';
import {
  fetchStudentRequest,
  findUserRequest,
  udpateStudent,
} from '@fava/Store/Thunks/fetchStudent';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { useDispatch, useSelector } from 'react-redux';

const useStudent = () => {
  const student: IStudent | any = useSelector((state: RootState) => state.student);

  const dispatch = useDispatch();

  const setListStudent = (x: any[]) => {
    dispatch(studentAction.setListStudent(x));
  };
  const setLoading = (x: boolean) => {
    dispatch(studentAction.setLoading(x));
  };

  const resetPassword = () => {
    dispatch(studentAction.resetPassword());
  };

  const setStudent = (x: any) => {
    dispatch(studentAction.setStudent(x));
  };

  const handleUpdateStudent = (x: any) => {
    dispatch(
      studentAction.setStudent({
        ...student.student,
        [x.target.name]: x.target.value,
      })
    );
  };

  const handleUpdateComfirmPassword = (x: any) => {
    dispatch(
      studentAction.setStudent({
        ...student.student,
        [x.target.name]: x.target.value,
      })
    );
  };

  const udpateStudentRequest = async () => {
    return udpateStudent(student.student);
  };

  const findUser = async (id: number) => {
    setLoading(true);
    setStudent({});
    try {
      const response = await findUserRequest(id);
      setStudent({
        ...response.data.attributes,
        id: response.data.id,
        class: response.data?.attributes?.class?.data?.id,
        level: response.data?.attributes?.class?.data?.attributes?.level?.data?.id,
      });
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const fetchStudent = async (query: any) => {
    setLoading(true);
    setListStudent([]);
    try {
      const response = await fetchStudentRequest({
        ...query,
      });
      setListStudent(response.data || []);
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const setParams = (name: string, value: any) => {
    const lasted: any = LocalStorageUtils.getItem('param') || {};
    LocalStorageUtils.setItem('param', {
      ...lasted,
      [name]: value,
    });
  };

  const getParams = (name: string) => {
    const lasted = LocalStorageUtils.getItem('param');
    return lasted[name];
  };

  return {
    ...student,
    findUser,
    getParams,
    setParams,
    setLoading,
    fetchStudent,
    setListStudent,
    handleUpdateStudent,
    udpateStudentRequest,
    resetPassword,
    handleUpdateComfirmPassword,
  };
};

export default useStudent;
