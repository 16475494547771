import '../style.scss';
import useAccount from '@fava/Hooks/useAccount';
import { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useHistory, useParams } from 'react-router-dom';

interface IChipStudent {
  id: number;
  img: any;
  nbr: number;
  handleCloseDrawer?: () => void;
}

const ChipStudent: FC<IChipStudent> = ({ id, img, nbr, handleCloseDrawer }) => {
  const params: any = useParams();

  const account = useAccount();

  const history = useHistory();

  const redirect = () => {
    if (account.type === 'professor_user') {
      if (handleCloseDrawer) {
        handleCloseDrawer();
      }
      const typeUlr = account.type === 'professor_user' ? 'teacher' : 'admin';
      history.push(`/${typeUlr}/student?teacher=${params.id_admin}&class=${id}`);
    }
  };

  return (
    <button type="button" className="chip-teacher-admin-class btn-simple" onClick={redirect}>
      <Container>
        <Row>
          <Col xs={3} className="no-padding">
            <div className="img-content-chip">
              <img src={img} alt="img-chip-student" />
            </div>
          </Col>

          <Col xs={9}>
            <div>
              <i>
                <h2>{nbr} élèves</h2>
              </i>
              <i>sont rattachés à cette classe</i>
            </div>
          </Col>
        </Row>
      </Container>
    </button>
  );
};

export default ChipStudent;
