import { useThunk } from '@fava/Hooks/useThunk';
import LayoutStudent from '@fava/Layout/Student';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { checkChapter } from '../../../Services/chapter';

const ChapterContent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    programId,
    moduleId,
    chapterId,
  }: { programId: string; moduleId: string; chapterId: string } = useParams();

  const { push } = useHistory();

  useEffect(() => {
    checkChapterContent();
  }, [programId, moduleId, chapterId]);

  const checkChapterContent = async () => {
    setIsLoading(true);
    try {
      const token = LocalStorageUtils.getItem('user_token') || '';
      const {
        data: { currentContent },
      } = await checkChapter({ token, programId, moduleId, chapterId });
      push(
        `/student/programs/${programId}/modules/${moduleId}/chapters/${chapterId}/contents/${currentContent?.id}`
      );
    } catch (error) {
      console.error('Error while fetching chapter status');
    } finally {
      setIsLoading(false);
    }
  };
  return <LayoutStudent>{isLoading ? 'Loading' : ''}</LayoutStudent>;
};

export default ChapterContent;
