/* eslint-disable simple-import-sort/imports */
import useQuestionAnswer from '@fava/Hooks/useQuestionAnswer';
import flash from '@fava/Asset/Icon/flash-dynamic-color.svg';
import ContentQuestion from '../Question/ContentQuestion';
import { useHistory, useParams } from 'react-router-dom';
import imgQuizz from '@fava/Asset/Icon/logo-quizz.svg';
import SwipeableViews from 'react-swipeable-views';
import LayoutStudent from '@fava/Layout/Student';
import Line from '@fava/Common/ProgressBar/Line';
import Validation from '../Question/Validation';
import useChapter from '@fava/Hooks/useChapter';
import useModule from '@fava/Hooks/useModule';
import SimpleBtn from '@fava/Button/Simple';
import '../Question/style.scss';

const QuizzStudent = () => {
  const history = useHistory();

  const { step, setStep, listQuestionAnswer, totalLengthQuestion, validateAnswer } =
    useQuestionAnswer();

  const { chapterTitle, description, nbrChapter } = useChapter();

  const { id } = useModule();

  const validateQuizz = () => {
    if (params.idQuestion === 'start') {
      history.push(`/student/quizz/${listQuestionAnswer[0].id}`);
      setStep(1);
      return;
    }
    validateAnswer();
  };

  const params: any = useParams();

  return (
    <LayoutStudent
      background="#004F38"
      back={{
        title: 'Chapitres',
        to: `/student/modules/${id}`,
      }}
      nbr={nbrChapter}
      type="Chapitre"
      title={chapterTitle}
      description={description}>
      <div className="root-center-flex position-relative">
        {step > 0 && <img src={imgQuizz} className="img-logo-quizz" alt="img logo quizz" />}
        <Line total={totalLengthQuestion} finish={step} backgroundColor="#FFEA00" />
      </div>

      <SwipeableViews axis="x" index={step} enableMouseEvents={false}>
        <div className="root-center-flex flex-direction-coloum ">
          <div className="p-5">
            <img src={flash} alt="flash quizz icon" />
          </div>

          <div className="p-5">
            <h1 className="h1-normal text-center text-light">
              Prêt à commencer ton évaluation sur ce module ?
            </h1>

            <div className="p-4">
              <p className="p-normal text-center  text-light">
                {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor inci
                ? */}
              </p>
            </div>
          </div>
        </div>

        {listQuestionAnswer.map(() => {
          const findCurrent = listQuestionAnswer.find((x: any) => x.id === +params.idQuestion);

          if (!findCurrent) {
            return <div />;
          }

          return (
            <ContentQuestion
              id={findCurrent.id}
              titleLight={true}
              title={findCurrent.title}
              img={findCurrent.img}
              listResponse={findCurrent.reponsePossible}
            />
          );
        })}
      </SwipeableViews>

      <div className="root-center-flex">
        <SimpleBtn borderRadius={false} backgroundColor="#615EFF" onClick={validateQuizz}>
          {step > 0 ? 'Valider' : 'Démarrer le quiz final'}
        </SimpleBtn>
      </div>

      <Validation toFinish="/student/success-quizz" nextUrl="/student/quizz" />
    </LayoutStudent>
  );
};

export default QuizzStudent;
