import './style.scss';
import Accordion from '@fava/Common/Accordion';
import VideoPlayer from '@fava/Components/ContentCard/VideoPlayer';
import useChapter from '@fava/Hooks/useChapter';
import Image from 'react-bootstrap/Image';
import ReactMarkdown from 'react-markdown';

const ProgramContenuCours = () => {
  const { oneChapter } = useChapter();

  const child = (imageUrl, videoUrl) => {
    return videoUrl ? (
      <div dangerouslySetInnerHTML={{ __html: videoUrl }} />
    ) : imageUrl ? (
      <Image src={imageUrl} className="w-75" />
    ) : (
      ''
    );
  };

  return (
    <div className="root-center-flex">
      <div className="content-list-teacher-admin-width">
        {(oneChapter?.contents?.data || []).length ? (
          <>
            {(oneChapter?.contents?.data || []).map((x: any) => (
              <div className="margin">
                <Accordion title={x.attributes.title}>
                  <div className="d-flex flex-column align-items-center justify-content-between content-children__container ">
                    <div className="quiz__video margin">
                      {child(x.attributes?.image?.data?.attributes?.url, x.attributes.video)}
                    </div>
                    <div className="quiz__video margin">
                      <ReactMarkdown>{x.attributes.description || ''}</ReactMarkdown>
                    </div>
                  </div>
                </Accordion>
              </div>
            ))}
          </>
        ) : (
          <div className="root-center-flex h-50">
            <h2 className="margin-big h1-normal color-fava-admin  ">Aucun contenu à ce chapitre</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgramContenuCours;
