import '../QuestionResponse/Question/style.scss';
import flash from '@fava/Asset/Icon/flash-dynamic-color.svg';
import imgQuizz from '@fava/Asset/Icon/logo-quizz.svg';
import imgQuizzF from '@fava/Asset/Icon/quiz-final.svg';
import Line from '@fava/Common/ProgressBar/Line';
import ButtonFava from '@fava/Components/ButtonFava/ButtonFava';
import ContentCard from '@fava/Components/ContentCard';
import useQuestionAnswer from '@fava/Hooks/useQuestionAnswer';
import LayoutStudent from '@fava/Layout/Student';
import { accountAction } from '@fava/Store/Slices/accountSlice';
import { saveAnswer } from '@fava/Store/Thunks/fetchChapter';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { toast } from 'react-toastify';
import {
  calculateAverageAndRate,
  getContentChapters,
  updateStatusChapters,
} from '../../../Services/chapter';
import ContentQuestion from '../QuestionResponse/Question/ContentQuestion';
import Validation from '../QuestionResponse/Question/Validation';
import SuccessQuestion from '../Success/Question';
import SuccessQuizz from '../Success/Quizz';

const child = (imageUrl, videoUrl) => {
  return videoUrl ? (
    <div dangerouslySetInnerHTML={{ __html: videoUrl }} />
  ) : imageUrl ? (
    <Image src={imageUrl} />
  ) : (
    ''
  );
};

const Content = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPageContent, setPageContent] = useState(0);
  const [currentQuestionIndex, setIndexCurrentQuestion] = useState(0);
  const [isStart, setStartedQuiz] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [loadingSaveResponseQuestion, setLoading] = useState(false);
  const [resultFinal, setShowResultFinal] = useState(!!LocalStorageUtils.getItem('isQuizRestart'));
  const [quiz, setShowQuiz] = useState(false);
  const [hasquiz, setShowHasQuiz] = useState(!!LocalStorageUtils.getItem('isQuizRestart'));
  const [isRestart, setRestart] = useState(
    !!LocalStorageUtils.getItem('isQuizRestart') || !!LocalStorageUtils.getItem('review')
  );
  const dispatch = useDispatch();
  const [showContent, setShowContent] = useState(!LocalStorageUtils.getItem('isQuizRestart'));
  const [contents, setContents] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [chapter, setChapter] = useState({
    title: '',
    id: '',
    description: '',
    quizzEnd: false,
  });
  const { step, setStep, setModalValidation, answer } = useQuestionAnswer();
  const { programId, moduleId, chapterId }: any = useParams();

  useEffect(() => {
    setIsLoading(true);
    getContentChapters(chapterId)
      .then(response => {
        const { data } = response;
        setChapter(data);
        setContents(data?.contents);
        setQuestions(data?.questions);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });
  }, [chapterId]);

  const handleNext = async () => {
    if (currentPageContent + 1 === contents.length) {
      setShowContent(false);
      if (chapter.quizzEnd) {
        setShowHasQuiz(true);
        setShowResultFinal(true);
      } else {
        setShowQuiz(true);
      }
    } else {
      setPageContent(currentPageContent + 1);
    }
  };

  const handlePrevious = async () => {
    setPageContent(currentPageContent - 1);
  };

  const validateQuizz = () => {
    if (questions.length > 0) {
      setStartedQuiz(false);

      const checkingAnswer = answer.filter(res => {
        return res.id === questions[currentQuestionIndex].id;
      });
      if (checkingAnswer.length === 0 && step > 0) {
        toast.warning('Veuillez sélectionner la réponse en cliquant dessus.');
      }
      if (step < questions.length) {
        const stepper = step;
        if (isStart) {
          setStep(stepper + 1);
          setIndexCurrentQuestion(stepper);
        }
        if (checkingAnswer.length > 0) {
          setModalValidation({
            open: true,
          });
        }
      } else if (checkingAnswer.length > 0) {
        setModalValidation({
          open: true,
        });
      }
    } else {
      toast.warning(
        "Désolé, il semble que cette partie ne comporte pas de questions. Veuillez contacter l'administrateur."
      );
    }
  };

  const checkAllGoodResponses = (objects: any): boolean => {
    for (let i = 0; i < objects.length; i++) {
      if (!objects[i].goodResponse) {
        return false;
      }
    }
    return true;
  };

  const nextChapters = (): void => {
    setStartedQuiz(true);
    setShowContent(true);
    setShowResult(false);
    setShowQuiz(false);
    setRestart(false);
    setShowResultFinal(false);
    setStep(0);
    setPageContent(0);
    setIndexCurrentQuestion(0);
  };

  const handleNextStep = (): void => {
    const stepper = step;

    if (step < questions.length) {
      setStep(stepper + 1);
      setIndexCurrentQuestion(stepper);
    } else {
      setLoading(true);
      setShowResult(true);
      setRestart(
        !!LocalStorageUtils.getItem('isQuizRestart') || !!LocalStorageUtils.getItem('review')
      );
      const payload = {
        module: moduleId,
        program: programId,
        chapitre: chapterId,
        status: 'currentResponses',
        reponse_user: answer,
        isRestart:
          !!LocalStorageUtils.getItem('isQuizRestart') || !!LocalStorageUtils.getItem('review'),
      };

      if (calculateAverageAndRate(answer).avg >= 70) {
        updateStatusChapters(chapter?.id, 'done');
      } else {
        updateStatusChapters(chapter?.id, 'failed');
      }
      saveAnswer(payload as any)
        .then(res => {
          setLoading(false);
          console.log(res);
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
        });
      LocalStorageUtils.removeItem('isQuizRestart');
      LocalStorageUtils.removeItem('review');
      if (!payload.isRestart) {
        dispatch(accountAction.updateScoring(calculateAverageAndRate(answer).scoring + 1));
      }
    }
  };

  const statusValidation = (): string => {
    if (answer.length > 0) {
      if (
        !answer.filter(res => {
          return res?.id === questions[currentQuestionIndex]?.id;
        })[0]?.isMultiple &&
        answer.filter(res => {
          return res?.id === questions[currentQuestionIndex]?.id;
        }).length > 1
      ) {
        return 'failed';
      }

      if (
        answer.filter(res => {
          return res?.id === questions[currentQuestionIndex]?.id;
        })[0]?.isMultiple &&
        answer.filter(res => {
          return res?.id === questions[currentQuestionIndex]?.id;
        }).length <= 1
      ) {
        return 'failed';
      }

      if (
        !answer.filter(res => {
          return res?.id === questions[currentQuestionIndex]?.id;
        })[0]?.isMultiple &&
        answer.filter(res => {
          return res?.id === questions[currentQuestionIndex]?.id;
        }).length === 1 &&
        answer.filter(res => {
          return res?.id === questions[currentQuestionIndex]?.id;
        })[0]?.goodResponse
      ) {
        return 'best';
      }

      if (
        !answer.filter(res => {
          return res?.id === questions[currentQuestionIndex]?.id;
        })[0]?.isMultiple &&
        answer.length === 1 &&
        !answer.filter(res => {
          return res?.id === questions[currentQuestionIndex]?.id;
        })[0]?.goodResponse
      ) {
        return 'failed';
      }

      if (
        answer.filter(res => {
          return res?.id === questions[currentQuestionIndex]?.id;
        })[0]?.isMultiple &&
        answer.length > 0 &&
        checkAllGoodResponses(
          answer.filter(res => {
            return res?.id === questions[currentQuestionIndex]?.id;
          })
        )
      ) {
        return 'best';
      }
      return 'failed';
    }
  };

  return (
    <LayoutStudent
      back={{
        title: 'Chapitres',
        to: `/student/programs/${programId}/modules/${moduleId}`,
      }}
      title={chapter?.title || ''}
      description={
        chapter?.description ||
        'Pourriez-vous ajouter des descriptions dans le back office pour obtenir un rendu similaire à la maquette ? Merci de votre compréhension.'
      }
      loading={isLoading}
      loadingSaveResponse={loadingSaveResponseQuestion}
      isFinaleQuiz={hasquiz && !showResult}
      succesFinal={resultFinal}
      clickBackButton={nextChapters}>
      {showContent && !showResult && (
        <ContentCard
          isLoading={isLoading}
          cardTitle={contents[currentPageContent]?.title}
          cardDescription={contents[currentPageContent]?.description ?? ''}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          step={currentPageContent + 1}
          stepCount={contents.length}
          isVideo={contents[currentPageContent]?.video}
          nextButtonText={
            currentPageContent + 1 === contents.length ? 'Commencer le quiz ' : 'Suivant'
          }>
          {child(contents[currentPageContent]?.image, contents[currentPageContent]?.video)}
        </ContentCard>
      )}
      {quiz && !showResult && (
        <>
          <div className="root-center-flex position-relative quiz--height">
            {currentQuestionIndex === 0 && (
              <img src={imgQuizz} className="img-logo-quizz" alt="img logo quizz" />
            )}
            <Line total={questions.length} finish={step} backgroundColor="#FFEA00" />
          </div>
          <SwipeableViews axis="x" index={step} enableMouseEvents={false}>
            <div className="root-center-flex flex-direction-coloum ">
              <div className="p-5">
                <img src={flash} alt="flash quizz icon" />
              </div>

              <div className="p-5">
                <h1 className="h1-normal text-center">
                  Prêt à commencer ton évaluation sur ce module ?
                </h1>

                <div className="p-4">
                  <p className="p-normal text-center">
                    {/*   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    inci ? */}
                  </p>
                </div>
              </div>

              {step === 0 && (
                <div className="d-flex justify-content-center">
                  <div>
                    <ButtonFava
                      type="button-fava--primary"
                      text={step > 0 ? 'Valider' : 'Démarrer le quiz question'}
                      url={validateQuizz}
                    />
                  </div>
                </div>
              )}
            </div>

            {questions.map(data => {
              return (
                <ContentQuestion
                  id={data?.id}
                  img={data.img}
                  title={data?.title}
                  listResponse={data?.responses}
                  titleLight={true}
                  resultDescription={data?.resultDescription}
                  key={data?.title}
                />
              );
            })}
          </SwipeableViews>
          {step > 0 && (
            <div className="row w-100 justify-content-center">
              <div className="col-lg-3">
                <ButtonFava type="button-fava--primary" text="Valider" url={validateQuizz} />
              </div>
            </div>
          )}
        </>
      )}

      <Validation
        toFinish="/student/success-quizz"
        nextUrl="/student/quizz"
        responseQuiz={
          answer.length > 0 &&
          answer.filter(res => {
            return res?.id === questions[currentQuestionIndex]?.id;
          })[0]?.responseText
        }
        goodResponseList={
          answer.length > 0 &&
          answer.filter(res => {
            return res.id === questions[currentQuestionIndex]?.id;
          })[0]?.goodResponseList
        }
        text={
          answer.length > 0
            ? answer.filter(res => {
              return res?.id === questions[currentQuestionIndex]?.id;
            })[0]?.resultDescription
            : ''
        }
        type={statusValidation()}
        onNext={handleNextStep}
      />

      {hasquiz && !showResult && (
        <>
          {' '}
          <div className="content-quiz-final">
            <div className="root-center-flex position-relative quiz__final">
              {step > 0 && <img src={imgQuizzF} className="img-logo-quizz" alt="img logo quizz" />}
              <Line total={questions.length} finish={step} backgroundColor="#FFEA00" />
            </div>
            <SwipeableViews axis="x" index={step} enableMouseEvents={false}>
              <div className="root-center-flex flex-direction-coloum ">
                <div className="p-5">
                  <img src={flash} alt="flash quizz icon" />
                </div>

                <div className="p-5">
                  <h1 className="h1-normal text-center content-quiz-final__title">
                    Prêt à commencer ton évaluation finale de ce module ?
                  </h1>

                  <div className="p-4">
                    <p className="p-normal text-center content-quiz-final__desc">
                      {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      inci ? */}
                    </p>
                  </div>
                </div>

                {step === 0 && (
                  <div className="d-flex justify-content-center">
                    <div>
                      <ButtonFava
                        type="button-fava--primary"
                        text={step > 0 ? 'Valider' : 'Démarrer le quiz final'}
                        url={validateQuizz}
                      />
                    </div>
                  </div>
                )}
              </div>
              {questions.map(data => {
                return (
                  <ContentQuestion
                    id={data?.id}
                    img={data.img}
                    title={data?.title}
                    listResponse={data?.responses}
                    titleLight={true}
                    resultDescription={data?.resultDescription}
                    key={data?.title}
                  />
                );
              })}
            </SwipeableViews>

            {step > 0 && (
              <div className="row w-100 justify-content-center">
                <div className="col-lg-3">
                  <ButtonFava type="button-fava--primary" text="Valider" url={validateQuizz} />
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {showResult && !hasquiz && (
        <SuccessQuestion nextChapters={nextChapters} isRestart={isRestart} />
      )}
      {showResult && hasquiz && <SuccessQuizz nextChapters={nextChapters} isRestart={isRestart} />}
    </LayoutStudent>
  );
};

export default Content;
