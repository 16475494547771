import { FC, ReactNode } from 'react';

interface IPublicLayout {
  children: ReactNode;
}

const PublicLayout: FC<IPublicLayout> = ({ children }) => {
  return (
    <div
      style={{
        backgroundColor: '#004F38',
        minHeight: '100vh',
      }}
      className="d-flex justify-content-center align-items-center">
      <div style={{ position: 'absolute', top: 0, left: 0, marginLeft: '10%' }}>
        <svg
          width="338"
          height="154"
          viewBox="0 0 338 154"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M338 -132.621C338 -39.0307 292.578 154 169 154C75.6186 154 0 -39.0307 0 -132.621C0 -226.212 75.6186 -302 169 -302C262.381 -302 338 -226.212 338 -132.621Z"
            fill="#FFEA00"
          />
        </svg>
      </div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translateY(-50%)',
          marginRight: '20px',
        }}>
        <svg
          width="299"
          height="573"
          viewBox="0 0 299 573"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M282.26 393.511C245.916 381.413 245.916 422.546 192.833 422.546C156.49 422.546 143.274 393.511 143.274 355.018V227C143.274 78.5263 78.077 0 -33.1552 0C-128.749 0 -184.255 55.6503 -214.43 105.142C-241.082 36.2937 -295.487 0 -371.697 0C-405.177 0 -433.371 6.8188 -457.599 17.8169C-468.172 22.656 -475 33.2142 -475 44.8722V115.04C-475 119.219 -472.577 118.999 -471.256 118.779C-469.273 118.339 -467.071 118.119 -464.868 118.119C-418.833 118.119 -405.618 167.611 -405.618 235.139V465.659C-405.618 528.348 -432.269 525.928 -432.269 551.444C-432.269 558.703 -426.322 563.542 -416.631 563.542H-183.374C-175.004 563.542 -167.735 558.703 -167.735 551.444C-167.735 526.148 -194.387 528.568 -194.387 465.659V241.078C-194.387 161.452 -171.479 117.899 -127.868 117.899C-81.8329 117.899 -67.5159 167.391 -67.5159 234.919V371.295C-67.5159 489.635 -4.74136 573 113.759 573C273.229 573 331.378 408.909 281.819 393.071L282.26 393.511Z"
            fill="#615EFF"
          />
        </svg>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '20px',
          left: '20px',
          marginBottom: '40px',
          marginLeft: '40px',
        }}>
        <svg
          width="149"
          height="149"
          viewBox="0 0 149 149"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M74.5 149C115.645 149 149 115.645 149 74.5C149 33.3548 115.645 0 74.5 0C33.3548 0 0 33.3548 0 74.5C0 115.645 33.3548 149 74.5 149Z"
            fill="#FD99FF"
          />
        </svg>
      </div>

      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        <svg
          width="458"
          height="234"
          viewBox="0 0 458 234"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M281 234C436.192 234 562 108.192 562 -47C562 -202.192 436.192 -328 281 -328C125.808 -328 0 -202.192 0 -47C0 108.192 125.808 234 281 234Z"
            fill="#615EFF"
          />
        </svg>
      </div>

      <div
        style={{
          position: 'absolute',
          bottom: '60%',
          right: 0,
          transform: 'translateY',
          zIndex: 9,
        }}>
        <svg
          width="96"
          height="480"
          viewBox="0 0 96 480"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ position: 'absolute', bottom: '50%', right: '0', transform: 'translateY(50%)' }}>
          <path
            d="M202.35 234.284V25.5648C202.35 7.06601 192.055 -3.21109 166.41 0.899753C137.583 5.94488 24.7088 23.5094 13.2903 25.5648C6.1772 26.4991 0 32.6654 0 39.9528C0 59.3859 22.6497 55.275 22.6497 108.716V396.849C22.6497 450.103 0 448.048 0 469.723C0 475.889 6.1772 480 13.2903 480H211.71C219.946 480 225 475.889 225 469.723C225 448.234 202.35 450.29 202.35 396.849V234.284Z"
            fill="#FD99FF"
          />
        </svg>
      </div>
      <div style={{ zIndex: 1 }}>
        <svg
          width="240"
          height="240"
          viewBox="0 0 240 240"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: 'absolute',
            top: '70%',
            left: '90%',
            transform: 'translate(-50%, -50%)',
          }}>
          <path
            d="M120 240C186.274 240 240 186.274 240 120C240 53.7258 186.274 0 120 0C53.7258 0 0 53.7258 0 120C0 186.274 53.7258 240 120 240Z"
            fill="#FFEA00"
          />
        </svg>
      </div>
      {children}
    </div>
  );
};

export default PublicLayout;
