import '../style.scss';
import { FC, ReactNode } from 'react';

interface IProviderTimeLine {
  children: ReactNode;
}

const ProviderTimeLine: FC<IProviderTimeLine> = ({ children }) => {
  return (
    <div className="uk-container uk-padding">
      <div className="uk-timeline">{children}</div>
    </div>
  );
};

export default ProviderTimeLine;
