import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';

const qs = require('qs');

const fetchListAvatar = async () => {
  const params = qs.stringify(
    {
      populate: ['image'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/avatars?${params}`, 'GET', {});
  return response.data;
};

const udpateAvatar = async (id: number, idAvatar: any) => {
  const response: any = await makeRequest(`${apiUrl}/api/update-avatar-user/${id}`, 'PUT', {
    avatar: idAvatar,
  });
  return response.data;
};

export { fetchListAvatar, udpateAvatar };
