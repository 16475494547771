/* eslint-disable simple-import-sort/imports */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { TitlePage, Table } from '@fava/Components';
import useAccount from '@fava/Hooks/useAccount';
import useTeacher from '@fava/Hooks/useTeacher';
import LayoutAdmin from '@fava/Layout/Admin';
import BtnSimple from '@fava/Button/Simple';
import { useEffect, useState } from 'react';
import { head, headNoEmail } from './constant';
import EditTeacher from './Edit';
import './style.scss';

const Teacher = () => {
  const { setTeacherSelected, fetchTeacherRequest, listTeacher, loading } = useTeacher();

  const adminConneted = useAccount();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!adminConneted.loading) {
      fetchTeacherRequest();
    }
  }, [adminConneted.loading]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const createTeacher = () => {
    setTeacherSelected({
      firstname: '',
      lastname: '',
      email: '',
      function: '',
      classes: [],
    });
    setOpen(true);
  };

  const onSelect = (e: any) => {
    setTeacherSelected({
      ...e.attributes,
      id: e.id,
      classes: e.attributes?.classes?.data || [],
    });
    setOpen(true);
  };

  return (
    <>
      <div className="root-space-between">
        <div>
          <TitlePage nbr={listTeacher.length}>Professeurs</TitlePage>
        </div>

        {!localStorage.getItem('session') && (
          <div>
            <BtnSimple backgroundColor="#615EFF" borderRadius={false} onClick={createTeacher}>
              Créer un professeur
            </BtnSimple>
          </div>
        )}
      </div>
      <div className="container-group">
        <Table head={!localStorage.getItem('session') ? head : headNoEmail} body={listTeacher} onSelect={onSelect} loading={loading} />
      </div>
      <EditTeacher open={open} handleOpen={handleOpen} />
    </>
  );
};

export default Teacher;
