/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCurrentUserChapter } from '../Thunks/fetchContents';

const chapterContentsSlice: any = createSlice({
  name: 'contents',
  initialState: {
    isLoading: false,
    data: [],
    meta: {},
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchCurrentUserChapter.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCurrentUserChapter.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    });
    builder.addCase(fetchCurrentUserChapter.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const chapterContentsAction = chapterContentsSlice.actions;

export default chapterContentsSlice.reducer;
